import React from "react";
import './Notifications.scss';


function Notifications() {
    return (
        <>
            <div className="notifications_info container-fluid d-flex flex-column">
                <h1 className="notifications_info-title mt-3 mb-0">Notifications</h1>
            </div>
        </>
    )
}

export default Notifications;