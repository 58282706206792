import React from 'react';
import './ThemeToggle.scss';
import {useDispatch} from "react-redux";
import {toggleThemeMode} from "../../reducers/ThemeModeSlicer";
import {GiHeraldicSun, GiMoon} from "react-icons/gi";

function ThemeToggle() {

    const dispatch = useDispatch();

    const toggleTheme = () => {
        dispatch(toggleThemeMode());
    }

    return (
        <div className={"theme-toggle"} onClick={toggleTheme}>
            <div className="theme-toggle__button">
                <div className="theme-toggle__sun">
                    <GiHeraldicSun size={25} />
                </div>
                <div className="theme-toggle__moon">
                    <GiMoon size={25} />
                </div>
            </div>
        </div>
    )
}

export default ThemeToggle;