import {configureStore} from '@reduxjs/toolkit'
import navbarReducer from "../reducers/navbarSlice";
import progressBarReducer from "../reducers/progressBarSlice";
import themeModeReducer from '../reducers/ThemeModeSlicer';
import notificationsReducer from '../reducers/NotificationsSlicer';
import currentBookingReducer from '../reducers/CurrentBookingSlicer';
import todayBookingsReducer from '../reducers/TodayBookingsSlicer';
import meetingReducer from '../reducers/MeetingSlicer';
import ticketsReducer from '../reducers/TicketsSlice';

export default configureStore({
    reducer: {
        profile: navbarReducer,
        progressBar: progressBarReducer,
        themeMode: themeModeReducer,
        notifications: notificationsReducer,
        currentBooking: currentBookingReducer,
        todayBookings: todayBookingsReducer,
        meeting: meetingReducer,
        tickets: ticketsReducer,
    },
})