import React, {useEffect, useState} from 'react'
import {motion} from "framer-motion";
import {BiX} from "react-icons/bi";
import "./modal.scss"

type Props = {
    flagTrigger?: Function,
    children: React.ReactNode,
    onClose: Function,
    size: string,
    closable?: boolean
}

export const Modal = ({flagTrigger, children, onClose, size, closable = true}: Props) => {

    const [isShown, setIsShown] = useState(true);

    useEffect(() => {
        // document.body.classList.add('overflow-hidden');
        // document.body.style.overflow = 'hidden';
    }, []);

    const close = () => {
        // document.body.classList.remove('overflow-hidden');
        document.body.style.overflow = 'auto';
        setIsShown(false);
        onClose();

        setTimeout(() => {
            if (flagTrigger)
                flagTrigger(true);
        }, 300);
    }

    return (
        <motion.div className={'main-modal ' + size + (isShown ? ' show' : '')}>
            <motion.div className={'main-modal__body'}>
                {
                    closable &&
                    <div className="main-modal__close" onClick={close}>
                        <BiX size={'35px'}/>
                    </div>
                }

                {children}
            </motion.div>
        </motion.div>
    )
}