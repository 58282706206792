import React, {MouseEventHandler} from 'react';
import {Link} from "react-router-dom";
import {IoIosLogOut} from "react-icons/io";
import User from "../../../models/user";
import Logout from "./Logout/Logout";

type Props = {
    profile: User;
    wrapperRef: any
    popupOpen: boolean,
};

const NavProfilePopup = ({profile, wrapperRef, popupOpen}: Props) =>
    <div ref={wrapperRef} className={"navbar-popup" + (popupOpen ? ' active' : '')}>
        <div>
            <div className="navbar-popup__user">
                <img src={profile.profile_picture !== '' ? profile.profile_picture : '/resources/student.png'}
                     alt={'profile picture'}/>
                <label>{profile.first_name + ' ' + profile.last_name}</label>
            </div>
        </div>
        <div className="d-flex flex-column justify-content-between">
            <div>
                <Link className="navbar-popup__button" to="/">Results</Link>
                <Link className="navbar-popup__button" to="/profile">Profile</Link>
            </div>

            <Logout minimized={false} />
        </div>
    </div>
export default NavProfilePopup