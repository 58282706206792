import React from "react";
import "./AboutUs.scss";
// @ts-ignore
import lines from "../../resources/geometrics/lines.png";

function AboutUs() {

    return (
        <div className="about-us container-fluid d-flex flex-column h-100">
            <div className="container mb-15 position-relative">

                <img className="about-us__line" src={lines} alt=""/>

                <div className="about-us__section d-flex flex-column position-relative align-items-center mt-8 mb-10">
                    <h1 className="mt-10 mb-3 about-us__title">
                        ABOUT US
                    </h1>

                    <h4 className="text-center">
                        <i>Our</i> Story: Bridging Cultures through Education
                    </h4>

                    <img className="w-100 w-lg-50 about-us__img" src="/resources/about/about_4.png" alt=""/>
                </div>

                <div className="about-us__section mb-15 pt-5">
                    <h2 className="mb-8 text-center">
                        Meet the Team Behind Your Learning Journey
                    </h2>

                    <div className="row d-flex justify-content-center mb-15">
                        <div className="about-us__img-wrapper img1 col-4">
                        </div>

                        <div className="about-us__img-wrapper img2 col-4">
                        </div>

                        <div className="about-us__img-wrapper img3 col-4">

                        </div>
                    </div>
                </div>

                <div className="row mb-15 flex-wrap">
                    <div className="col-12 col-lg-6 d-flex flex-column justify-content-center">
                        <h3 className="mb-3 fw-bold text-start">Our Teaching Philosophy</h3>
                        <h5 className="mb-8">Delve into our educational approach rooted in cultural appreciation and collaboration.</h5>
                        <div>
                            Our teaching philosophy at Eng-Link is grounded in the belief that language and culture are intricately intertwined. We don't just teach you a language; we immerse you in the cultural context, providing a deeper understanding of the language's nuances and history. Our approach emphasizes collaboration, appreciation, and holistic learning.

                        </div>
                    </div>

                    <div className="col-12 col-lg-6 d-flex justify-content-end">
                        <img className="about-us__img" src="/resources/about/about_1.png" alt=""/>
                    </div>
                </div>


                <div className="row d-flex justify-content-between align-items-center flex-wrap">
                    <div className="col-12 col-lg-6">
                        <img className="about-us__img" src="/resources/about/about_2.png" alt="about-img"/>
                    </div>

                    <div className="col-12 col-lg-6">
                        <h3 className="mb-3">
                            Meet Our Expert Tutors
                        </h3>

                        <div>
                            Our team of expert tutors is the driving force behind your learning journey at Eng-Link. Hailing from diverse backgrounds, they bring a wealth of knowledge and experience to the platform. They are not just educators but passionate mentors who are dedicated to your growth and success.
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
}

export default AboutUs;
