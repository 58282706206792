import React from "react";
import {useState} from 'react';
import './Register.scss';
import '../../scss/main.scss';
import {useEffect} from 'react';
import {Link, useNavigate} from "react-router-dom";
import $ from 'jquery';
import {calls} from "../../config/db_config";
import {validateEmail} from "../../utils/email-validator";
import dbCore from "../../controller/core";
import AuthResponse from "../../models/authResponse";
import CaptchaForm from "../../components/CaptchaForm/CaptchaForm";

const Register = () => {

    const navigate = useNavigate();

    // LOGIN ERROR MESSAGES HANDLING
    const errorMassages = {
        emptyFields: 'Please fill all the fields',
        invalidEmail: 'Invalid email',
        passwordsMismatch: 'Passwords do not match',
        unexpectedError: 'Unexpected Error',
        genericError: 'Something went wrong',
        emailTaken: 'The email is already in use'
    }

    const [errorMessage, setErrorMessage] = useState({
        visible: false,
        message: ''
    });

    const [registerErrorMessage, setRegisterErrorMessage] = useState({
        visible: false,
        message: ''
    });

    const goToLogin = () => {
        navigate('/login');
    };

    const register = () => {
        let $inputs = $('#register-form :input')
            .not('button').not('#g-recaptcha-response');

        interface Values {
            [key: string]: any;
        }

        let values: Values = {};
        let isFieldEmpty = false;
        $inputs.each(function () {
            const value = $(this).val();
            // @ts-ignore
            values[this.name] = value;

            if (value === '')
                isFieldEmpty = true;
        });

        const isValidEmail = validateEmail(values.email);

        if (isFieldEmpty) {
            setRegisterErrorMessage({visible: true, message: errorMassages.emptyFields});
        } else if (isValidEmail == null) {
            setRegisterErrorMessage({visible: true, message: errorMassages.invalidEmail});
        } else if (values.password !== values.repeat_password) {
            setRegisterErrorMessage({visible: true, message: errorMassages.passwordsMismatch});
        } else {
            // everything in the form is correct

            // TODO: consider a datepicker or initialize a default value
            const d = new Date();
            d.setTime(d.getTime() - (27 * 365 * 24 * 60 * 60 * 1000));
            const birthday = new Date().toISOString().slice(0, 19).replace('T', ' ');

            // TODO: get user_type from secret_code
            const data = {
                'email': values.email,
                'password': values.password,
                'first_name': values.first_name,
                'last_name': values.last_name,
                'birthday': birthday,
                'profile_picture': '',
                'subject': '',
                'exam_state': 0,
                'last_quiz': null,
                'last_login': null,
            }

            dbCore.register(data).then((data: AuthResponse) => {
                if (data.status != null) {
                    switch (data.status) {
                        case 200:
                            goToLogin();
                            break;
                        case 410:
                            setRegisterErrorMessage({visible: true, message: errorMassages.emailTaken});
                            break;
                        default:
                            if (data.message)
                                setRegisterErrorMessage({visible: true, message: data.message});
                            else
                                setRegisterErrorMessage({visible: true, message: errorMassages.genericError});
                            break;
                    }
                }
            })
        }
    }

    const bgStyle = {
        backgroundImage: `url(${process.env.PUBLIC_URL + '/resources/login-bg.jpg'})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    };

    return (
        <div style={bgStyle} className="register">
            <div className={'register__left'}>
                <h1 className="register__left-title text-center">Εγγραφή</h1>

                <CaptchaForm callback={register} id="register-form" className="register__left-form">
                    <div className="d-flex flex-column">
                        <div className="d-flex">
                            <div className="w-50">
                                <label className="register__left-form-label">Όνομα</label>
                                <input placeholder="π.χ. Γιάννης" type="text" name="first_name"
                                       className="input input-primary text-dark register__left-form-input mt-1 w-90"/>
                            </div>
                            <div className="w-50">
                                <label className="register__left-form-label">Επώνυμο</label>
                                <input placeholder="π.χ. Παπαδόπουλος" name="last_name" type="text"
                                       className="input input-primary text-dark register__left-form-input mt-1 w-100"/>
                            </div>
                        </div>
                        <label className="register__left-form-label mt-4">Email</label>
                        <input placeholder="π.χ. john@edupal.gr" name="email" type="text"
                               className="input input-primary text-dark register__left-form-input mt-1"/>
                        <label className="register__left-form-label mt-4">Κωδικός</label>
                        <div className="d-flex">
                            <div className="w-50">
                                <input placeholder="Πληκτρολογήστε τον κωδικό σας" name="password" type="password"
                                       className="input input-primary text-dark register__left-form-input mt-1 w-90"/>
                            </div>
                            <div className="w-50">
                                <input placeholder="Πληκτρολογήστε ξανά τον κωδικό σας" name="repeat_password"
                                       type="password"
                                       className="input input-primary text-dark register__left-form-input mt-1 w-100"/>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-column">
                        <div className={'alert alert-danger mt-1 ' + (registerErrorMessage.visible ? '' : 'd-none')}>
                            {registerErrorMessage.message}
                        </div>

                        <button type="submit"
                            className={"button font-weight-bold text-center " + (registerErrorMessage.visible ? '' : 'mt-5')}>
                            Εγγραφή
                        </button>

                        <label className="register__left-form-label mt-3 mb-10">Έχετε ήδη λογαριασμό;<a
                            onClick={goToLogin} className="link link-primary m-2">Συνδεθείτε</a></label>

                        <div className={'d-flex flex-column align-items-center'}>
                            <div className={'mt-8 mb-3'}>
                                OR
                            </div>
                            <Link className={'button'} to="/tutor-register">Become a tutor</Link>

                        </div>
                    </div>
                </CaptchaForm>
            </div>

            <div className="w-50 d-none d-lg-block">
                <img className={'register__background'} src="/resources/register-image.jpg" alt="register-image"/>
            </div>
        </div>
    );
};

export default Register;