import './Dashboard.scss';
import React, {useContext, useEffect} from "react";
import {ContextManager} from "../../app/socket";
import {Link, useNavigate} from "react-router-dom";
import LatestLectures from "../../components/LatestLectures/LatestLectures";
import BarGraph from "./stats/BarGraph";
import YourClasses from "../../components/YourClasses/YourClasses";
import {dateFormatter} from "../../utils/dateUtils";
import FavoriteTutors from "../../components/FavoriteTutors/FavoriteTutors";

const Dashboard = () => {
    const socketContext = useContext(ContextManager);

    const navigate = useNavigate();


    useEffect(() => {
    }, []);

    return (
        <div className="container-app page dashboard">
            <div className="row">
                <div className="col-lg-7 col-12 pe-5">
                    <div className='mb-4'>
                        <FavoriteTutors />
                    </div>

                    <div className="dashboard__box mb-5">
                        <h4>Time spent on learning</h4>
                        <BarGraph />
                    </div>

                    <div className='mb-4 d-flex align-items-center justify-content-between'>
                        <h4>
                            Latest Lectures
                        </h4>

                        <Link className='button button-gray' to={'/latest-lectures'}>
                            Show all
                        </Link>
                    </div>

                    <LatestLectures />
                </div>

                <div className="col-lg-5 col-12">
                    <div className="dashboard__box mb-5 d-flex flex-column h-50">
                        <h4>Upcoming classes</h4>
                        <h6 className='opacity-75'>Today {dateFormatter(new Date())}</h6>

                        <div className="flex-grow-1">
                            <YourClasses />
                        </div>
                    </div>

                    <div className="dashboard__box mb-5 d-flex flex-column h-50">

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
