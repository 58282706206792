import React, {useContext, useEffect, useState} from 'react';
import './TopTutors.scss';
import {AiFillStar} from "react-icons/ai";
import Slider from "react-slick";
import {BsChevronLeft, BsChevronRight} from "react-icons/bs";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {ContextManager} from "../../app/socket";
import {useNavigate} from "react-router-dom";
import User from "../../models/user";

const TopTutors = () => {

    const navigate = useNavigate();

    const context = useContext(ContextManager);

    const [users, setUsers] = useState<Array<User>>([]);

    useEffect(() => {
        if (context && context.socket) {
            context.socket.emit('getTopTutors');
            context.socket.on('onGetTopTutors', onGetTopTutors);
        }

        return () => {
            if (context && context.socket) {
                context.socket.off('onGetTopTutors', onGetTopTutors);
            }
        }
    }, [])

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: users.length >= 3 ? 3 : users.length,
        slidesToScroll: 1,
        prevArrow: <button className="slick-prev"><BsChevronLeft size={20}/></button>,
        nextArrow: <button className="slick-next"><BsChevronRight size={20}/></button>,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    variableWidth: true,
                    arrows: false
                }
            }
        ]
    };

    const onGetTopTutors = (data: Array<User>) => {
        if (data) {
            setUsers([...data]);
        }
    }

    const visitTutor = (tutorId: string) => {
        navigate("/tutor?tutor_id=" + tutorId)
    }

    return (
        <div className="top-tutors">
            <Slider {...settings}>
                {users.map((user, index) => {
                    return (
                        <div key={index} className="top-tutors__item" onClick={() => visitTutor(user._id)}>
                            <img className='top-tutors__user-pic'
                                 src={user.profile_picture ? user.profile_picture
                                     : '/resources/student.png'}
                                 alt={'profile picture'}/>

                            <div className="top-tutors__footer">
                                <div className="top-tutors__name">
                                    {user.first_name + ' ' + user.last_name}
                                </div>

                                <div className="d-flex gap-3">
                                    <div className="top-tutors__star">
                                        <AiFillStar className={'mr-1'}/>
                                        {user.rating ? user.rating : '-'}
                                    </div>

                                    <div className="top-tutors__info">
                                        {user.info ? user.info : 'Native speaker'}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Slider>

        </div>
    );
};

export default TopTutors;