import React, {useState} from 'react';
import './LatestLectures.scss';
// @ts-ignore
import LessonBook from "../../resources/LessonBook.png";
// @ts-ignore
import {IoCalendarNumberOutline} from "react-icons/io5";
import Lesson from "../../models/lesson";
import {formatDate} from "../../utils/dateUtils";
import {useNavigate} from "react-router-dom";
import dbCore from "../../controller/core";

type Props = {
    numberToShow?: number
    showPagination?: boolean
}

function LatestLectures({numberToShow = 5, showPagination = false}: Props) {

    const navigate = useNavigate();

    const [lessons, setLessons] = useState<Array<Lesson>>([]);

    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);

    let pagesSet = false;

    const onLessonsListener = (lessonsData: Array<Lesson>, pageData: number) => {
        if (lessonsData) {
            setLessons(lessonsData);

            if (pageData && !pagesSet) {
                setPages(pageData);
                pagesSet = false;
            }
        }
    }

    const getLecturesHook: Function = dbCore.useGetLectures(numberToShow, page, onLessonsListener);

    const changePage = (index: number) => {
        setPage(index);

        if (getLecturesHook && typeof getLecturesHook === 'function')
            getLecturesHook(numberToShow, index);
    }

    return (
        <>
            <div className="latest-lectures">
                <div className="d-flex flex-column gap-4">
                    {lessons.map((lesson, index) => (
                        <div key={index} className="latest-lectures__user-wrapper d-flex"
                             onClick={() => navigate('/lesson-details?lesson_id=' + lesson._id)}>
                            <div
                                className="latest-lectures__profile-picture d-flex align-self-center justify-content-center">
                                <img src={lesson.user.profile_picture} alt="Profile"/>
                            </div>
                            <div className="col-2 d-flex flex-column justify-content-center flex-grow-1">
                                <div
                                    className="latest-lectures__name">{lesson.user.first_name + ' ' + lesson.user.last_name}</div>
                                <div className={'d-flex align-items-center gap-2'}>
                                    <IoCalendarNumberOutline size={20}/>
                                    <div className="latest-lectures__date">{formatDate(lesson.date)}</div>
                                </div>
                            </div>
                            <div
                                className="col-2 col-md-2 col-lg-1 d-flex flex-column justify-content-center align-items-center">
                                <p className="latest-lectures__lesson-text align-self-center text-nowrap mb-0">No {lesson?.lesson_number}</p>
                                <div className="latest-lectures__lesson">
                                    <img src={LessonBook} alt="Lesson No."/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {
                    (pages > 1 && showPagination) &&
                    <div className="latest-lectures__pagination d-flex gap-4 mt-5">
                        {Array.from({length: pages}).map((_, index) => (
                            <div key={index}
                                 className={"latest-lectures__pagination-number d-flex" + (index + 1 === page ? ' active' : '')}
                                 onClick={() => changePage(index + 1)}>
                                {index + 1}
                            </div>
                        ))}
                    </div>
                }

            </div>
        </>
    )
}

export default LatestLectures;