import {calls} from "../../../config/db_config";

type Props = { birthday: string; last_quiz: null; password: any; subject: string; last_login: null; last_name: any; profile_picture: string; first_name: any; email: any; exam_state: number }
const register = async (data: Props) => {
    let response = await fetch(calls.signUp, {
        method: 'post',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    });
    return await response.json();
}

export default register;