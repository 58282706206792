import './progressBar.scss';
import React from 'react';
import {Spinner} from 'react-bootstrap';
import {useSelector} from 'react-redux';

function ProgressBar() {
    const progressBar = useSelector((state: any) => state.progressBar.value);

    return (
        progressBar && (
            <div className="progress-bar">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        )
    );
}

export default ProgressBar;
