import './HeroFeatures.scss';
import React from "react";
// @ts-ignore
import feature1 from "../../resources/features/feature-1.png";
// @ts-ignore
import feature2 from "../../resources/features/feature-2.png";
// @ts-ignore
import feature3 from "../../resources/features/feature-3.png";

const HeroFeatures = () => {

    return (
        <div className="hero-features">
            <div className="hero-features__box">

                <img className={'hero-features__img'} src={feature1} alt=""/>

                <div className="hero-features__title">
                    Feature
                </div>

                <div className="hero-features__subtitle">
                    Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit.
                </div>
            </div>

            <div className="hero-features__box">

                <img className={'hero-features__img'} src={feature2} alt=""/>

                <div className="hero-features__title">
                    Feature
                </div>

                <div className="hero-features__subtitle">
                    Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit.
                </div>
            </div>

            <div className="hero-features__box">

                <img className={'hero-features__img'} src={feature3} alt=""/>

                <div className="hero-features__title">
                    Feature
                </div>

                <div className="hero-features__subtitle">
                    Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. Aliquam in hendrerit.
                </div>
            </div>
        </div>
    );
};

export default HeroFeatures;
