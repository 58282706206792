import React, {useState, useEffect} from 'react';
import './YourClasses.scss';
import {areDatesEqual, formatTimeHHmm, parseDateString, roundToHalfHour} from "../../utils/dateUtils";
import Booked from "../../models/booked";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const YourClasses = () => {
    const navigate = useNavigate();

    const todayBookings = useSelector((state: any) => state.todayBookings.value);

    const [currentTime, setCurrentTime] = useState<Date>(new Date());

    const [timeSlots, setTimeSlots] = useState<Date[]>([]);

    useEffect(() => {
        populateTimeSlots();

        // Update the current time every minute
        const interval = setInterval(calculateCurrentTimeSlot, 1000 * 60); // Every 1 minute
        return () => clearInterval(interval);
    }, []);

    const populateTimeSlots = () => {
        // Round current time to the nearest half-hour
        const roundedCurrentTime = roundToHalfHour(new Date());

        const baseDate = roundedCurrentTime; // Get the current date and time (will be the base date)
        baseDate.setSeconds(0);
        baseDate.setMilliseconds(0);
        baseDate.setHours(roundedCurrentTime.getHours() - 1);

        let tempArray = [];
        for (let i = 0; i < 3; i++) {
            const newDate = new Date(baseDate); // Create a new Date object to avoid modifying the baseDate
            newDate.setHours(baseDate.getHours() + i); // Set the hours to the current hour
            tempArray.push(newDate);

            // Add the half-hour interval
            const halfHourLater = new Date(newDate);
            halfHourLater.setMinutes(newDate.getMinutes() + 30);
            tempArray.push(halfHourLater);
        }
        setTimeSlots([...tempArray]);
    };

    const getTop = () => {
        const roundedDate = roundToHalfHour(new Date(currentTime));

        const minutesDif = roundedDate.getMinutes() - currentTime.getMinutes();
        const hoursDif = roundedDate.getHours() - currentTime.getHours();

        // between 0-1
        let ratio;
        if (hoursDif === 0) {
            ratio = .5 + (Math.abs(minutesDif) / 15 * .5);
        } else {
            ratio = (Math.abs(minutesDif) / 60) / 2;
        }

        return `calc(((100% / 6) * (3 + ${ratio})) - ((100% / 12) * 2))`;
    };

    const [top, setTop] = useState(getTop());

    // Function to calculate the nearest half-hour time slot and set it as "current"
    const calculateCurrentTimeSlot = () => {
        // const roundedTime = roundToHalfHour(new Date());
        setCurrentTime(new Date());
        setTop(getTop);
    };

    const componentStyle = {
        top: top,
    };

    const slotClick = (bookedSlot: Booked | null) => {
        if (bookedSlot && new Date(bookedSlot.date) <= new Date()) {
            navigate('/call?meeting_id=' + bookedSlot._id);
        }
    };

    return (
        <div className="your-classes">
            <div className="your-classes__current" style={componentStyle}>
                <div className='your-classes__current-circle'></div>
            </div>

            <table className='your-classes__wrapper'>
                <tbody className="your-classes__hours">
                {timeSlots.map((slot, index) => {
                    const formattedTime = formatTimeHHmm(slot);
                    const japanTime = new Date(slot);
                    japanTime.setHours(japanTime.getHours() + 16); // Add 16 hours for Japan time

                    // const isCurrent = slot.getTime() === currentTime.getTime();

                    let bookedSlot: Booked | null = null;
                    if (todayBookings) {
                        bookedSlot = todayBookings.find((item: Booked) => {
                            const itemDate = parseDateString(item.date.toString());
                            return areDatesEqual(itemDate, slot);
                        });
                    }

                    return <tr key={index}>
                        <td className="your-classes__hour">
                            {formattedTime}
                        </td>
                        <td className="your-classes__slot">
                            <div className="your-classes__slot-separator">
                            </div>

                            {(bookedSlot && bookedSlot.user) &&
                                <div className='your-classes__slot-box' onClick={() => slotClick(bookedSlot)}>
                                    <div className="your-classes__slot-title">Class
                                        with: {bookedSlot.user.first_name + ' ' + bookedSlot.user.last_name}</div>
                                    <div className="your-classes__slot-subtitle">30 minutes</div>
                                </div>}
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
        </div>
    );
};

export default YourClasses;
