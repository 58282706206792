import {createSlice} from '@reduxjs/toolkit'
import Cookies from "universal-cookie";

export const ThemeModeSlicer = createSlice({
    name: 'themeMode',
    initialState: {
        value: false,
    },
    reducers: {
        setThemeMode: (state, action) => {
            state.value = action.payload;
        },
        toggleThemeMode: (state) => {
            state.value = !state.value;

            const cookies = new Cookies();
            cookies.set('theme', state.value ? 'dark' : 'light', {path: '/'});
        },
    },
})

// Action creators are generated for each case reducer function
export const {setThemeMode, toggleThemeMode} = ThemeModeSlicer.actions

export default ThemeModeSlicer.reducer