import React, {createContext, useState} from 'react';
import {tokenUtils} from "../utils/token-utils";
import {io, Socket, ManagerOptions, SocketOptions} from "socket.io-client";
import {calls} from "../config/db_config";

type contextProps = {
    socket: Socket | null;
};

export const ContextManager = createContext<contextProps>(null!);
export const DispatchContext = createContext<React.Dispatch<any> | null>(null);


function getSocket(): Socket | null {
    const token: string | null = tokenUtils.getBearerToken();
    const options: Partial<ManagerOptions & SocketOptions> = token ? {query: {token}} : {};
    return token ? io(calls.endpoint, options) : null;
}

export function ContextProvider({children}: { children: React.ReactNode }) {
    const [socket, dispatch] = useState<Socket | null>(getSocket());

    return (
        <ContextManager.Provider value={{socket}}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </ContextManager.Provider>
    );
}
