import login from "./src/FirebaseLogin";
import register from "./src/FirebaseRegister";
import registerTutor from "./src/FirebaseRegisterTutor";
import useGetLectures from "./src/FirebaseLectures";
import getUserData from "./src/FirebaseGetUserData";
import logout from "./src/FirebaseLogout";
import DbInterface from "../dbInterface";
import {initializeApp} from "firebase/app";
import {getDatabase} from "firebase/database";
import {getAnalytics} from "firebase/analytics";
import {getAuth} from "firebase/auth";
import useGetTutors from "./src/FirebaseGetTutors";
import updateProfilePicture from "./src/FirebaseUpdateProfilePicture";
import {getRemoteConfig, fetchAndActivate} from "firebase/remote-config";

class DbFirebaseCore implements DbInterface {

    constructor() {
        // Initialize Firebase

        // Your web app's Firebase configuration
        const firebaseConfig = {
            apiKey: "AIzaSyDO5yGuoT0H4q2Tn2KrL7xozxcTx72fu6U",
            authDomain: "englink-49855.firebaseapp.com",
            projectId: "englink-49855",
            storageBucket: "englink-49855.appspot.com",
            messagingSenderId: "420693985032",
            appId: "1:420693985032:web:81e2923821b0e038de8ebd",
            measurementId: "G-Z65KQ69ZG1",
            databaseURL: "https://englink-49855-default-rtdb.europe-west1.firebasedatabase.app",
        };

        const app = initializeApp(firebaseConfig);

        // Initialize Firebase
        const firebaseApp = initializeApp(firebaseConfig);
        const analytics = getAnalytics(firebaseApp);

        // Initialize Realtime Database and get a reference to the service
        const database = getDatabase(app);

        const auth = getAuth(app);
        // onAuthStateChanged(auth, (tempUser) => {
        //     if (tempUser) {
        //
        //     }
        // });

        const remoteConfig = getRemoteConfig(app);
        remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

        // let rcDefaults = require('../../resources/remote_config_defaults.json');
        // remoteConfig.defaultConfig = rcDefaults;

        fetchAndActivate(remoteConfig)
            .then(() => {
                // const val = getValue(remoteConfig, "ticket_cost_default");
                // console.log(val)
            })
            .catch((err) => {
                // ...
            });
    }

    useGetTutors = useGetTutors;
    getTopTutors = () => {
    };
    getNotifications = () => {
    };
    setFavoriteTutor = () => {
    };
    getFavoriteTutors = () => {
    };

    login = login;
    register = register;
    registerTutor = registerTutor;
    logout = logout;
    getUserData = getUserData;

    useGetLectures = useGetLectures;

    updateProfilePicture = updateProfilePicture;
}

export default DbFirebaseCore;