import React, {useState, useRef, useEffect, useContext} from "react";
import {Link} from "react-router-dom";
import './NavbarRoot.scss';
import {useDispatch, useSelector} from "react-redux";
import Notifications from "../../Notifications/Notifications";
import {FiMenu} from "react-icons/fi";
import {useLocation} from 'react-router-dom';
import ThemeToggle from "../../ThemeToggle/ThemeToggle";
import NavProfilePopup from "../Subcomponents/NavProfilePopup";
import NotificationIcon from "../Subcomponents/NotificationIcon/NotificationIcon";
import NavProfilePic from "../Subcomponents/NavProfilePic";
import NavMobileLinks from "../Subcomponents/NavMobileLinks";
import NavDesktopLinks from "../Subcomponents/NavDesktopLinks";
import AppLogic from "../App/AppLogic";
import {ContextManager} from "../../../app/socket";
import {setNotifications} from "../../../reducers/NotificationsSlicer";

function NavbarRoot() {
    const profile = useSelector((state: any) => state.profile.value);
    const themeMode = useSelector((state: any) => state.themeMode.value);

    const [mobileActive, setMobileActive] = useState(false);

    const location = useLocation();

    const dispatch = useDispatch();

    const context = useContext(ContextManager);

    useEffect(() => {
        if (context && context.socket) {
            context.socket.emit('getNotifications');

            context.socket.on('notification', notificationListener);
            return () => {
                if (context && context.socket)
                    context.socket.off('notification', notificationListener);
            }
        }
    }, [context]);

    useEffect(() => {
        document.body.style.overflow = 'auto';
    }, [location]);

    const notificationListener = (notifications: any) => {
        if (notifications && notifications.length)
            dispatch(setNotifications(notifications));
    }

    const unauthorizedLinks = [
        <Link to="/about">About</Link>,
        <Link to="/contact-us">Contact Us</Link>,
    ]

    const studentLinks = [
        <Link to="/app">App</Link>,
        <Link to="/book">Book</Link>,
    ]

    const professorLinks = [
        <Link to="/app">App</Link>,
        <Link to="/schedule">Schedule</Link>,
    ]

    const wrapperRef = useRef<HTMLDivElement>(null);

    function handleClickOutside(event: MouseEvent) {
        const element = event.target as Element;
        // if target has class ignore-event don't proceed bubbling
        if (event.target && (element.classList.contains('ignore-event') || element.tagName === 'svg')) {
            document.removeEventListener("mousedown", handleClickOutside);
            return;
        }

        if (wrapperRef) {

            if (wrapperRef.current && !wrapperRef.current.contains(element)) {
                if (!element.classList.contains('navbar-container__user-notification-img')) {
                    setpopupOpen(false);
                    setNavNotifications(false);

                    document.removeEventListener("mousedown", handleClickOutside);
                }
            }
        }
    }

    const navbarPopup = () => {
        document.addEventListener("mousedown", handleClickOutside);
    };

    const [popupOpen, setpopupOpen] = useState(false);
    const [navNotifications, setNavNotifications] = useState(false);

    const navUserClick = () => {
        document.removeEventListener("mousedown", handleClickOutside);
        setNavNotifications(false);

        if (popupOpen) {
            setpopupOpen(false);
        } else {
            setpopupOpen(true);
            navbarPopup();
        }
    };

    const navNotificationsOpen = () => {
        document.removeEventListener("mousedown", handleClickOutside);

        setpopupOpen(false);

        if (navNotifications) {
            setNavNotifications(false);
        } else {
            setNavNotifications(true);
            navbarPopup();
        }
    };

    return (
        <div className="navbar">
            {profile.user_type && <AppLogic/>}

            <div className="navbar-container d-flex">
                <div className={'navbar-container__menu-icon'} onClick={() => setMobileActive(!mobileActive)}>
                    <FiMenu size={'35px'} color={'#000'}/>
                </div>

                <ul className="navbar-container__links">
                    <li className="main-logo">
                        <Link to="/">
                            {
                                themeMode && (
                                    <img src="/resources/logo.png" alt={'main-logo'}/>
                                )
                            }

                            {
                                !themeMode && (
                                    <img src="/resources/logo-dark.png" alt={'main-logo'}/>
                                )
                            }
                        </Link>
                    </li>

                    <NavDesktopLinks profile={profile} studentLinks={studentLinks}
                                     unauthorizedLinks={unauthorizedLinks} professorLinks={professorLinks}/>
                </ul>

                <div className="navbar-container__user">

                    <ThemeToggle/>

                    {
                        profile.user_type && (
                            <>
                                <div className="mr-5">
                                    <NotificationIcon navNotificationsOpen={navNotificationsOpen}/>
                                </div>
                                <NavProfilePic profile={profile} navUserClick={navUserClick}/>
                            </>
                        )
                    }

                    {
                        !profile.user_type && (
                            <Link className="button" to="/login">Sign-in</Link>
                        )
                    }
                </div>
            </div>

            <Notifications navNotifications={navNotifications}/>

            {profile.user_type &&
                <NavProfilePopup popupOpen={popupOpen} profile={profile} wrapperRef={wrapperRef}/>}

            <NavMobileLinks setMobileActive={setMobileActive} mobileActive={mobileActive}
                            profile={profile} studentLinks={studentLinks} unauthorizedLinks={unauthorizedLinks}
                            professorLinks={professorLinks}/>

        </div>
    );
}

export default NavbarRoot;
