import {createSlice} from '@reduxjs/toolkit'

export const TodayBookingsSlicer = createSlice({
    name: 'todayBookings',
    initialState: {
        value: false,
    },
    reducers: {
        setTodayBookings: (state, action: any) => {
            state.value = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const {setTodayBookings} = TodayBookingsSlicer.actions

export default TodayBookingsSlicer.reducer