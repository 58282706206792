import React, {useState, useRef, useEffect} from 'react';
import {getRandomColor} from "../../../utils/generalUtils";
import {areDatesEqual} from "../../../utils/dateUtils";

type Props = {
    index: number;
    isDragging: boolean;
    setIsDragging: (isDragging: boolean) => void;
    setSelection: (index: Date, finish?: boolean) => void;
    selectionMade: boolean;
    availableSlot: any; // Replace 'any' with the appropriate type for availableSlot
    bookedSlot: any; // Replace 'any' with the appropriate type for bookedSlot
    dateSlot: Date;
    selectStudentBookTutor: Function;
    selectBooking: Function;
    isStudent: boolean;
    clickedSlot: Date | undefined;
};

const TableData: React.FC<Props> = ({
                                        index,
                                        isDragging,
                                        setIsDragging,
                                        setSelection,
                                        selectionMade,
                                        availableSlot,
                                        bookedSlot,
                                        dateSlot,
                                        selectStudentBookTutor,
                                        isStudent,
                                        clickedSlot,
                                        selectBooking
                                    }) => {
    const ref = useRef<HTMLTableDataCellElement>(null);

    const [isSelected, setIsSelected] = useState(false);

    const [randomColor] = useState(getRandomColor());

    useEffect(() => {
        if (!selectionMade) {
            setIsSelected(false);
        }
    }, [selectionMade]);

    useEffect(() => {
        if (clickedSlot) {
            if (areDatesEqual(clickedSlot, dateSlot)) {

            } else {
                setIsSelected(false)
            }
        }
    }, [clickedSlot]);

    const handleMouseDown = () => {
        if (isStudent)
            return;

        if (!bookedSlot) {
            setSelection(dateSlot);
            setIsSelected(true);
        }
        setIsDragging(true);
    };

    const handleMouseUp = () => {
        if (isStudent)
            return;

        setIsDragging(false);

        if (setSelection && ref.current) {
            if (!bookedSlot) {
                setSelection(dateSlot, true);
            }
        }
    };

    const handleMouseEnter = () => {
        if (isStudent)
            return;

        // Update selection status only if dragging
        if (isDragging && setSelection && ref.current) {
            if (!bookedSlot) {
                setSelection(dateSlot);
                setIsSelected(true);
            }
        }
    };

    const onClick = () => {
        if (!bookedSlot && availableSlot) {
            setIsSelected(true);
            selectStudentBookTutor(dateSlot);
        }

        if (bookedSlot?.student_id) {
            setIsSelected(true);
            selectBooking(dateSlot, bookedSlot);
        }
    };

    return (
        <td ref={ref}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseEnter={handleMouseEnter}
            onClick={onClick}
            key={index} className={"calendar-table__slot" + (isSelected ? ' selected' : '')}>
            <div className='calendar-table__slot-wrapper'>
                {/* PROFESSOR SIDE */}
                {!isStudent && <>
                    {bookedSlot && (
                        <div className={'calendar-table__slot-box calendar-table__slot-user ' + randomColor}>
                            <img
                                className='calendar-table__slot-user-img'
                                src={bookedSlot.profile_picture ? bookedSlot.profile_picture : '/resources/student.png'}
                                alt={'profile picture'}
                            />

                            <div
                                className={'text-white'}>{bookedSlot?.first_name + ' ' + bookedSlot?.last_name}</div>
                        </div>
                    )}

                    {!bookedSlot && availableSlot && (
                        <div className='calendar-table__slot-box calendar-table__slot-available'>
                            Available
                        </div>
                    )}
                </>}

                {/*  STUDENT SEEING TUTOR PAGE  */}
                {isStudent && <>
                    {bookedSlot && !bookedSlot?.student_id && (
                        <div className='calendar-table__slot-box calendar-table__slot-reserved'>
                            Reserved
                        </div>
                    )}

                    {bookedSlot?.student_id && (
                        <div className='calendar-table__slot-box calendar-table__slot-yours'>
                            Your Booking
                        </div>
                    )}

                    {!bookedSlot && availableSlot && (
                        <div className='calendar-table__slot-box calendar-table__slot-available'>
                            Available
                        </div>
                    )}
                </>}
            </div>
        </td>
    );
};

export default TableData;
