import "./Schedule.scss"
import Switcher from "../../components/Switcher/Switcher";
import ButtonMultipleToggle from "../../components/ButtonMultipleToggle/buttonMultipleToggle";
import React, {useContext, useEffect, useReducer, useState} from "react";
import {ContextManager} from "../../app/socket";
import CalendarTable from "./CalendarTable/CalendarTable";
import CalendarTableMonth from "./CalendarTableMonth/CalendarTableMonth";
import {areDatesEqual, formatDate, formatTimeHHmm, isSameDay} from "../../utils/dateUtils";
import Booked from "../../models/booked";
import ScheduleType from "../../models/ScheduleType";

type Button = {
    label: string
    value: string;
};

type props = {
    tutorId: string | null,
    onDateChange?: Function | null
}

function Schedule({tutorId, onDateChange}: props) {

    const socketContext = useContext(ContextManager);

    const [showSidebar, setShowSidebar] = useState(false);

    const [selectedDate, setSelectedDate] = useState<Date>();

    const buttons: Array<Button> = [
        {
            label: 'DAY',
            value: '1'
        },
        {
            label: 'WEEK',
            value: '7'
        },
        {
            label: 'MONTH',
            value: '30'
        }
    ]

    const [calendarView, setCalendarView] = useState(buttons[0].value);

    const requestSchedule = (tempDate: Date) => {
        const days = parseInt(calendarView);

        if (socketContext && socketContext.socket) {
            if (tutorId) {
                socketContext.socket.emit('getTutorSchedule', tempDate, tutorId, days);
            } else {
                socketContext.socket.emit('getSchedule', tempDate, days);
            }
        }
    }

    const dateReducer = (state: Date, action: Date) => {
        if (onDateChange)
            onDateChange(action);

        requestSchedule(action);

        return action;
    };

    const [date, setDate] = useReducer(dateReducer, new Date());

    const [booked, setBooked] = useState<Array<Booked>>([]);
    const [availableTimes, setAvailableTimes] = useState<Array<Date>>([]);

    useEffect(() => {
        if (socketContext?.socket) {
            requestSchedule(date);

            socketContext.socket.on('onSchedule', (data: ScheduleType) => {
                if (data.booked) {
                    setBooked(data.booked);
                }

                if (data.available_times) {
                    const tempAvailableTimes = new Set(data.available_times);
                    setAvailableTimes([...tempAvailableTimes]);
                }
            });

            return () => {
                if (socketContext.socket) {
                    socketContext.socket.off('onSchedule');
                }
            };
        }
    }, [socketContext, calendarView]);

    useEffect(() => {
        if (socketContext?.socket) {
            socketContext.socket.on('scheduleChanged', () => {
                requestSchedule(date);
            });

            return () => {
                if (socketContext.socket) {
                    socketContext.socket.off('scheduleChanged');
                }
            };
        }
    }, [date, calendarView]);

    function isBooked(obj: any): obj is Booked {
        return obj && obj.hasOwnProperty('date');
    }

    function compareDates(a: Date | Booked, b: Date | Booked): number {
        const dateA = a instanceof Date ? a : new Date(a.date);
        const dateB = b instanceof Date ? b : new Date(b.date);
        return dateA.getTime() - dateB.getTime();
    }

    const getSidebarInfo = () => {
        if (selectedDate) {
            const allSlots: Array<Date | Booked> = [];

            booked.find((item: Booked) => {
                if (!item.date)
                    return;

                const itemDate = new Date(item.date);
                if (isSameDay(itemDate, selectedDate)) {
                    allSlots.push(item);
                }
            });

            availableTimes.find((item: Date) => {
                let tempItem = new Date(item);
                if (isSameDay(tempItem, selectedDate)) {
                    allSlots.push(tempItem)
                }
            });

            allSlots.sort(compareDates);

            const filteredSlots = allSlots.filter((slot, index, array) => {
                if (slot instanceof Date) {
                    const nextElement = array[index + 1];
                    const prevElement = index - 1 >= 0 ? array[index - 1] : false;

                    if (isBooked(nextElement)) {
                        const dateA = new Date(slot);
                        const dateB = new Date(nextElement.date);

                        if (areDatesEqual(dateA, dateB))
                            return false;
                    }

                    if (prevElement && isBooked(prevElement)) {
                        const dateA = new Date(slot);
                        const dateB = new Date(prevElement.date);

                        if (areDatesEqual(dateA, dateB))
                            return false;
                    }
                }

                return true;
            });

            return <>
                {
                    filteredSlots.map((slot: Date | Booked, index) => {
                        return <div key={index}>
                            {!tutorId && <>
                                {!(slot instanceof Date) && (
                                    <div className="mb-3">
                                        <div className="w-100">
                                            {formatTimeHHmm(slot.date)}
                                        </div>

                                        <div
                                            className={'calendar-table__slot-box calendar-table__slot-user '}>
                                            <div
                                                className={'w-100 text-white'}>{slot?.first_name + ' ' + slot?.last_name}</div>

                                            <img
                                                className='calendar-table__slot-user-img'
                                                src={slot.profile_picture ? slot.profile_picture : '/resources/student.png'}
                                                alt={'profile picture'}
                                            />
                                        </div>
                                    </div>
                                )}

                                {slot instanceof Date && (
                                    <>
                                        {formatTimeHHmm(slot)}
                                        <div className='calendar-table__slot-box calendar-table__slot-available mb-3'>
                                            Available
                                        </div>
                                    </>
                                )}
                            </>}

                            {/*  STUDENT SEEING TUTOR PAGE  */}
                            {tutorId && <>
                                {!(slot instanceof Date) && !slot?.student_id && (
                                    <div className='calendar-table__slot-box calendar-table__slot-reserved'>
                                        Reserved
                                    </div>
                                )}

                                {(!(slot instanceof Date) && slot?.student_id) && (
                                    <div className='calendar-table__slot-box calendar-table__slot-yours'>
                                        Your Booking
                                    </div>
                                )}

                                {slot instanceof Date && (
                                    <div className='calendar-table__slot-box calendar-table__slot-available'>
                                        Available
                                    </div>
                                )}
                            </>}
                        </div>
                    })
                }
            </>
        } else {
            return <></>;
        }
    }

    const calendarViewChange = (action: string) => {
        if (action === '30') {
            let tempDate = date;
            tempDate.setDate(1);
            setDate(tempDate);
        }

        setCalendarView(action);
    }

    return (
        <div className="schedule">
            <div className="schedule__toolbar mb-6">
                <ButtonMultipleToggle buttons={buttons} setSelected={calendarViewChange} selected={calendarView}/>

                <Switcher date={date} setDate={setDate} increment={parseInt(calendarView)}/>
            </div>

            <div className={'mb-12'}>
                {
                    calendarView !== buttons[2].value &&
                    <CalendarTable daysToAdd={parseInt(calendarView)} date={date} booked={booked}
                                   availableTimes={availableTimes} tutorId={tutorId}/>
                }

                {
                    calendarView === buttons[2].value &&
                    <div className="d-flex">
                        <CalendarTableMonth daysToAdd={parseInt(calendarView)} date={date} booked={booked}
                                            availableTimes={availableTimes}
                                            tutorId={tutorId} setShowSidebar={setShowSidebar}
                                            setSelectedDate={setSelectedDate}/>

                        <div className={"schedule__sidebar" + (showSidebar ? ' open' : '')}>
                            {
                                selectedDate && <>
                                    <h5 className="p-3 pb-1">{formatDate(selectedDate)}</h5>
                                    <a className="p-3 pt-0 link">Visit day</a>

                                    <div className="flex-grow-1 p-3">
                                        {getSidebarInfo()}
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                }
            </div>

        </div>
    );
}

export default Schedule;
