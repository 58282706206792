import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export const MeetingSlicer = createSlice({
    name: 'meeting',
    initialState: {
        value: false,
    },
    reducers: {
        setMeeting: (state, action: PayloadAction<any>) => {
            state.value = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const {setMeeting} = MeetingSlicer.actions

export default MeetingSlicer.reducer