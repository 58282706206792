import React, {MouseEventHandler} from 'react';
import {BsBell} from "react-icons/bs";
import "./NotificationIcon.scss"

type Props = {
    navNotificationsOpen: MouseEventHandler<HTMLDivElement>;
};

const NotificationIcon = ({navNotificationsOpen}: Props) =>
    <div className="navbar-container__user-notification ignore-event" onClick={navNotificationsOpen}>
        <BsBell className={'ignore-event'} size={20} color={'white'}/>
        <span className="navbar-popup__user-notification-count ignore-event"></span>
    </div>
export default NotificationIcon