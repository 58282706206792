import React from "react";
import {useState} from 'react';
import './login.scss';
import '../../scss/main.scss';
import {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import $ from 'jquery';
import {tokenToCookie} from "../../utils/token-to-cookie";
import {MouseEvent} from "react";
import dbCore from "../../controller/core";
import CaptchaForm from "../../components/CaptchaForm/CaptchaForm";

const Login = () => {

    const navigate = useNavigate();

    useEffect(() => {
        // Add reCaptcha
        const script = document.createElement("script")
        script.src = "https://www.google.com/recaptcha/api.js?render=6Lea0oooAAAAALdD-1UxDcaMTsyvDhvLj26GHeWl"
        // script.addEventListener("load", handleLoaded)
        document.body.appendChild(script)
    });

    // LOGIN ERROR MESSAGES HANDLING
    const errorMassages = {
        emptyFields: 'Please fill all the fields',
        invalidEmail: 'Invalid email',
        passwordsMismatch: 'Passwords do not match',
        unexpectedError: 'Unexpected Error',
        genericError: 'Something went wrong',
        emailTaken: 'The email is already in use'
    }

    const [errorMessage, setErrorMessage] = useState({
        visible: false, message: ''
    });

    const registerNowClick = () => {
        navigate('/register');
    };

    const login = () => {
        const email = $('[name="email"]').val();
        const password = $('[name="password"]').val();

        if (email === '' || password === '' || typeof email !== 'string' || typeof password !== 'string') {
            setErrorMessage({visible: true, message: errorMassages.emptyFields});
        } else {
            const data = {
                'email': email, 'password': password
            }

            dbCore.login(data)
                .then((data: any) => {
                    if (data.status != null && data.status === 200) {
                        setErrorMessage({...errorMessage, visible: false});
                        tokenToCookie(data.user);
                        window.location.href = '/'
                    } else {
                        if (data.message) setErrorMessage({visible: true, message: data.message});
                    }
                })
        }
    }

    const bgStyle = {
        backgroundImage: `url(${process.env.PUBLIC_URL + '/resources/login-bg.jpg'})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    };

    return (<div style={bgStyle} className="login-page">
        <div className={'login-page__left'}>
            <h1 className="login-page__left-title text-center">Σύνδεση</h1>

            <CaptchaForm callback={login} id={"login-form"} className={"login-page__left-form"}>
                <label className="login-page__left-form-label">Email</label>
                <input placeholder="π.χ. john@edupal.gr" name="email" type="text"
                       className="input input-primary text-dark login-page__left-form-input mt-1"/>
                <label className="login-page__left-form-label mt-4">Κωδικός</label>
                <input placeholder="Πληκτρολογήστε τον κωδικό σας" name="password" type="password"
                       className="input input-primary text-dark login-page__left-form-input mt-1 mb-4"/>
                <div
                    className={'alert alert-success ' + (errorMessage.visible ? '' : 'd-none')}>{errorMessage.message}</div>
                <button type="submit" className="button font-weight-bold text-center mt-4">
                    Είσοδος
                </button>
                <label className="login-page__left-form-label mt-3">Δεν έχετε λογαριασμό;<a
                    onClick={registerNowClick} className="link link-primary m-2">Εγγραφείτε εδώ.</a></label>
            </CaptchaForm>
        </div>

        <div className="w-50 d-none d-lg-block">
            <img className={'register__background'} src="/resources/register-image.jpg" alt="register-image"/>
        </div>
    </div>);
};

export default Login;