import React, {useContext, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {ContextManager} from "../../../app/socket";
import {setCurrentBooking} from "../../../reducers/CurrentBookingSlicer";
import {setTodayBookings} from "../../../reducers/TodayBookingsSlicer";
import {setTickets} from "../../../reducers/TicketsSlice";

const AppLogic = () => {

    const profile = useSelector((state: any) => state.profile.value);

    const context = useContext(ContextManager);
    const dispatch = useDispatch();

    useEffect(() => {
        checkForBookings();
        getTicketsNumber();
    }, [context]);

    const getTicketsNumber = () => {
        if (!profile || profile.user_type !== 'student')
            return;

        if (profile && profile.tickets) {
            dispatch(setTickets(profile.tickets));
        }

        if (context && context.socket) {
            context.socket.emit('getTickets');
            context.socket.on('onTickets', onTicketsListener);
        }

        return () => {
            if (context.socket)
                context.socket.off('onCurrentUserSchedule', onTicketsListener);
        };
    }

    const onTicketsListener = (tickets: any) => {
        if (tickets) {
            dispatch(setTickets(tickets));
        }
    }

    const checkForBookings = () => {
        if (context && context.socket && profile && profile.user_type) {
            const isTutor = profile.user_type === 'professor';
            context.socket.emit('getCurrentUserSchedule', isTutor);

            context.socket.on('onCurrentUserSchedule', currentUserScheduleHandler);
        }

        return () => {
            if (context.socket)
                context.socket.off('onCurrentUserSchedule', currentUserScheduleHandler);
        };
    }

    const currentUserScheduleHandler = (data: any) => {
        if (data) {
            const currentDate = new Date();
            const min = 60 * 1000;

            let nextBooking: any;

            dispatch(setTodayBookings(data));

            data.forEach(function (booking: any) {
                const bookDate = new Date(booking.date);
                const tenMinutesBefore = new Date(bookDate.getTime() - 10 * min);
                const fiveMinutesBefore = new Date(bookDate.getTime() - 5 * min);
                const fiveMinutesAfter = new Date(bookDate.getTime() + 5 * min);
                const thirtyMinutesAfter = new Date(bookDate.getTime() + 30 * min);

                if (currentDate >= fiveMinutesBefore && currentDate < bookDate) {
                    console.log("Current date is within 5 minutes before specDate");
                    nextBooking = {...booking};
                    nextBooking.title = 'Upcoming lesson with Tasos is in 5 minutes';
                    nextBooking.ready = false;
                    return;
                }

                if (currentDate >= tenMinutesBefore && currentDate < bookDate) {
                    console.log("Current date is within 10 minutes before specDate");
                    nextBooking = {...booking};
                    nextBooking.title = 'Upcoming lesson with Tasos is in 10 minutes';
                    nextBooking.ready = false;
                    return;
                }

                if (currentDate < thirtyMinutesAfter && currentDate < fiveMinutesAfter && currentDate >= bookDate) {
                    console.log("Current date is within 5 minutes after the start");
                    nextBooking = {...booking};
                    nextBooking.title = 'Your lesson with Tasos started!';
                    nextBooking.ready = true;
                    return;
                }

                if (currentDate < thirtyMinutesAfter && currentDate >= bookDate) {
                    console.log("Current date is less than 30 minutes after specDate");
                    nextBooking = {...booking};
                    nextBooking.title = 'Your lesson with Tasos is running late';
                    nextBooking.ready = true;
                    return;
                }
            })

            if (context && context.socket && nextBooking) {
                nextBooking.created = currentDate.toString();
                dispatch(setCurrentBooking(nextBooking))
            }
        }
    }

    return (
        <>
        </>
    )
}
export default AppLogic