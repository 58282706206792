import React, {useState, useRef, useEffect, useContext} from "react";
import {Link} from "react-router-dom";
import './NavbarApp.scss';
import {useDispatch, useSelector} from "react-redux";
import Notifications from "../../Notifications/Notifications";
import {FiMenu} from "react-icons/fi";
import {useLocation} from 'react-router-dom';
import ThemeToggle from "../../ThemeToggle/ThemeToggle";
import NotificationIcon from "../Subcomponents/NotificationIcon/NotificationIcon";
import NavDesktopLinks from "../Subcomponents/NavDesktopLinks";
import AppLogic from "./AppLogic";
import {ContextManager} from "../../../app/socket";
import {setNotifications} from "../../../reducers/NotificationsSlicer";
import {BiSolidDashboard} from "react-icons/bi";
import {BsFillCalendarCheckFill} from "react-icons/bs";
import Tickets from "../../Tickets/Tickets";
import {AiOutlineDoubleLeft} from "react-icons/ai";
import Logout from "../Subcomponents/Logout/Logout";

function NavbarApp() {
    const profile = useSelector((state: any) => state.profile.value);
    const themeMode = useSelector((state: any) => state.themeMode.value);

    const [minimized, setMinimized] = useState(true);

    const location = useLocation();

    const dispatch = useDispatch();

    const context = useContext(ContextManager);

    useEffect(() => {
        if (context && context.socket) {
            context.socket.emit('getNotifications');

            context.socket.on('notification', notificationListener);
            return () => {
                if (context && context.socket)
                    context.socket.off('notification', notificationListener);
            }
        }
    }, [context]);

    useEffect(() => {
        document.body.style.overflow = 'auto';
    }, [location]);

    const notificationListener = (notifications: any) => {
        if (notifications && notifications.length)
            dispatch(setNotifications(notifications));
    }

    const unauthorizedLinks = [
        <Link to="/about">About</Link>,
        <Link to="/contact-us">Contact Us</Link>,
    ]

    const studentLinks = [
        <Link to="/app">
            <BiSolidDashboard size={26} className={'mr-3'}/>
            <span>Dashboard</span>
        </Link>,
        <Link to="/book">
            <BsFillCalendarCheckFill size={20} className={'mr-3'}/>
            <span>Book</span>
        </Link>,
    ]

    const professorLinks = [
        <Link to="/app">
            <BiSolidDashboard size={26} className={'mr-3'}/>
            <span>Dashboard</span>
        </Link>,
        <Link to="/schedule">
            <BsFillCalendarCheckFill size={20} className={'mr-3'}/>
            <span>Schedule</span>
        </Link>,
    ]

    const wrapperRef = useRef<HTMLDivElement>(null);

    function handleClickOutside(event: MouseEvent) {
        const element = event.target as Element;
        // if target has class ignore-event don't proceed bubbling
        if (event.target && (element.classList.contains('ignore-event') || element.tagName === 'svg')) {
            document.removeEventListener("mousedown", handleClickOutside);
            return;
        }

        if (wrapperRef) {
            if (wrapperRef.current && !wrapperRef.current.contains(element)) {
                if (!element.classList.contains('navbar-app-container__user-notification-img')) {
                    setNavNotifications(false);

                    document.removeEventListener("mousedown", handleClickOutside);
                }
            }
        }
    }

    const navbarPopup = () => {
        document.addEventListener("mousedown", handleClickOutside);
    };

    const [navNotifications, setNavNotifications] = useState(false);

    const navNotificationsOpen = () => {
        document.removeEventListener("mousedown", handleClickOutside);

        if (navNotifications) {
            setNavNotifications(false);
        } else {
            setNavNotifications(true);
            navbarPopup();
        }
    };

    return (
        <div className={"navbar-app" + (minimized ? ' minimized' : '')}>
            {profile.user_type && <AppLogic/>}

            <div className="navbar-app-container d-flex">
                <div className={'navbar-app-container__menu-icon'} onClick={() => {
                    setMinimized(!minimized)
                }}>
                    <FiMenu size={'35px'} color={'#000'}/>
                </div>

                <div>
                    <div className="main-logo mb-5">
                        <Link to="/app">
                            {
                                themeMode && (
                                    <img src="/resources/logo.png" alt={'main-logo'}/>
                                )
                            }

                            {
                                !themeMode && (
                                    <img src="/resources/logo-dark.png" alt={'main-logo'}/>
                                )
                            }
                        </Link>
                    </div>

                    <ul className="navbar-app-container__links">
                        <NavDesktopLinks profile={profile} studentLinks={studentLinks}
                                         unauthorizedLinks={unauthorizedLinks} professorLinks={professorLinks}/>
                    </ul>
                </div>

                <div className="navbar-app-container__user">

                    {profile.user_type === 'student' && <Tickets/>}

                    <Link to="/profile" className="navbar-app-container__user-icon mb-3">
                        <img className={'ignore-event'}
                             src={profile.profile_picture !== '' ? profile.profile_picture : '/resources/student.png'}
                             alt={'profile picture'}/>

                        {
                            !minimized &&
                            <div className="d-flex flex-column justify-content-center">
                                <div>{profile.first_name}</div>
                                <div>{profile.last_name}</div>
                            </div>
                        }
                    </Link>

                    <div className={'d-flex gap-3 align-items-center' + (minimized ? ' flex-column' : '')}>
                        <NotificationIcon navNotificationsOpen={navNotificationsOpen}/>
                        <ThemeToggle/>
                    </div>

                    <Logout minimized={minimized}/>
                </div>
            </div>

            <div ref={wrapperRef}>
                <Notifications navNotifications={navNotifications}/>
            </div>

            <div className="navbar-app__toggle-bar" onClick={() => {
                setMinimized(!minimized)
            }}>
                <AiOutlineDoubleLeft size={20}/>
            </div>

        </div>
    );
}

export default NavbarApp;
