import {calls} from "../../../config/db_config";

type Props = {
    email: string,
    password: string
}

const login = async (data: Props) => {
    let response = await fetch(calls.signIn, {
        method: 'post', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(data)
    });
    return await response.json();
}

export default login;