import React from 'react';
import './Testimonials.scss';

// @ts-ignore
import TutorProfile from '../../resources/image-1.png';
import {AiFillStar} from "react-icons/ai";


function Testimonials() {

    const starCount = 5;

    function renderStars() {
        const stars = [];

        for (let i = 0; i < starCount; i++) {
            stars.push(<AiFillStar key={i} style={{ filter: "drop-shadow(0px 0px 3px rgba(255, 255, 255, .3))" }}/>);
        }

        return stars;
    }

    return (
        <>
            <div className="testimonials container d-flex flex-column">
                <div className="row mx-auto col-12 col-sm-10 col-md-8 col-lg-8 col-xl-6 col-xxl-6">
                    <h1 className="testimonials-title text-center">
                        Trusted by thousands of students
                        across Japan.
                    </h1>
                </div>
                <div className="row d-flex justify-content-evenly">
                    <div
                        className="testimonials-card d-flex flex-column justify-content-between col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                        <img src={TutorProfile} alt={"tutor-profile"}/>
                        <div className="row d-flex justify-content-between">
                            <h5 className="testimonials-tutorName col-8 d-flex mb-0">Anastasios T.</h5>
                            <div className="testimonials-stars col-4 d-flex justify-content-end align-items-center">
                                {renderStars()}
                            </div>
                        </div>
                        <p className="testimonials-text mb-0">
                            "Mr. Anastasios is an excellent Tutor. He's very friendly, patient and made topics I
                            struggled with, easy to understand.
                            Highly recommending and looking forward to more lessons with him!"
                        </p>
                        <h5 className="testimonials-studentName mb-0">
                            - Enthusiastic Pepe
                        </h5>
                    </div>
                    <div
                        className="testimonials-card d-flex flex-column justify-content-between col-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                        <img src={TutorProfile} alt={"tutor-profile"}/>
                        <div className="row d-flex justify-content-between">
                            <h5 className="testimonials-tutorName col-8 d-flex mb-0">Lincoln S.</h5>
                            <div className="testimonials-stars col-4 d-flex justify-content-end align-items-center">
                                {renderStars()}
                            </div>
                        </div>
                        <p className="testimonials-text mb-0">
                            "Mr. Lincoln is an excellent Tutor. He's very friendly, patient and made topics I struggled
                            with, easy to understand.
                            Highly recommending and looking forward to more lessons with him!"
                        </p>
                        <h5 className="testimonials-studentName mb-0">
                            - Skeptical Pepe
                        </h5>
                    </div>

                </div>

            </div>
        </>
    )
}


export default Testimonials;