import {createSlice} from '@reduxjs/toolkit'

export const NotificationsSlicer = createSlice({
    name: 'notifications',
    initialState: {
        value: [],
    },
    reducers: {
        setNotifications: (state, action: any) => {
            state.value = action.payload;
        },
        pushNotification: (state: any, action: any) => {
            let tempArray: any = state.value;
            tempArray.push(action.payload);
            state.value = [...tempArray];
        },
    },
})

// Action creators are generated for each case reducer function
export const {setNotifications, pushNotification} = NotificationsSlicer.actions

export default NotificationsSlicer.reducer