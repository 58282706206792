import React, {useEffect} from 'react';
import {Outlet} from 'react-router';
import {setProfileData} from "../../reducers/navbarSlice";
import {useDispatch} from "react-redux";
import Footer from "../../components/Footer/Footer";
import NavbarRoot from "../../components/Navbar/Simple/NavbarRoot";
import dbCore from "../../controller/core";
import User from "../../models/user";
import {getAuth, onAuthStateChanged} from 'firebase/auth';

type Response = {
    data: any,
    status: number
}

const FreePass = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        try {
            const auth = getAuth();
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    dbCore.getUserData().then((data: User) => {
                        dispatch(setProfileData(data));
                    });
                }
            });

            // const token = tokenUtils.getBearerToken();
            // if (token) {
            //     const requestHeaders: HeadersInit = new Headers();
            //     requestHeaders.set('Content-Type', 'application/json');
            //     requestHeaders.set('Authorization', token);
            //
            //     dbCore.login().then((data: Response) => {
            //         console.log(data);
            //
            //         if (data.status === 200) {
            //             let profileData = data.data;
            //             dispatch(setProfileData(profileData));
            //         }
            //     })
            // }
        } catch (e) {
        }
    });

    return (
        <>
            <NavbarRoot/>
            <Outlet/>
            <Footer/>
        </>
    );
};

export default FreePass