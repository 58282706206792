import React from "react";
import "./TermsAndConditions.scss";

function TermsAndConditions() {

    return (
        <div className="terms container-fluid d-flex flex-column h-100">
            <div className="container mb-15 mt-10">

                <header className="mb-12">
                    <h1 className="fw-bold">Eng-Link - Terms and Conditions</h1>
                    <p>Last Updated: 15/09/2023</p>
                </header>

                <section className="mb-5">
                    <h3>1. Acceptance of Terms</h3>
                    <p>
                        By using the Eng-Link tutoring web app ("the App"), you agree to abide by these terms and conditions. If you do not agree with any part of these terms, please refrain from using the App.
                    </p>
                </section>

                <section className="mb-5">
                    <h3>2. Registration</h3>
                    <p>
                        a. Users must be at least 18 years of age or have the legal capacity to enter into agreements to use the App.
                    </p>
                    <p>
                        b. Users are required to provide accurate and up-to-date information during registration.
                    </p>
                </section>

                <section className="mb-5">
                    <h3>15. Contact Information</h3>
                    <p>
                        a. For any questions or concerns regarding these terms, please contact us at <a href="mailto:info@eng-link.com"
                                                                                                        className="link">eng-link</a>.
                    </p>
                </section>

            </div>
        </div>
    );
}

export default TermsAndConditions;
