import {createSlice} from '@reduxjs/toolkit';

export const TicketsSlice = createSlice({
    name: 'tickets',
    initialState: {
        value: 0,
    },
    reducers: {
        setTickets: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const {setTickets} = TicketsSlice.actions

export default TicketsSlice.reducer