import React from 'react';
import './Notes.scss';

const Notes = () => {

    return (
        <div className={'notes'}>

        </div>
    );
};

export default Notes;