import {getDatabase, ref, get, query, startAfter, orderByKey, limitToFirst} from "firebase/database";
import {useEffect, useState} from "react";
import User from "../../../models/user";

export default function useGetTutors(page: number, perLoad: number, onGetTutorsBatch: (data: Array<User>) => void) {

    const [lastId, setLastId] = useState('0')

    const getArray = (pageArg: number, perLoadArg: number) => {
        const db = getDatabase();

        // const startAtNumber = (pageArg - 1) * perLoadArg;
        // // const endAtNumber = startAtNumber + perLoadArg;

        const tutorsRef = query(ref(db, 'users'), orderByKey(),
            startAfter(lastId.toString()), limitToFirst(perLoadArg));

        get(tutorsRef).then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                if (data && data as Array<User>) {
                    const dataArray = Object.keys(data).map((key) => ({
                        _id: key, // Use the key as an identifier (optional)
                        ...data[key], // Spread the properties of each nested object
                    }));

                    setLastId(dataArray[dataArray.length - 1]._id);
                    onGetTutorsBatch(dataArray);
                }
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    useEffect(() => {
        getArray(page, perLoad);
    }, []);

    return (newPage: number, newNumberToShow: number) => {
        getArray(newPage, newNumberToShow);
    }

}