import React, {useEffect} from 'react';
import {Outlet} from 'react-router';
import {setProfileData} from "../../reducers/navbarSlice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import NavbarRoot from "../../components/Navbar/Simple/NavbarRoot";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import dbCore from "../../controller/core";
import User from "../../models/user";

const Unauthorized = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        try {
            // const token = tokenUtils.getBearerToken();
            //
            // if (token) {
            //     const requestHeaders: HeadersInit = new Headers();
            //     requestHeaders.set('Content-Type', 'application/json');
            //     requestHeaders.set('Authorization', token);
            //
            //     fetch(calls.getUserData, {
            //         method: 'get',
            //         headers: requestHeaders
            //     }).then(response => response.json()).then(data => {
            //         if (data.status === 200) {
            //             let profileData = data.data;
            //             dispatch(setProfileData(profileData));
            //
            //             if (profileData.user_type === 'student' || profileData.user_type === 'professor') {
            //                 navigate("/");
            //             }
            //         }
            //     })
            // }


            const auth = getAuth();
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    dbCore.getUserData().then((data: User) => {
                        if (data) {
                            let profileData = data;
                            dispatch(setProfileData(profileData));

                            if (profileData.user_type === 'student' || profileData.user_type === 'professor') {
                                navigate("/");
                            }
                        }
                    });
                }
            });
        } catch (e) {
            console.log(e)
        }
    });

    return (
        <>
            <NavbarRoot/>
            <Outlet/>
            <Footer/>
        </>
    );
};

export default Unauthorized