import React, {
    useContext,
    useEffect,
    useRef,
    useState
} from 'react';
import './chat.scss';
import {IoMdAddCircleOutline, IoMdSend} from "react-icons/io";
import {ContextManager} from "../../app/socket";
import {TbMessageCircleOff} from "react-icons/tb";
import {HiOutlineUserGroup} from "react-icons/hi";
import {useSelector} from "react-redux";

type Message = {
    _id: string,
    chat_room_id: string,
    date: Date,
    from: string,
    message_text: string
}

type ChatRoom = {
    _id: string,
    name: string,
    date: Date,
    image: string,
    last_message: string,
    participants: Array<Participant>,
    profile_picture?: string,
    email?: string,
    user_id?: string,
}

type Participant = {
    profile_picture: string,
    name: string,
    first_name?: string,
    last_name?: string,
    _id: string
}

type Props = {
    singleMode?: boolean;
}

const ChatUI = ({singleMode = true}: Props) => {

    const profile = useSelector((state: any) => state.profile.value);
    const meeting = useSelector((state: any) => state.meeting.value);

    const messagesEndRef = useRef<HTMLDivElement>(null);

    const {socket} = useContext(ContextManager);

    const [message, setMessage] = useState('');

    const [selfId, setSelfId] = useState(null);

    const [chatLogs, setChatLogs] = useState<any>({});

    const [possibleParticipants, setPossibleParticipants] = useState<Array<Participant>>([]);

    const [readyParticipants, setReadyParticipants] = useState<Array<Participant>>([]);

    const [participants, setParticipants] = useState<Array<Participant>>();
    const [participantsObj, setParticipantsObj] = useState<any>({});

    const [users, setUsers] = useState<Array<Participant>>([]);

    const [participantsInput, setParticipantsInput] = useState('');

    const [chatRooms, setChatRooms] = useState<Array<ChatRoom>>([]);

    const [chatRoomSelected, setChatRoomSelected] = useState<number>();

    const [currentChatId, setCurrentChatId] = useState<string>();

    const [showNewChatInput, setShowNewChatInput] = useState(false);

    const getMessagesListener = (value: Array<Message>) => {
        let tempLogs = chatLogs;

        if (currentChatId) {
            tempLogs[currentChatId] = value;
            setChatLogs({...tempLogs});

            setTimeout(function () {
                scrollToBottom();
            }, 200)
        }
    }

    const receiveMessageListener = (value: Message, roomId: string) => {
        let tempLogs = chatLogs;

        if (tempLogs[roomId]) {
            tempLogs[roomId].push(value);
            setChatLogs({...tempLogs});
            scrollToBottom();
        }
    }

    const getChatRoomId = (chatRoomArg: ChatRoom) => {
        let chat: ChatRoom = {
            _id: '',
            name: '',
            date: new Date(),
            image: '',
            last_message: '',
            participants: []
        };
        let chatIndex;
        let foundChat = false;
        chatRooms.forEach(function (chatRoom: ChatRoom, index) {
            if (chatRoom._id === chatRoomArg._id) {
                chat = chatRoom;
                chatIndex = index;
                foundChat = true;
            }
        });

        if (foundChat) {
            if (!socket)
                return;

            setChatRoomSelected(chatIndex);

            socket.emit('getMessages', chat._id);

            // array participants
            setParticipants([...chat.participants]);
            setPossibleParticipants([])

            // object
            let partsObj: any = {};
            chat.participants.forEach(function (part) {
                partsObj[part._id] = part;
            })
            setParticipantsObj(partsObj);
        } else {
            let chatRoomObj: any = {};
            chatRoomObj.participants = readyParticipants;

            setCurrentChatId(chatRoomArg._id);

            let tempChatRooms = chatRooms;
            tempChatRooms.unshift(chatRoomArg);
            setChatRooms([...tempChatRooms]);

            let tempChatLogs = chatLogs;
            tempChatLogs[chatRoomArg._id] = [];
            setChatLogs({...tempChatLogs})

            setChatRoomSelected(0);

            // array participants
            setParticipants([...chatRoomArg.participants]);
            setPossibleParticipants([]);
            setReadyParticipants([]);

            // object
            let partsObj: any = {};
            chatRoomArg.participants.forEach(function (part) {
                partsObj[part._id] = part;
            })
            setParticipantsObj(partsObj);
        }
    }

    useEffect(() => {
        if (!socket)
            return;

        socket.on('currentUser', (value) => {
            if (!value)
                return;

            setSelfId(value._id);
            let parts = [];

            let selfObj = {
                profile_picture: value.profile_picture,
                name: value.first_name + ' ' + value.last_name,
                _id: value._id
            };

            parts.push(selfObj);
            setParticipants([...parts]);

            let partsObj: any = {};
            partsObj[value._id] = selfObj;
            setParticipantsObj(partsObj);
        });
        socket.emit('getCurrentUser');

        // user data
        const getUsersListener = (data: Array<Participant>) => {
            setUsers(data);
        }
        socket.on('allUsersWithParams', getUsersListener);
        socket.emit('getAllUsersWithParams');
        socket.on('refreshAllUsersWithParams', () => {
            socket.emit('getAllUsersWithParams');
        });

        // get chat list
        socket.emit('getChatList');
        socket.on('onChatList', onChatListListener);
    }, []);

    useEffect(() => {
        if (chatLogs && socket) {
            socket.on('receiveMessage', receiveMessageListener);
            return () => {
                socket.off('receiveMessage', receiveMessageListener);
            };
        }
    }, [chatLogs]);

    useEffect(() => {
        if (chatLogs && currentChatId && socket) {
            socket.on('messagesListener', getMessagesListener);

            return () => {
                socket.off('messagesListener', getMessagesListener);
            };
        }
    }, [chatLogs, currentChatId]);

    useEffect(() => {
        if (socket) {
            socket.on('chatRoomId', getChatRoomId);

            return () => {
                socket.off('chatRoomId', getChatRoomId);
            };
        }
    }, [chatRooms]);

    useEffect(() => {
        if (users && users.length && profile && meeting && singleMode) {
            setTimeout(getSingleUser, 200);
        }
    }, [profile, meeting, users]);

    useEffect(() => {
        if (chatRoomSelected && chatRooms[chatRoomSelected])
            selectRoom(chatRoomSelected, chatRooms[chatRoomSelected]);
    }, [chatRoomSelected]);

    const getSingleUser = () => {
        const otherUserId = profile._id === meeting.tutor_id._id ? meeting.student_id._id : meeting.tutor_id._id;

        let userFound: Participant | null = null;
        users.forEach((user) => {
            if (user._id === otherUserId) {
                userFound = user;
            }
        })

        if (userFound) {
            selectUser(userFound);
        }
    }

    const onChatListListener = (value: Array<ChatRoom>) => {
        const groupedArray: any = {};
        let emptyArray: any = {};

        value.forEach(obj => {
            const chatRoomId = obj._id;
            if (!groupedArray[chatRoomId]) {
                groupedArray[chatRoomId] = obj;
            }
            // const participant = {
            //     name: obj.name,
            //     _id: obj.user_id,
            //     profile_picture: obj.profile_picture,
            //     email: obj.email
            // };
            // groupedArray[chatRoomId].participants.push(participant);

            // have an object assigned to the chatRoomId with an empty array (there will be the messages of each chat)
            let tempObj: any = {};
            tempObj[chatRoomId] = [];
            emptyArray = {...emptyArray, ...tempObj};
        });

        const resultArray: Array<ChatRoom> = Object.values(groupedArray);

        setChatRooms(resultArray);
        setChatLogs({emptyArray});
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const element = e.target as HTMLInputElement;
        setMessage(element.value);
    };

    const handleEnterButton = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            handleSend();
        }
    };

    const handleSend = () => {
        if (message && socket) {
            socket.emit('sendMessage',
                currentChatId,
                participants,
                {from: selfId, message_text: message});

            scrollToBottom();
            setMessage('');
        }
    };

    const scrollToBottom = () => {
        if (messagesEndRef && messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "start"
            });
        }
    };

    const findInUsers = () => {
        const pattern = new RegExp([...participantsInput].join('.*?'), 'i');
        let parts: Array<Participant> = [];

        users.forEach(function (user) {
            let userName = user.first_name + ' ' + user.last_name;
            if (pattern.test(userName) || userName.includes(participantsInput)) {
                parts.push({profile_picture: user.profile_picture, name: userName, _id: user._id});
            }
        });

        setPossibleParticipants([...parts]);
    };

    const findParticipants = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        setParticipantsInput(value);
        setPossibleParticipants([]);
        findInUsers();
    };

    const selectUser = (possibleParticipant: Participant) => {
        let tempParts = readyParticipants;
        tempParts.push(possibleParticipant);
        setReadyParticipants([...tempParts]);

        let tempParticipants = []
        tempParticipants.push(possibleParticipant._id);

        if (socket)
            socket.emit('getUserChat', tempParticipants);
    };

    const searchFocusOut = () => {
        if (participantsInput === '')
            setPossibleParticipants([]);
    };

    const selectRoom = (index: number, chat: ChatRoom) => {
        setChatRoomSelected(index);
        setCurrentChatId(chat._id);

        if (!socket)
            return;
        socket.emit('getMessages', chat._id);

        // array participants
        setParticipants([...chat.participants]);
        setPossibleParticipants([])

        // object
        let partsObj: any = {};
        chat.participants.forEach(function (part) {
            partsObj[part._id] = part;
        })
        setParticipantsObj(partsObj);
    };

    const createNewChat = () => {
        setShowNewChatInput(true);
    };

    const handleScroll = () => {
        const chatWindow = document.querySelector('.chat__window');

        if (!chatWindow)
            return;

        // Calculate the distance from the bottom of the scrollable content
        const distanceFromBottom = chatWindow.scrollHeight - chatWindow.scrollTop - chatWindow.clientHeight;

        // Define the minimum distance from the bottom you want to allow scrolling
        const minDistanceFromBottom = 90;

        // Check if the distance from the bottom is less than the minimum distance
        if (distanceFromBottom < minDistanceFromBottom) {
            // Prevent scrolling to the bottom 20px by adjusting the scrollTop
            chatWindow.scrollTop = chatWindow.scrollHeight - chatWindow.clientHeight - minDistanceFromBottom;
        }
    };

    return (
        <div className="chat">
            <div className="chat__header">
                {participants && participants.length > 0 && (
                    <div className="chat__participants">
                        {participants.map((participant, index) => (
                            <div key={index} className='chat__select-item'>
                                <img className='chat__message-img'
                                     src={participant.profile_picture !== '' ? participant.profile_picture : '/resources/student.png'}
                                     alt=""/>
                                <div className='chat__user-name'>{participant.name}</div>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className="chat__wrap">
                <div className="chat__sidebar">
                    <div className='d-flex align-items-center justify-content-between p-2 border-bottom'>
                        <div>Chat</div>
                        <IoMdAddCircleOutline size={30} onClick={createNewChat}/>
                    </div>

                    {
                        showNewChatInput && (
                            <div className='position-relative'>
                                <input value={participantsInput} type="text" className="input chat__search"
                                       onChange={findParticipants}
                                       onBlur={searchFocusOut}/>

                                {possibleParticipants.length > 0 && (
                                    <div className="chat__possible-participants">
                                        {possibleParticipants.map((possibleParticipant, index) => (
                                            <div key={index} className='chat__select-item'
                                                 onClick={() => selectUser(possibleParticipant)}>
                                                <img className='chat__message-img'
                                                     src={possibleParticipant.profile_picture !== '' ? possibleParticipant.profile_picture : '/resources/student.png'}
                                                     alt=""/>
                                                <div className='chat__user-name'>{possibleParticipant.name}</div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )
                    }

                    {chatRooms.map((chat, index) => (
                        <div key={index} className={'chat__room-item' + (chatRoomSelected === index ? ' selected' : '')}
                             onClick={() => selectRoom(index, chat)}>
                            {
                                chat.participants && chat.participants.length === 1 && (
                                    <img className='chat__message-img'
                                         src={chat.participants[0].profile_picture !== '' ? chat.participants[0].profile_picture : '/resources/student.png'}
                                         alt=""/>
                                )
                            }

                            {
                                chat.participants.length > 1 && (
                                    <HiOutlineUserGroup size={35} color={'black'}/>
                                )
                            }

                            <div className="chat__chat-info">
                                <div className='chat__user-name'>{chat.participants.length > 1
                                    ? chat.name : chat.participants[0].first_name + ' ' + chat.participants[0].last_name}</div>
                                <div className='chat__last-msg'>{chat.last_message}</div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="chat__main">
                    {
                        currentChatId == null && (
                            <div className={'chat__no-messages'}>
                                <TbMessageCircleOff size={40} color={'#3a3a3a'}/>
                                No messages
                            </div>
                        )
                    }

                    {
                        (currentChatId != null && chatLogs[currentChatId]) && (
                            <>
                                <div className="chat__window" onScroll={handleScroll}>
                                    {chatLogs[currentChatId].map((msg: Message, index: number) => (
                                        <div key={index} className={"chat__message" + (msg.from === selfId ? ' self' : '')}>
                                            <img className={'chat__message-img'}
                                                 src={participantsObj[msg.from] && participantsObj[msg.from].profile_picture !== '' ?
                                                     participantsObj[msg.from].profile_picture : '../../../resources/student.png'}
                                                 alt={'profile picture'}/>

                                            <div className="chat__message-msg">
                                                {msg.message_text}
                                            </div>
                                        </div>
                                    ))}
                                    <div className={'chat__scroll-view'} ref={messagesEndRef}/>
                                </div>
                                <div className="chat__input">
                                    <input
                                        type="text"
                                        value={message}
                                        onChange={handleInputChange}
                                        onKeyDown={handleEnterButton}
                                        className="input w-100"
                                        placeholder="Type your message here..."
                                    />
                                    <div className={'chat__send-btn'} onClick={handleSend}>
                                        <IoMdSend size={30}/>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default ChatUI;