import React, {useState} from "react";
import './Account.scss';
import {tokenUtils} from "../../../../utils/token-utils";
import {calls} from "../../../../config/db_config";
import {BiCheck, BiSolidPencil, BiX} from "react-icons/bi";
import {useDispatch, useSelector} from "react-redux";


function Account() {

    const profile = useSelector((state: any) => state.profile.value)
    const dispatch = useDispatch();

    const [showResetPwdModal, setShowResetPwdModal] = useState(false);
    const [oldPwdInput, setOldPwdInput] = useState('');
    const [newPwdInput, setNewPwdInput] = useState('');
    const [newPwdRepeatInput, setNewPwdRepeatInput] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [successModalShow, setSuccessModalShow] = useState(false);

    const changePasswordSubmit = () => {
        setErrorMsg('');

        if (newPwdInput != newPwdRepeatInput) {
            setErrorMsg('Οι νέοι κωδικοί δεν είναι ίδιοι.');
        } else {
            const body = {
                'old_password': oldPwdInput,
                'new_password': newPwdInput
            }

            const token = tokenUtils.getBearerToken();

            if (!token)
                return;

            const requestHeaders: HeadersInit = new Headers();
            requestHeaders.set('Content-Type', 'application/json');
            requestHeaders.set('Authorization', token);

            fetch(calls.changePassword, {
                method: 'post',
                headers: requestHeaders,
                body: JSON.stringify(body)
            }).then(response => response.json()).then(data => {
                if (data.status === 200) {
                    setSuccessModalShow(true);
                    setShowResetPwdModal(false)
                    setTimeout(() => {
                        setSuccessModalShow(false);
                    }, 3000);
                } else {
                    setErrorMsg('Ο παλιός κωδικός δεν είναι έγκυρος. Ξαναπροσπαθήστε');
                }
            })
        }
    }

    const iconStyle = (bgColor: string) => {
        return {background: bgColor, padding: '5px', borderRadius: '50%', marginBottom: '8px'}
    }

    return (
        <>
            <div className="account container-fluid d-flex flex-column">
                <div className={"reset-pwd__success " + (successModalShow ? 'show' : '')}>
                    <div className="wrapper">
                        <span>
                            Ο κωδικός άλλαξε!
                        </span>
                        <BiCheck size={'40px'} color={'#fff'} style={iconStyle('#91FE8F')}/>
                    </div>
                </div>
                <div className={"reset-pwd__popup " + (showResetPwdModal ? 'open' : '')}>
                    <div className="container">
                        <div className="title">
                            Αλλαγή κωδικού
                        </div>
                        <div className="pwd-input">
                            <span className="pwd-input__label">
                                Παλιός κωδικός
                            </span>
                            <input type="password" className="input" placeholder='Εισάγεται τον παλιό κωδικό'
                                   onChange={(e) => setOldPwdInput(e.target.value)}/>
                        </div>
                        <div className="pwd-input">
                            <span className="pwd-input__label">
                                Καινουριός κωδικός
                            </span>
                            <input type="password" className="input" placeholder='Εισάγεται τον παλιό κωδικό'
                                   onChange={(e) => setNewPwdInput(e.target.value)}/>
                        </div>
                        <div className="pwd-input">
                            <span className="pwd-input__label">
                                Επανάληψη νέου κωδικού
                            </span>
                            <input type="password" className="input" placeholder='Εισάγεται τον παλιό κωδικό'
                                   onChange={(e) => setNewPwdRepeatInput(e.target.value)}/>
                        </div>
                        <div className={"pwd-error " + (errorMsg != '' ? '' : 'hide')}>
                            <span>
                                {errorMsg}
                            </span>
                        </div>
                        <div className="pwd-save">
                            <button className="cta" onClick={() => changePasswordSubmit()}>
                                Αποθήκευση
                            </button>
                        </div>
                        <div className="close" onClick={() => setShowResetPwdModal(false)}>
                            <BiX size={'35px'} color={'#cccccc'} style={iconStyle('#f2f2f2')}/>
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-between">
                    <div className="account-info col-12 col-lg-6 col-xl-6 col-xxl-6">
                        <label className="account-info__label">
                            First Name
                        </label>
                        <div className="account-info__detail d-flex justify-content-between align-items-center">
                            <p className="mb-0">{profile.first_name}</p>
                            <BiSolidPencil />
                        </div>
                    </div>
                    <div className="account-info col-12 col-lg-6 col-xl-6 col-xxl-6">
                        <label className="account-info__label">
                            Last Name
                        </label>
                        <div className="account-info__detail d-flex justify-content-between align-items-center">
                            <p className="mb-0">{profile.last_name}</p>
                            <BiSolidPencil />
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-between">
                    <div className="account-info col-12 col-lg-6 col-xl-6 col-xxl-6">
                        <label className="account-info__label">
                            Email
                        </label>
                        <div className="account-info__detail d-flex justify-content-between align-items-center">
                            <p className="mb-0">{profile.email}</p>
                            <BiSolidPencil />
                        </div>
                    </div>
                    <div className="account-info col-12 col-lg-6 col-xl-6 col-xxl-6">
                        <label className="account-info__label">
                            Birthday
                        </label>
                        <div className="account-info__detail d-flex justify-content-between align-items-center">
                            <p className="mb-0">{profile.birthday}</p>
                            <BiSolidPencil />
                        </div>
                    </div>
                </div>
                <div className="account-option d-flex col-12 justify-content-center">
                    <button className="button" onClick={() => setShowResetPwdModal(true)}>
                        Change Password
                    </button>
                </div>
            </div>
        </>
    )
}

export default Account;