import React, {useState} from 'react';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import "./PhoneNumber.scss";

type Props = {
    phone: string,
    setPhone: Function
}

function PhoneNumber ({phone, setPhone}: Props) {

    return (
        <>
            <PhoneInput
                inputClass="phonenumber input"
                country={"eg"}
                enableSearch={true}
                value={phone}
                onChange={(phone) => setPhone(phone)}
            />
        </>
    )
}

export default PhoneNumber;