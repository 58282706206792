import React from 'react';
import './footer.scss';
// @ts-ignore
import { Link } from 'react-router-dom';
import {BsFacebook, BsTwitter} from 'react-icons/bs';
import {useSelector} from "react-redux";
import {MdEmail} from "react-icons/md";
import {AiFillInstagram} from "react-icons/ai";

function Footer () {

    const themeMode = useSelector((state: any) => state.themeMode.value);

    return (
        <>
            <div className="footer container-fluid h-100 text-nowrap">
                <div className="row footer-row-1 d-flex justify-content-between align-content-center">
                    <div className="r1-c1 d-flex justify-content-start col-12 col-lg-4">
                        {
                            themeMode && <img src="/resources/logo.png" alt={'logo'}/>
                        }
                        {
                            !themeMode && <img src="/resources/logo-dark.png" alt={'logo'}/>
                        }


                        <h2 className="align-self-center"><strong>ENG</strong>Link</h2>
                    </div>
                    <div className="r1-c2 d-flex column-gap-5 row-gap-2 flex-wrap justify-content-lg-end justify-content-center align-items-center col-12 col-lg-8">
                        <Link className="navLink" to="/">Home</Link>
                        <Link className="navLink" to="/about">About</Link>
                        <Link className="navLink" to="/contact-us">Contact Us</Link>
                        <Link className="navLink" to="/help-center">Help</Link>
                        <Link className="navLink" to="/terms-and-conditions">Terms & Conditions</Link>
                    </div>
                </div>
                <div className="row footer-row-2 d-flex justify-content-between">
                    <div className="r2-c1 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex flex-column justify-content-start">
                        <h4 className="text-start text-wrap">Linking Passions, Bridging Languages</h4>
                        <div className="icons-wrapper d-flex justify-content-start">
                            <BsFacebook className="icon-element align-self-start" size={60} style={{fontSize: "white" }} />
                            <AiFillInstagram className="icon-element align-self-start" size={64} style={{fontSize: "white" }} />
                            <BsTwitter className="icon-element align-self-start" size={60} style={{fontSize: "white" }} />
                            <MdEmail className="icon-element align-self-start" size={60} style={{fontSize: "white" }} />
                        </div>

                    </div>
                    <div className="r2-c2 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex justify-content-end align-items-center">
                        <p className="">© 2023 <strong>ENG</strong>Link</p>
                    </div>

                </div>

            </div>
        </>
    )
}

export default Footer;