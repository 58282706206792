import React, {useState} from 'react';
import './ContactUs.scss';
import {Link} from 'react-router-dom';
import {calls} from "../../config/db_config";
import {call} from "../../utils/generalUtils";
import {formatDate} from "../../utils/dateUtils";


function ContactUs() {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    // Handle form input changes
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const {name, value} = event.target;
        setFormData({...formData, [name]: value});
    };

    // Handle form submission
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        console.log(formData); // For demonstration, print the form data to the console

        call(calls.sendContactForm, formData, 'POST', false)
            .then((data) => {
                console.log(data)
            })
            .catch((error) => {
                console.log(error)
            })
    };

    return (
        <>
            <div className="contact container-fluid d-flex flex-column align-items-center">
                <h1 className="contact-title text-center mb-3">
                    Get in Touch with Us
                </h1>
                <h4 className="contact-subtitle text-center mb-5">
                    Your satisfaction is our priority
                </h4>
                <div
                    className="contact-form-wrapper d-flex justify-content-center col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4 col-xxl-4">
                    <form className="d-flex flex-column w-100" onSubmit={handleSubmit}>
                        <div className="contact-inputwrapper d-flex flex-column justify-content-between mb-4">
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                                placeholder="type your name..."
                                aria-label="name of user"
                                aria-required="true"
                                className="input mt-1"
                            />
                        </div>
                        <div className="contact-inputwrapper d-flex flex-column justify-content-between mb-4">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                                placeholder="example@hotmail.com"
                                aria-label="email of user"
                                aria-required="true"
                                className="input mt-1"
                            />
                        </div>
                        <div className="contact-inputwrapper d-flex flex-column justify-content-between mb-5">
                            <label htmlFor="message">Message</label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleInputChange}
                                rows={4}
                                required
                                placeholder="Write a message..."
                                aria-label="message"
                                aria-required="true"
                                className="input p-2 mt-1"
                            />
                        </div>
                        <button type="submit">Submit</button>
                    </form>
                </div>
                <div className="contact-to-help-center d-flex flex-column justify-content-center align-items-center">
                    <h2>
                        Do you need help?
                    </h2>
                    <button className="w-75 mt-3" type="submit">
                        <Link to="/help-center">
                            Help Center
                        </Link>
                    </button>

                </div>
            </div>
        </>
    )
}

export default ContactUs;