import {updateProfile, getAuth} from "firebase/auth";
import {getStorage, ref as stRef, uploadBytes, getDownloadURL} from "firebase/storage";
import {getDatabase, update, ref} from "firebase/database";

export default async function updateProfilePicture(file: Blob | File) {
    const storage = getStorage();
    const storageRef = stRef(storage, 'users/' + getUniqueFilename());

    // 'file' comes from the Blob or File API
    const snapshot = await uploadBytes(storageRef, file)

    if (snapshot) {
        const downloadURL = await getDownloadURL(storageRef);

        const auth = getAuth();
        if (auth.currentUser) {
            await updateProfile(auth.currentUser, {
                photoURL: downloadURL
            })

            // write to db
            const db = getDatabase();
            const userId = auth.currentUser.uid;
            await update(ref(db, 'users/' + userId), {
                profile_picture: downloadURL
            })
        }
        return downloadURL;
    } else {
        return null;
    }
}

const getUniqueFilename = () => {
    // Generate a timestamp (Unix timestamp in milliseconds)
    const timestamp = new Date().getTime();

    // Generate a random string
    const randomString = Math.random().toString(36).substring(2, 10); // Adjust the length as needed

    // Combine the timestamp and random string to create a unique file name
    return `${timestamp}_${randomString}`;
}