import {useContext, useEffect} from "react";
import {ContextManager} from "../../../app/socket";

export default function useGetLectures(numberToShow: number, page: number, onLessonsListener: (...args: any[]) => void) {
    let context = useContext(ContextManager);

    useEffect(() => {
        if (context && context.socket) {
            context.socket.emit('getLessons', numberToShow, page);
            context.socket.on('onLessons', onLessonsListener);
        }

        return () => {
            if (context && context.socket) {
                context.socket.off('onLessons', onLessonsListener);
            }
        }
    }, []);

    return (newNumberToShow: number, newPage: number) => {
        if (context && context.socket)
            context.socket.emit('getLessons', newNumberToShow, newPage);
    }

}