import './not-found.scss';
import React from "react";

function NotFound() {
    return (
        <div className="container-fluid not-found">
            <img className="img w-50" src="/resources/404.png" alt="404-image"/>
            
            <h1 className="color-primary fst-italic">PAGE NOT FOUND</h1>

            <a href="/" className="button">
                GO BACK
            </a>
        </div>
    );
}

export default NotFound;
