import React, { useState } from 'react';
import './FAQuestions.scss';
import { BsFillChatFill } from 'react-icons/bs';

function FAQuestions() {
    const [activeQuestion, setActiveQuestion] = useState(null); // State to store the active question

    const handleIconClick = (question: any) => {
        if (activeQuestion === question) {
            // If the clicked question is already active, close it
            setActiveQuestion(null);
        } else {
            // Otherwise, set the clicked question as active
            setActiveQuestion(question);
        }
    };

    const getQuestionIcon = (question: any) => {
        return activeQuestion === question ? '-' : '+';
    };

    return (
        <>
            <div className="questions container-fluid d-flex flex-column col-12">
                <div
                    className="questions-wrapper d-flex justify-content-between"
                    onClick={() => handleIconClick('one')}
                >
                    <p className="questions-one mb-0 align-self-center">First frequently asked question.</p>
                    <span className="questions-chaticon align-self-center">
                        {getQuestionIcon('one')}
                    </span>
                </div>
                {activeQuestion === 'one' && (
                    <div className="answer-wrapper">
                        <div className="answer-content">
                            <p>
                                This is the answer to the first question.
                                Frenkie De Jong is the best transfer of the last 10 years probably,
                                for F.C. Barcelona. A fantastic player, a great professional and an
                                example of hard work, dedication, passion and loyalty.
                                Teo cry more
                            </p>
                        </div>
                    </div>
                )}
                <div
                    className="questions-wrapper d-flex justify-content-between"
                    onClick={() => handleIconClick('two')}
                >
                    <p className="questions-one mb-0 align-self-center">First frequently asked question.</p>
                    <span className="questions-chaticon align-self-center">
                        {getQuestionIcon('two')}
                    </span>
                </div>
                {activeQuestion === 'two' && (
                    <div className="answer-wrapper">
                        <div className="answer-content">
                            <p>
                                This is the answer to the second question.
                                Frenkie De Jong is the best transfer of the last 10 years probably,
                                for F.C. Barcelona. A fantastic player, a great professional and an
                                example of hard work, dedication, passion and loyalty.
                                Teo cry more
                            </p>
                        </div>
                    </div>
                )}
                <div
                    className="questions-wrapper d-flex justify-content-between"
                    onClick={() => handleIconClick('three')}
                >
                    <p className="questions-one mb-0 align-self-center">First frequently asked question.</p>
                    <span className="questions-chaticon align-self-center">
                        {getQuestionIcon('three')}
                    </span>
                </div>
                {activeQuestion === 'three' && (
                    <div className="answer-wrapper">
                        <div className="answer-content">
                            <p>
                                This is the answer to the third question.
                                Frenkie De Jong is the best transfer of the last 10 years probably,
                                for F.C. Barcelona. A fantastic player, a great professional and an
                                example of hard work, dedication, passion and loyalty.
                                Teo cry more
                            </p>
                        </div>
                    </div>
                )}
                <div
                    className="questions-wrapper d-flex justify-content-between"
                    onClick={() => handleIconClick('four')}
                >
                    <p className="questions-one mb-0 align-self-center">First frequently asked question.</p>
                    <span className="questions-chaticon align-self-center">
                        {getQuestionIcon('four')}
                    </span>
                </div>
                {activeQuestion === 'four' && (
                    <div className="answer-wrapper">
                        <div className="answer-content">
                            <p>
                                This is the answer to the fourth question.
                                Frenkie De Jong is the best transfer of the last 10 years probably,
                                for F.C. Barcelona. A fantastic player, a great professional and an
                                example of hard work, dedication, passion and loyalty.
                                Teo cry more
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default FAQuestions;