import Hero from "../../components/Hero/Hero";
import Partners from "../../components/Partners/Partners";
import FourSquares from "../../components/FourSquares/FourSquares";
import Testimonials from "../../components/Testimonials/Testimonials";
import React from "react";
import HeroFeatures from "../../components/HeroFeatures/HeroFeatures";
import PricePlans from "../../components/PricePlans/PricePlans";
import FAQuestions from "../../components/FAQuestions/FAQuestions";
import ContactUs from "../../components/ContactUs/ContactUs";
import ReferralSystem from "../../components/ReferralSystem/ReferralSystem";

function Homepage() {

    return (
        <div className="container-fluid d-flex flex-column h-100">
            <div className="container mb-15">
                <Hero/>
                <HeroFeatures/>
                <FourSquares/>
                <Partners/>
                <Testimonials/>
                <PricePlans />
                <FAQuestions />
                <ReferralSystem />
            </div>
        </div>
    );
}

export default Homepage;
