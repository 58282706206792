import React, {useEffect} from 'react';
import './LatestLecturesPage.scss';
import {useTranslation} from "react-i18next";
import LatestLectures from "../../components/LatestLectures/LatestLectures";

const LatestLecturesPage = () => {
    const {t} = useTranslation();

    useEffect(() => {
    }, []);

    return (
        <div className={'container-app notification-page'}>

            <h1 className='mb-5'>{t("latestLecturePage.label")}</h1>

            <LatestLectures numberToShow={5} showPagination={true}/>
        </div>
    );
};

export default LatestLecturesPage;
