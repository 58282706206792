import React, {useEffect, useState} from 'react';
import {Outlet} from 'react-router';
import {ContextProvider} from "../../app/socket";
import {calls} from "../../config/db_config";
import {setProfileData} from "../../reducers/navbarSlice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import {call} from "../../utils/generalUtils";
import NavbarApp from "../../components/Navbar/App/NavbarApp";
import dbCore from "../../controller/core";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import User from "../../models/user";

type Response = {
    data: any,
    status: number
}

const Authorized = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const studentExcludePaths = ['/schedule'];
    const professorExcludePaths = ['/tutor'];

    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        try {
            const auth = getAuth();
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    dbCore.getUserData().then((data: User) => {
                        if (data) {
                            let profileData = data;
                            dispatch(setProfileData(profileData));

                            const pathname = window.location.pathname;

                            if (profileData.user_type === 'student') {
                                if (studentExcludePaths.includes(pathname)) {
                                    navigate("/");
                                    return;
                                }
                            } else if (profileData.user_type === 'professor') {
                                if (professorExcludePaths.includes(pathname)) {
                                    navigate("/");
                                    return;
                                }
                            }

                            setIsReady(true);
                        } else {
                            navigate("/login");
                        }
                    });
                }
            });
            // dbCore.login().then((data: Response) => {
            //     if (data.status === 200) {
            //         let profileData = data.data;
            //         dispatch(setProfileData(profileData));
            //
            //         const pathname = window.location.pathname;
            //
            //         if (profileData.user_type === 'student') {
            //             if (studentExcludePaths.includes(pathname)) {
            //                 navigate("/");
            //                 return;
            //             }
            //         } else if (profileData.user_type === 'professor') {
            //             if (professorExcludePaths.includes(pathname)) {
            //                 navigate("/");
            //                 return;
            //             }
            //         }
            //
            //         setIsReady(true);
            //     } else {
            //         navigate("/login");
            //     }
            // })
        } catch (e) {
            navigate("/login");
        }
    });

    return (
        <>
            <ContextProvider>
                {
                    isReady && (
                        <div className="app-wrapper">
                            <NavbarApp/>
                            <Outlet/>
                        </div>
                    )
                }
            </ContextProvider>
        </>
    );
};

export default Authorized