import React, {useContext, useEffect, useRef, useState} from 'react';
import {ContextManager} from "../../app/socket";
import VideoInfo from "../VideoInfo/VideoInfo";
import './VideoPlayer.scss';
import '../VideoToolbar/VideoToolbar';
import VideoToolbar from "../VideoToolbar/VideoToolbar";
import Peer from "simple-peer";
import {useDispatch, useSelector} from "react-redux";
import {HiMiniVideoCamera} from "react-icons/hi2";
import {calls} from "../../config/db_config";
import {tokenUtils} from "../../utils/token-utils";
import axios from "axios";
import {hideProgressBar, showProgressBar} from "../../reducers/progressBarSlice";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const VideoPlayer = () => {
    const context = useContext(ContextManager);

    const dispatch = useDispatch();

    const meeting = useSelector((state: any) => state.meeting.value);
    const profile = useSelector((state: any) => state.profile.value);

    const [isReadyForCall, setIsReadyForCall] = useState(false);
    // const [callAccepted, setCallAccepted] = useState(false);
    // const [callEnded, setCallEnded] = useState(false);

    const [stream, setStream] = useState<MediaStream>();
    const [isTutor, setIsTutor] = useState(false);

    const myVideo = useRef();
    const userVideo = useRef();
    const connectionRef = useRef();

    const [isRecording, setIsRecording] = useState(false);
    const mediaRecorderRef = useRef(null);
    const recordedChunksRef = useRef([]);
    const [recordedAudioUrl, setRecordedAudioUrl] = useState<string | null>(null);

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    useEffect(() => {
        if (meeting && profile) {
            const isTutor = meeting.tutor_id?._id === profile?._id;
            setIsTutor(isTutor);
        }
    }, []);

    useEffect(() => {
        if (stream) {
            joinMeeting();
        }

        if (context.socket) {
            context.socket.on('bothJoinedMeeting', onBothJoinedListener);
        }

        return () => {
            if (context.socket) {
                context.socket.off('bothJoinedMeeting', onBothJoinedListener);
            }
        }
    }, [stream]);

    useEffect(() => {
        if (stream && isRecording) {
            // Create a new MediaRecorder when recording starts
            const mediaRecorder = new MediaRecorder(stream);

            mediaRecorder.ondataavailable = event => {
                if (event.data.size > 0) {
                    // @ts-ignore
                    recordedChunksRef.current.push(event.data);
                }
            };

            mediaRecorder.onstop = () => {
                const blob = new Blob(recordedChunksRef.current, {type: 'audio/webm'});
                const url = URL.createObjectURL(blob);
                setRecordedAudioUrl(url); // Store the recorded audio URL in state

                const formData = new FormData();
                formData.append('audio', blob, 'record_' + meeting._id + '.webm');
                formData.append('meeting_id', meeting._id);

                const config = {
                    headers: {
                        Authorization: tokenUtils.getBearerToken(),
                        ContentType: 'multipart/form-data'
                    }
                };

                dispatch(showProgressBar())

                try {
                    axios.post(
                        calls.saveMeetingAudio,
                        formData,
                        config
                    ).then((result) => {
                        console.log(result);
                    }).finally(() => {
                        dispatch(hideProgressBar())
                    })

                } catch (ex) {
                    console.log(ex);
                    saveRecordedAudioToLocalStorage(url);
                }
            };

            // @ts-ignore
            mediaRecorderRef.current = mediaRecorder;

            // Start recording
            mediaRecorder.start();
        } else if (mediaRecorderRef.current && !isRecording) {
            // Stop recording
            // @ts-ignore
            mediaRecorderRef.current.stop();
        }

        return () => {
            // @ts-ignore
            if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
                // @ts-ignore
                mediaRecorderRef.current.stop();
            }
        };
    }, [stream, isRecording]);

    const toggleRecording = () => {
        setIsRecording(prevState => !prevState);
    };

    const saveRecordedAudioToLocalStorage = (url: string) => {
        localStorage.setItem('recordedAudio', url);
    };

    const onBothJoinedListener = (otherPersonSocketId: string) => {
        if (context.socket) {
            // send signal data
            if (isTutor) {
                callUser(otherPersonSocketId);
            } else {
                context.socket.on('callUser', (signal) => {
                    answerCall(signal, otherPersonSocketId);
                });
            }
        }
    }

    const triggerCamera = () => {
        setIsReadyForCall(true);
        toggleRecording();

        if (navigator && navigator.mediaDevices) {
            navigator.mediaDevices.getUserMedia({video: true, audio: true})
                .then((currentStream) => {
                    if (currentStream) {
                        setStream(currentStream);

                        if (myVideo.current) {
                            // @ts-ignore
                            myVideo.current.srcObject = currentStream;
                        }
                    }
                });
        }
    }

    const answerCall = (signal: any, otherPersonSocketId: string) => {
        // setCallAccepted(true);
        const peer = new Peer({initiator: false, trickle: false, stream});

        peer.on('signal', (data) => {
            if (signal && context.socket)
                context.socket.emit('answerCall', otherPersonSocketId, data);
        });

        peer.on('stream', (currentStream) => {
            if (userVideo && userVideo.current) {
                // @ts-ignore
                userVideo.current.srcObject = currentStream;

                SpeechRecognition.startListening({
                    continuous: true
                });
            }
        });

        peer.on('error', (error) => {
            console.log(error)
        });

        peer.on('close', () => {
            if (context.socket) {
                context.socket.off('callUser');
                context.socket.off('callAccepted');
                peer.destroy();
                // @ts-ignore
                connectionRef.current = null;
                // @ts-ignore
                userVideo.current = null;
            }
        });

        if (signal) {
            peer.signal(signal);
        }

        // @ts-ignore
        connectionRef.current = peer;
    };

    const callUser = (otherPersonSocketId: string) => {
        // the tutor will be the initiator
        const peer = new Peer({initiator: true, trickle: false, stream});

        peer.on('signal', (data) => {
            if (context.socket && meeting) {
                context.socket.emit('callUser', otherPersonSocketId, data);
            }
        });

        peer.on('stream', (currentStream) => {
            if (userVideo && userVideo.current) {
                // @ts-ignore
                userVideo.current.srcObject = currentStream;

                SpeechRecognition.startListening({
                    continuous: true
                });
            }
        });

        peer.on('error', (error) => {
            console.log(error)
        });

        peer.on('close', () => {
            if (context.socket) {
                context.socket.off('callUser');
                context.socket.off('callAccepted');
                peer.destroy();
                // @ts-ignore
                connectionRef.current = null;
                if (userVideo.current)
                    // @ts-ignore
                    userVideo.current.srcObject = null;
            }
        });

        // to receive signal
        if (context.socket) {
            context.socket.on('callAccepted', (signal) => {
                try {
                    if (signal !== null) {
                        peer.signal(signal);
                    } else {
                        // Handle the case where the signal is null (other user disconnected)
                        // For example, you could display a message or take appropriate action
                        // peer.destroy();
                    }
                } catch (error) {
                    console.error("Error during peer signal:", error);
                    // Handle the error as needed
                } finally {
                    // Always remember to remove the event listener
                    if (context.socket)
                        context.socket.off('callAccepted');
                }
            });
        }

        // @ts-ignore
        connectionRef.current = peer;
    };

    const microphoneOnClick = () => {
        // Toggle microphone on/off
        if (stream) {
            const audioTracks = stream.getAudioTracks();
            audioTracks.forEach(track => {
                track.enabled = !track.enabled;
            });
        }
    };

    const soundOnClick = () => {
        // Toggle sound on/off (nullify sound)
        if (stream) {
            const audioTracks = stream.getAudioTracks();
            audioTracks.forEach(track => {
                track.enabled = !track.enabled;
            });

            if (!audioTracks.some(track => track.enabled)) {
                // If all audio tracks are disabled, set the volume to 0 for the audio elements
                if (myVideo.current) {
                    // @ts-ignore
                    myVideo.current.volume = 0;
                }
                if (userVideo.current) {
                    // @ts-ignore
                    userVideo.current.volume = 0;
                }
            } else {
                // Restore the volume if at least one audio track is enabled
                if (myVideo.current) {
                    // @ts-ignore
                    myVideo.current.volume = 1;
                }
                if (userVideo.current) {
                    // @ts-ignore
                    userVideo.current.volume = 1;
                }
            }
        }
    };

    const endMeetingOnClick = () => {
        setIsReadyForCall(false);

        if (connectionRef.current) {
            // @ts-ignore
            connectionRef.current.destroy();
            // @ts-ignore
            connectionRef.current = null;
        }

        if (context.socket) {
            context.socket.off('callUser');
            context.socket.off('callAccepted');
            leaveMeeting();
        }

        if (stream) {
            stream.getTracks().forEach(track => {
                track.stop();
            });
        }

        // Nullify video refs
        // @ts-ignore
        myVideo.current = null;
        // @ts-ignore
        userVideo.current = null;
    };


    const fullScreenOnClick = () => {
        // Toggle full screen mode
        const videoPlayer = document.querySelector('.video-player');
        if (videoPlayer) {
            if (videoPlayer.requestFullscreen) {
                videoPlayer.requestFullscreen();
                // @ts-ignore
            } else if (videoPlayer.mozRequestFullScreen) { // Firefox
                // @ts-ignore
                videoPlayer.mozRequestFullScreen();
                // @ts-ignore
            } else if (videoPlayer.webkitRequestFullscreen) { // Chrome, Safari and Opera
                // @ts-ignore
                videoPlayer.webkitRequestFullscreen();
                // @ts-ignore
            } else if (videoPlayer.msRequestFullscreen) { // IE/Edge
                // @ts-ignore
                videoPlayer.msRequestFullscreen();
            }
        }
    };

    const videoOnClick = () => {
        // Toggle video on/off (start/stop sharing video)
        if (stream) {
            const videoTracks = stream.getVideoTracks();
            videoTracks.forEach(track => {
                track.enabled = !track.enabled;
            });
        }
    };

    const joinMeeting = () => {
        if (context.socket) {
            context.socket.emit('joinMeeting', {meeting: meeting, userId: profile._id});
        }
    };

    const leaveMeeting = () => {
        if (context.socket) {
            context.socket.emit('leaveMeeting', meeting._id);
        }
    };

    return (meeting &&
        <div className={'video-player'}>


            <div className="mb-11">
                <VideoInfo user={isTutor ? meeting.student_id : meeting.tutor_id}/>
            </div>

            {
                !isReadyForCall && (
                    <div className='video-player__wrapper'>

                        <HiMiniVideoCamera size={55}/>

                        <h2 className='text-center'>
                            When you are ready you can join the meeting with Sao Lin
                            and start your lesson.
                        </h2>

                        <div className='mb-4 text-center'>
                            note: the meeting will be recorded and can be rewatched by you and your tutor
                        </div>

                        <div className={'button'} onClick={() => {
                            triggerCamera();
                        }}>
                            JOIN MEETING
                        </div>

                    </div>
                )
            }

            {
                isReadyForCall && (
                    <>
                        <div className='position-relative'>
                            {
                                (// @ts-ignore
                                    <video playsInline muted ref={myVideo} autoPlay
                                           className={'video-player__player video-player__player-self'}/>
                                )}
                            {(
                                // @ts-ignore
                                <video playsInline ref={userVideo} autoPlay
                                       className={'video-player__player video-player__player-external'}/>
                            )}
                        </div>

                        <VideoToolbar fullScreenOnClick={fullScreenOnClick} microphoneOnClick={microphoneOnClick}
                                      videoOnClick={videoOnClick} endOnClick={endMeetingOnClick}
                                      soundOnClick={soundOnClick}/>

                        <p>{transcript}</p>
                    </>
                )
            }


        </div>
    );
};

export default VideoPlayer;