import React from 'react';
import "./Logout.scss"
import {IoIosLogOut} from "react-icons/io";
import {setProfileData} from "../../../../reducers/navbarSlice";
import {removeToken} from "../../../../utils/token-to-cookie";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import dbCore from "../../../../controller/core";

type Props = {
    minimized: boolean;
};

const Logout = ({minimized = false}: Props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logout = () => {
        dispatch(setProfileData({}));
        removeToken();
        dbCore.logout();
        setTimeout(() => navigate("/login"), 300);
    };

    return <div className="logout mt-2 text-warning" onClick={logout}>
        {
            !minimized &&
            <span>Logout</span>
        }
        <IoIosLogOut size={26}/>
    </div>
}
export default Logout