import React, { useState } from 'react';
import './VideoInfo.scss';
import {useSelector} from "react-redux";
import User from "../../models/user";

type Props = {
    user: User
}

const VideoInfo = ({user}: Props) => {

    const meeting = useSelector((state: any) => state.meeting.value);

    return (
        <div className="video-info">
            <div className="video-info__wrapper">
                <img className='video-info__user-pic'
                     src={user.profile_picture ? user.profile_picture
                         : '/resources/student.png'}
                     alt={'profile picture'}/>

                <div>
                    <h1>Live Lesson <strong className='text-primary'>{user.first_name + ' ' + user.last_name}</strong></h1>
                    <div className="video-info__record">
                        <div className="video-info__record-icon"></div>
                        Recording
                    </div>
                </div>
            </div>

        </div>
    );
};

export default VideoInfo;