import React from "react";
import './PricePlans.scss';
import {BsCheck2, BsChevronLeft, BsChevronRight} from "react-icons/bs";
import {Navigation, Pagination, Scrollbar, A11y, EffectCoverflow} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

function PricePlans() {

    return (
        <>
            <div className="priceplans">
                <Swiper
                    // @ts-ignore
                    modules={[Navigation, Pagination, Scrollbar, A11y, EffectCoverflow]}
                    slidesPerView={"auto"}
                    navigation
                    grabCursor={true}
                    initialSlide={1}
                    effect={'coverflow'}
                    centeredSlides={true}
                    coverflowEffect={{
                        rotate: 0,
                        stretch: 0,
                        depth: 100,
                        modifier: 2.5,
                    }}
                    pagination={{clickable: true}}
                >
                    <SwiperSlide className="priceplans-carditem d-flex flex-column justify-content-between">
                        <div className="priceplans-title d-flex flex-column align-items-center p-3">
                            <h3 className="priceplans-title-h3-1 mb-0">$ 19.34</h3>
                            <h6 className="mb-0 mt-2">STUDENTS</h6>
                        </div>
                        <div className="priceplans-features d-flex flex-column flex-grow-1">
                            <h5>Top features</h5>
                            <div className="d-flex">
                                <BsCheck2 className="priceplans-bs-icon align-self-center"/>
                                <p className="mb-0 "> Single user </p>
                            </div>
                            <div className="d-flex">
                                <BsCheck2 className="priceplans-bs-icon align-self-center"/>
                                <p className="mb-0 "> Access to all Creative Cloud apps </p>
                            </div>
                            <div className="d-flex">
                                <BsCheck2 className="priceplans-bs-icon align-self-center"/>
                                <p className="mb-0 "> 10 free Adobe stock images each month </p>
                            </div>
                        </div>
                        <div className="priceplans-button d-flex flex-column justify-content-center text-center">
                            <button className="button">Upgrade</button>
                            <p className="mb-0 pt-3"> Get your 30 days free trial </p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className="priceplans-carditem d-flex flex-column justify-content-between">
                        <div className="priceplans-title d-flex flex-column align-items-center p-3">
                            <h3 className="priceplans-title-h3-2 mb-0">$ 59.49</h3>
                            <h6 className="mb-0 mt-2">INDIVIDUALS</h6>
                            <p className="mb-0 mt-2">Single User</p>
                        </div>
                        <div className="priceplans-features d-flex flex-column flex-grow-1">
                            <h5>Top features</h5>
                            <div className="d-flex">
                                <BsCheck2 className="priceplans-bs-icon align-self-center"/>
                                <p className="mb-0 "> Single user </p>
                            </div>
                            <div className="d-flex">
                                <BsCheck2 className="priceplans-bs-icon align-self-center"/>
                                <p className="mb-0 "> Access to all Creative Cloud apps </p>
                            </div>
                            <div className="d-flex">
                                <BsCheck2 className="priceplans-bs-icon align-self-center"/>
                                <p className="mb-0 "> 10 free Adobe stock images ech month </p>
                            </div>
                            <div className="d-flex">
                                <BsCheck2 className="priceplans-bs-icon align-self-center"/>
                                <p className="mb-0 "> 1 free Adobe stock video each month </p>
                            </div>
                        </div>
                        <div className="priceplans-button d-flex flex-column justify-content-center text-center">
                            <button className="button">Upgrade</button>
                            <p className="mb-0 pt-3"> Get your 30 days free trial </p>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide className="priceplans-carditem d-flex flex-column justify-content-between">
                        <div className="priceplans-title d-flex flex-column align-items-center p-3">
                            <h3 className="priceplans-title-h3-3 mb-0">$ 169.99</h3>
                            <h6 className="mb-0 mt-2">BUSINESS</h6>
                            <p className="mb-0 mt-2">Multiple Users</p>
                        </div>
                        <div className="priceplans-features d-flex flex-column flex-grow-1">
                            <h5>Top features</h5>
                            <div className="d-flex">
                                <BsCheck2 className="priceplans-bs-icon align-self-center"/>
                                <p className="mb-0 "> Single user </p>
                            </div>
                            <div className="d-flex">
                                <BsCheck2 className="priceplans-bs-icon align-self-center"/>
                                <p className="mb-0 "> Access to all Creative Cloud apps </p>
                            </div>
                            <div className="d-flex">
                                <BsCheck2 className="priceplans-bs-icon align-self-center"/>
                                <p className="mb-0 "> 10 free Adobe stock images each month </p>
                            </div>
                            <div className="d-flex">
                                <BsCheck2 className="priceplans-bs-icon align-self-center"/>
                                <p className="mb-0 "> 5 free Adobe stock videos each month </p>
                            </div>
                            <div className="d-flex">
                                <BsCheck2 className="priceplans-bs-icon align-self-center"/>
                                <p className="mb-0 "> Co-editing features </p>
                            </div>
                        </div>
                        <div className="priceplans-button d-flex flex-column justify-content-center text-center">
                            <button className="button">Upgrade</button>
                            <p className="mb-0 pt-3"> Get your 30 days free trial </p>
                        </div>
                    </SwiperSlide>
                </Swiper>

            </div>

        </>
    )
}

export default PricePlans;