import React from 'react';
import User from "../../../models/user";
import {MouseEventHandler} from "react";

type Props = {
    profile: User,
    navUserClick: MouseEventHandler
}

const NavProfilePic = ({profile, navUserClick}: Props) =>
    <div className="navbar-container__user-icon" onClick={navUserClick}>
        <img className={'ignore-event'}
             src={profile.profile_picture !== '' ? profile.profile_picture : '/resources/student.png'}
             alt={'profile picture'}/>
    </div>
export default NavProfilePic