import React, {ChangeEvent, useRef} from "react";
import {useState} from 'react';
import './TutorRegister.scss';
import '../../scss/main.scss';
import {Link} from "react-router-dom";
import $ from 'jquery';
import {calls} from "../../config/db_config";
import {validateEmail} from "../../utils/email-validator";
import CountrySelector from "../../components/CountrySelector/CountrySelector";
import PhoneNumber from "../../components/PhoneNumber/PhoneNumber";
import Webcam from "react-webcam";
import {hideProgressBar, showProgressBar} from "../../reducers/progressBarSlice";
import {useDispatch} from "react-redux";
import axios from "axios";
import TutorNotActivated from "../../models/tutor_not_activated";
import dbCore from "../../controller/core";
import AuthResponse from "../../models/authResponse";

const TutorRegister = () => {

    // const clientId = '30236837544-ssa7tnbsrev0q51rs61ci69110999mos.apps.googleusercontent.com';

    const dispatch = useDispatch();

    const [selectedCountry, setSelectedCountry] = useState<string>();

    const [step, setStep] = useState(1);

    const [infoData, setInfoData] = useState<null | TutorNotActivated>(null);

    const [phone, setPhone] = useState("");

    // useEffect(() => {
    //     // Add reCaptcha
    //     const script = document.createElement("script")
    //     script.src = "https://www.google.com/recaptcha/api.js?render=6LevrSElAAAAAEFKDKkBD4pyCqLFOoJMDMkILRpr"
    //     // script.addEventListener("load", handleLoaded)
    //     document.body.appendChild(script)
    // });

    // LOGIN ERROR MESSAGES HANDLING
    const errorMassages = {
        emptyFields: 'Please fill all the fields',
        invalidEmail: 'Invalid email',
        passwordsMismatch: 'Passwords do not match',
        unexpectedError: 'Unexpected Error',
        genericError: 'Something went wrong',
        emailTaken: 'The email is already in use'
    }

    const [errorMessage, setErrorMessage] = useState({
        visible: false,
        message: ''
    });

    const [registerErrorMessage, setRegisterErrorMessage] = useState({
        visible: false,
        message: ''
    });

    const firstStepValidate = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        let $inputs = $('#register-form .form-fields :input').not('button');
        let values: any = {};
        let isFieldEmpty = false;
        $inputs.each(function () {
            const value = $(this).val();
            // @ts-ignore
            values[this.name] = value;

            if (value === '')
                isFieldEmpty = true;
        });

        const isValidEmail = validateEmail(values.email);

        if (isFieldEmpty || !selectedCountry || selectedCountry == '' || !phone || phone == '') {
            setRegisterErrorMessage({visible: true, message: errorMassages.emptyFields});
        } else if (isValidEmail == null) {
            setRegisterErrorMessage({visible: true, message: errorMassages.invalidEmail});
        } else if (values.password !== values.repeat_password) {
            setRegisterErrorMessage({visible: true, message: errorMassages.passwordsMismatch});
        } else {
            // everything in the form is correct

            // TODO: consider a datepicker or initialize a default value
            const d = new Date();
            d.setTime(d.getTime() - (27 * 365 * 24 * 60 * 60 * 1000));
            // const birthday = new Date().toISOString().slice(0, 19).replace('T', ' ');

            const data: TutorNotActivated = {
                '_id': '',
                'email': values.email as string,
                'password': values.password,
                'first_name': values.first_name,
                'last_name': values.last_name,
                'birthday': d,
                'profile_picture': '',
                'user_type': 'professor',
                'country': selectedCountry,
                'phone': phone
            }

            setInfoData(data);
            setStep(2);
        }
    }

    const secondStepValidate = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (idPhoto)
            setStep(3);
    }

    const thirdStepValidate = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        register();
    }

    const register = () => {
        if (idPhotoBlob && photoDataBlob && infoData) {
            dispatch(showProgressBar())

            dbCore.registerTutor(idPhotoBlob, photoDataBlob, infoData)
                .then((result: AuthResponse) => {
                    console.log(result);
                    setStep(4);
                })
                .catch((error: any) => {
                    if (error.response && error.response.data && error.response.data.message)
                        setErrorMessage({visible: true, message: error.response.data.message});
                    else if (error.response.data.status != null) {
                        switch (error.response.data.status) {
                            case 403:
                                setRegisterErrorMessage({visible: true, message: errorMassages.genericError});
                                break;
                            case 410:
                                setRegisterErrorMessage({visible: true, message: errorMassages.emailTaken});
                                break;
                        }
                    }
                })
                .finally(() => {
                    dispatch(hideProgressBar());
                });
        }
    }

    const bgStyle = {
        backgroundImage: `url(${process.env.PUBLIC_URL + '/resources/login-bg.jpg'})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    };

    const webcamRef = useRef(null);
    const [photoData, setPhotoData] = useState(null);
    const [photoDataBlob, setPhotoDataBlob] = useState<Blob | null>(null);

    const [idPhoto, setIdPhoto] = useState<null | string>(null);
    const [idPhotoBlob, setIdPhotoBlob] = useState<null | Blob>(null);

    const capturePhoto = async (event: React.MouseEvent) => {
        event.preventDefault();
        if (webcamRef && webcamRef.current) {
            // @ts-ignore
            const imageSrc = webcamRef.current.getScreenshot();
            setPhotoData(imageSrc);

            const blob = await fetch(imageSrc).then((res) => res.blob());
            setPhotoDataBlob(blob);
        }
    };

    const onPhotoId = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
            setIdPhotoBlob(file);

            const reader = new FileReader();

            reader.onload = (e) => {
                if (e && e.target)
                    setIdPhoto(e.target.result as string); // Set the selected image to be displayed
            };

            reader.readAsDataURL(file);
        }
    };

    const step1 = () => {
        return <>
            <div className="d-flex flex-column">

                <div className="form-fields d-flex flex-column">
                    <div className="d-flex">
                        <div className="w-50">
                            <label className="register__left-form-label">Όνομα</label>
                            <input placeholder="π.χ. Γιάννης" type="text" name="first_name"
                                   className="input input-primary text-dark register__left-form-input mt-1 w-90"/>
                        </div>
                        <div className="w-50">
                            <label className="register__left-form-label">Επώνυμο</label>
                            <input placeholder="π.χ. Παπαδόπουλος" name="last_name" type="text"
                                   className="input input-primary text-dark register__left-form-input mt-1 w-100"/>
                        </div>
                    </div>

                    <label className="register__left-form-label mt-4">Email</label>
                    <input placeholder="π.χ. john@edupal.gr" name="email" type="text"
                           className="input input-primary text-dark register__left-form-input mt-1"/>

                    <label className="register__left-form-label mt-4">Κωδικός</label>
                    <div className="d-flex mb-4">
                        <div className="w-50">
                            <input placeholder="Πληκτρολογήστε τον κωδικό σας" name="password" type="password"
                                   className="input input-primary text-dark register__left-form-input mt-1 w-90"/>
                        </div>
                        <div className="w-50">
                            <input placeholder="Πληκτρολογήστε ξανά τον κωδικό σας" name="repeat_password"
                                   type="password"
                                   className="input input-primary text-dark register__left-form-input mt-1 w-100"/>
                        </div>
                    </div>
                </div>

                <label className="register__left-form-label mt-4">Country</label>
                <div className="mt-1">
                    <CountrySelector setSelectedCountry={setSelectedCountry}/>
                </div>
                <label className="register__left-form-label mt-4">Phone Number</label>
                <div className="mt-1">
                    <PhoneNumber phone={phone} setPhone={setPhone}/>
                </div>
            </div>

            <div className="d-flex flex-column">
                <div className={'alert alert-danger mt-3 ' + (registerErrorMessage.visible ? '' : 'd-none')}>
                    {registerErrorMessage.message}
                </div>

                <button
                    className={"button font-weight-bold text-center " + (registerErrorMessage.visible ? '' : 'mt-5')}
                    onClick={firstStepValidate}>
                    Next
                </button>
            </div>
        </>
    };

    const step2 = () => {
        return <>
            <div className="d-flex flex-column">
                <label className="h2 register__left-form-label mt-4 text-center">
                    <h2>Upload ID</h2>
                </label>

                <div className="mb-4 text-center">
                    It is mandatory to provide your ID card.
                    <Link className="link ms-1" to="/terms-and-conditions#tutor-registration">
                        More info
                    </Link>
                </div>

                <input placeholder="Upload ID" name="email" type="file" onChange={onPhotoId}
                       className="input input-primary text-dark register__left-form-input mt-1 mb-4"/>

                {
                    idPhoto && <img className="tutor-register__id mb-4" src={idPhoto} alt="Selected"/>
                }
            </div>

            <div className="d-flex flex-column">
                <button
                    className={"button font-weight-bold text-center " + (registerErrorMessage.visible ? '' : 'mt-3')}
                    onClick={secondStepValidate}>
                    Next
                </button>
            </div>
        </>
    }

    const step3 = () => {
        return <>
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    className="tutor-register__id mb-4"
                />

                <button onClick={capturePhoto}>Take a Photo</button>
                {photoData && (
                    <div className="mt-5">
                        <h2 className="mb-2">Preview:</h2>
                        <img className="tutor-register__id mb-4" src={photoData} alt="Captured"/>
                        <button className="m-auto" onClick={thirdStepValidate}>Register</button>
                    </div>
                )}
            </div>
        </>
    }

    const thankYouStep = () => {
        return <div className="d-flex flex-column align-items-center justify-content-center mt-15">
            <h1 className="mb-3">
                Congrats {infoData && infoData?.first_name}
            </h1>

            <h5 className="mb-15">
                We will inform you as soon as possible with results of your application.
            </h5>

            <Link className="button" to={'/login'}>
                RETURN TO LOGIN
            </Link>
        </div>
    }

    return (
        <div style={bgStyle} className="register tutor-register">
            <div className="ms-auto me-auto mt-10 mb-14">
                <h1 className="register-tutor__left-title text-center mb-4">Become a Tutor</h1>

                <div className="d-flex justify-content-center align-items-center gap-5 mb-8">
                    <div>
                        <div className={"tutor-register__step" + (step === 1 ? ' active' : '')}>1</div>
                        Info
                    </div>
                    <div>
                        <div className={"tutor-register__step" + (step === 2 ? ' active' : '')}>2</div>
                        ID
                    </div>
                    <div>
                        <div className={"tutor-register__step" + (step === 3 ? ' active' : '')}>3</div>
                        Picture
                    </div>
                </div>

                <form id="register-form" className="register__left-form">
                    {
                        step === 1 && step1()
                    }

                    {
                        step === 2 && step2()
                    }

                    {
                        step === 3 && step3()
                    }

                    {
                        step === 4 && thankYouStep()
                    }

                    <div>
                        {errorMessage && errorMessage.message}
                    </div>

                </form>

                {/*<label className="register__left-form-label mt-3">Έχετε ήδη λογαριασμό;<a*/}
                {/*    onClick={goToLogin} className="link link-primary m-2">Συνδεθείτε</a></label>*/}

            </div>

            <div
                className="g-recaptcha"
                data-sitekey="6LevrSElAAAAAEFKDKkBD4pyCqLFOoJMDMkILRpr"
                data-size="invisible"
            ></div>
        </div>
    );
};

export default TutorRegister;