import login from "./src/ServerLogin";
import register from "./src/ServerRegister";
import registerTutor from "./src/ServerRegisterTutor";
import useGetLectures from "./src/ServerLectures";
import DbInterface from "../dbInterface";

class DbServerCore implements DbInterface {

    constructor() {
    }

    login = login;
    register = register;
    registerTutor = registerTutor;

    useGetLectures = useGetLectures;

}

export default DbServerCore;