import React from "react";
import "./ContactUs.scss";
// @ts-ignore
import lines from "../../resources/geometrics/lines.png";
import ContactUsCmp from "../../components/ContactUs/ContactUs";

function ContactUs() {

    return (
        <div className="about-us container-fluid d-flex flex-column h-100">
            <div className="container mb-15 position-relative">

                <ContactUsCmp/>

            </div>
        </div>
    );
}

export default ContactUs;
