import React from 'react';
import './FourSquares.scss';
import {FaGlobe} from "react-icons/fa";
import {LiaCertificateSolid} from "react-icons/lia";
import {Tb24Hours, TbChartDots3} from "react-icons/tb";

function FourSquares() {

    return (
        <>
            <div className="four-squares">

                <div className="four-squares__leftside-wrapper">
                    <h6><span></span> Discover High-Quality Resources to Find the Optimal Tutor</h6>
                    <h1>If the best tutor doesn't exist,
                        then what are we here for?</h1>
                    <p>At LanguageLink, we are dedicated to going above and beyond our limits to find the ideal match
                        for every student.
                    </p>
                    <button className={'button'}>Learn More</button>
                </div>

                <div className="four-squares__rightside">
                    <div className="four-squares__rightside__row first">
                        <div className="four-squares__rightside__row__square">
                            <div className="four-squares__rightside__row__square__img first">
                                <LiaCertificateSolid size={30}/>
                            </div>
                            <div>
                                <h5>Certified Mentors</h5>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
                            </div>
                        </div>

                        <div className="four-squares__rightside__row__square">
                            <div className="four-squares__rightside__row__square__img second">
                                {/*<img src={LearningIcon} alt={"..."}/>*/}
                                <TbChartDots3 size={30}/>
                            </div>
                            <div>
                                <h5>Learning Methods</h5>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
                            </div>
                        </div>
                    </div>

                    <div className="four-squares__rightside__row second">
                        <div className="four-squares__rightside__row__square">
                            <div className="four-squares__rightside__row__square__img third">
                                <FaGlobe size={30}/>

                                {/*<img src={GlobeIcon} alt={"..."}/>*/}
                            </div>
                            <div>
                                <h5>Industrial Material</h5>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
                            </div>
                        </div>

                        <div className="four-squares__rightside__row__square">
                            <div className="four-squares__rightside__row__square__img fourth">
                                <Tb24Hours size={30}/>
                            </div>
                            <div>
                                <h5>24/7 Support</h5>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do</p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}


export default FourSquares;