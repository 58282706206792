import React from 'react';
import './LessonInfoTable.scss';

type Props = {
    details: {
        language: string,
        content: string,
        intro: string,
        corrections: string,
        remarks: string,
    }
}

const LessonInfoTable = ({details}: Props) => {

    return (
        <div className={'lesson-info'}>
            <table>
                <tbody>
                <tr>
                    <td>Lesson Language</td>
                    <td>{details.language}</td>
                </tr>

                <tr>
                    <td>Lesson Content</td>
                    <td>{details.content}</td>
                </tr>

                <tr>
                    <td>Self Introduction</td>
                    <td>{details.intro}</td>
                </tr>

                <tr>
                    <td>Corrections</td>
                    <td>{details.corrections}</td>
                </tr>

                <tr>
                    <td>Special Remarks</td>
                    <td>{details.remarks}</td>
                </tr>
                </tbody>

            </table>
        </div>
    );
};

export default LessonInfoTable;