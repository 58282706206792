const endpoint = 'http://localhost:3001/';

export enum DATABASE_ENUM {
    firebase = 'FIREBASE',
    mongo = 'MONGO'
}
export const DB_SELECT = DATABASE_ENUM.firebase.valueOf();

export const calls = {
    endpoint: endpoint,
    signIn: endpoint + 'sign-in',
    signUp: endpoint + 'sign-up',
    signUpTutor: endpoint + 'sign-up-tutor',
    changePassword: endpoint + 'change-password',
    getData: endpoint + 'get-data',
    getUserData: endpoint + 'get-user-data',
    updateProfile: endpoint + 'update-profile',
    updateProfilePicture: endpoint + 'update-profile-picture',
    getUsers: endpoint + 'get-users',
    insertLog: endpoint + 'insert-log',
    saveMeetingAudio: endpoint + 'save-meeting-audio',
    sendContactForm: endpoint + 'send-contact-form',

    // meeting
    getMeeting: endpoint + 'get-meeting',
};
