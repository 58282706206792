import React from 'react';

type Props = {
    profile: { user_type: 'student' | 'professor' };
    mobileActive: boolean;
    setMobileActive: React.Dispatch<React.SetStateAction<boolean>>;
    studentLinks: React.JSX.Element[];
    professorLinks: React.JSX.Element[];
    unauthorizedLinks: React.JSX.Element[];
};

const NavMobileLinks: React.FC<Props> = ({
                                             profile,
                                             mobileActive,
                                             setMobileActive,
                                             studentLinks,
                                             professorLinks,
                                             unauthorizedLinks,
                                         }) => (
    <ul className={'navbar-container__links-mobile' + (mobileActive ? ' active' : '')}>
        {profile.user_type === 'student' &&
            studentLinks.map((element, index) => (
                <li className={'navbar-container__links-mobile-item'} key={index} onClick={() => setMobileActive(false)}>
                    {element}
                </li>
            ))}
        {profile.user_type === 'professor' &&
            professorLinks.map((element, index) => (
                <li className={'navbar-container__links-mobile-item'} key={index} onClick={() => setMobileActive(false)}>
                    {element}
                </li>
            ))}
        {profile.user_type &&
            unauthorizedLinks.map((element, index) => (
                <li className={'navbar-container__links-mobile-item'} key={index} onClick={() => setMobileActive(false)}>
                    {element}
                </li>
            ))}
    </ul>
);

export default NavMobileLinks;
