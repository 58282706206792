import './Hero.scss';
import React from "react";
// @ts-ignore
import Laptop from "../../resources/laptop.png";
// @ts-ignore
import LaptopDark from "../../resources/laptop-dark.png";
// @ts-ignore
import Rumbus from "../../resources/geometrics/rumbus.png";
// @ts-ignore
import Lines from "../../resources/geometrics/lines.png";
// @ts-ignore
import Cross from "../../resources/geometrics/cross.png";

const Hero = () => {

    return (
        <div className="hero">
            <div className="hero__wrapper col-12 col-md-6">
                <h1 className="hero__title">
                    Linking Passions, <br/>Bridging Languages
                </h1>

                <h2 className="hero__subtitle">
                    Welcome to <strong>LanguageLink:</strong> Personalized Online Tutoring. Choose your dedicated
                    professor
                    and schedule sessions that suit your schedule. Unlock your potential!
                </h2>

                <button className="button">Start now!</button>
            </div>

            <div className={'d-flex justify-content-end col-12 col-md-6'}>
                <img className={'hero__laptop'} src="/resources/hero-img.png" alt=""/>
                {/*<img className={'hero__laptop-dark'} src="/resources/hero-img.png" alt=""/>*/}
                {/*<img className={'hero__laptop hero__laptop-dark'} src={LaptopDark} alt=""/>*/}
                {/*<img className={'hero__cross'} src={Cross} alt=""/>*/}
                {/*<img className={'hero__rumbus'} src={Rumbus} alt=""/>*/}
            </div>
        </div>
    );
};

export default Hero;
