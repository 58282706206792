import React, {useState, useContext, SetStateAction} from 'react';
import '../CalendarTable/CalendarTable.scss';
import '../TableRow/CalendarTableRow.scss';
import '../Confirmation/CalendarTableConfirmation.scss';
import './CalendarTableMonth.scss';
import {areDatesEqual, isSameDay} from "../../../utils/dateUtils";
import {ContextManager} from "../../../app/socket";
import Booked from "../../../models/booked";
import { motion } from 'framer-motion';

type Props = {
    date: Date;
    booked: Array<Booked>;
    availableTimes: Array<Date>;
    tutorId: string | null;
    daysToAdd: number;
    setShowSidebar: Function,
    setSelectedDate: Function
};

const CalendarTableMonth = ({date, booked, availableTimes, tutorId, daysToAdd, setShowSidebar, setSelectedDate}: Props) => {
    const socketContext = useContext(ContextManager);

    const [clickedSlot, setClickedSlot] = useState<Date>(new Date());

    // Array to represent 48 time slots (24 hours before + 24 hours after) with 30-minute intervals
    const timeSlots: Date[] = [];

    const currentDate = new Date(date); // Get the current date and time
    const currentMonth = currentDate.getMonth(); // Get the current month (0-11)
    const currentYear = currentDate.getFullYear(); // Get the current year
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate(); // Get the number of days in the current month

    for (let day = 1; day <= daysInMonth; day++) {
        const newDate = new Date(currentYear, currentMonth, day); // Create a new Date object for each day
        newDate.setHours(0); // Set the hours to start from midnight
        newDate.setMinutes(0);
        newDate.setSeconds(0);
        newDate.setMilliseconds(0);

        timeSlots.push(newDate);
    }

    // Calculate the number of empty cells needed at the start
    const emptyCellsStart = timeSlots[0].getDay() > 1 ? timeSlots[0].getDay() - 1 : 6;

    const numRows = Math.ceil((timeSlots.length + emptyCellsStart) / 7);

    const onClickListener = (slot: Date) => {
        setClickedSlot(slot);
        setShowSidebar(true);
        setSelectedDate(slot);
    }

    return (
        <div className="calendar-table month">
            <table id="selectableTable">
                <thead>
                <tr>
                    <th>Monday</th>
                    <th>Tuesday</th>
                    <th>Wednesday</th>
                    <th>Thursday</th>
                    <th>Friday</th>
                    <th>Saturday</th>
                    <th>Sunday</th>
                </tr>
                </thead>
                <tbody>
                {Array.from({length: numRows}).map((_, rowIndex) => (
                    <tr key={rowIndex}>
                        {Array.from({length: 7}).map((_, columnIndex) => {
                            const slotIndex = rowIndex * 7 + columnIndex;

                            if (slotIndex < emptyCellsStart || slotIndex >= emptyCellsStart + timeSlots.length) {
                                return <td key={columnIndex}></td>; // Empty cells before or after the month
                            }

                            const slot = timeSlots[slotIndex - emptyCellsStart];

                            const bookedSlot: Array<Date> = [];
                            booked.find((item: Booked) => {
                                if (!item.date)
                                    return;

                                const itemDate = new Date(item.date);
                                if (isSameDay(itemDate, slot)) {
                                    bookedSlot.push(itemDate);
                                }
                            });

                            const availableSlot: Array<Date> = [];
                            availableTimes.find((item: Date) => {
                                if (isSameDay(new Date(item), slot)) {
                                    availableSlot.push(item)
                                }
                            });

                            return <td
                                className={"calendar-table__slot" + (areDatesEqual(slot, clickedSlot) ? ' selected' : '')}
                                key={columnIndex} onClick={() => onClickListener(slot)}>
                                <div>{slot.getDate()}</div>

                                <section className={'d-flex flex-column ms-2 me-2 mt-2 gap-1'}>
                                    {
                                        bookedSlot.length > 0 &&
                                        <span className='calendar-table__slot-available'>
                                            {'Booked: ' + bookedSlot.length}
                                        </span>
                                    }
                                    {
                                        availableSlot.length > 0 &&
                                        <span className='calendar-table__slot-reserved'>
                                            {'Available: ' + (availableSlot.length - bookedSlot.length)}
                                        </span>
                                    }
                                </section>

                            </td>;
                        })}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default CalendarTableMonth;
