import React, {MouseEventHandler, useState} from 'react';
import './VideoToolbar.scss';
import {
    BiExpand,
    BiSolidMicrophone, BiSolidMicrophoneOff,
    BiSolidVolumeLow, BiSolidVolumeMute
} from "react-icons/bi";
import {HiMiniVideoCamera, HiMiniVideoCameraSlash} from "react-icons/hi2";

type Props = {
    microphoneOnClick: MouseEventHandler<HTMLDivElement>,
    soundOnClick: MouseEventHandler<HTMLDivElement>,
    endOnClick: MouseEventHandler<HTMLButtonElement>,
    fullScreenOnClick: MouseEventHandler<HTMLDivElement>,
    videoOnClick: MouseEventHandler<HTMLDivElement>,
}

function VideoToolbar({microphoneOnClick, soundOnClick, endOnClick, fullScreenOnClick, videoOnClick}: Props) {

    const [microphoneEnabled, setMicrophoneEnabled] = useState(true);
    const [soundEnabled, setSoundEnabled] = useState(true);
    const [fullScreenEnabled, setFullScreenEnabled] = useState(true);
    const [videoEnabled, setVideoEnabled] = useState(true);

    return (
        <>
            <div className="video-toolbar container">
                <div className="row d-flex justify-content-center gap-4">
                    <div className='video-toolbar__btn' onClick={() => {
                        setMicrophoneEnabled(!microphoneEnabled);
                        microphoneOnClick();
                    }}>
                        {microphoneEnabled ? <BiSolidMicrophone size={20}/> : <BiSolidMicrophoneOff size={20}/>}
                    </div>

                    <div className='video-toolbar__btn' onClick={() => {
                        setSoundEnabled(!soundEnabled);
                        soundOnClick();
                    }}>
                        {soundEnabled ? <BiSolidVolumeLow size={20}/> : <BiSolidVolumeMute size={20}/>}
                    </div>

                    <button className="button button-warning video-toolbar__text-btn" onClick={endOnClick}>
                        END MEETING
                    </button>

                    <div className='video-toolbar__btn' onClick={() => {
                        setFullScreenEnabled(!fullScreenEnabled);
                        fullScreenOnClick();
                    }}>
                        {fullScreenEnabled ? <BiExpand size={20} /> : <BiExpand size={20} />}
                    </div>

                    <div className='video-toolbar__btn' onClick={() => {
                        setVideoEnabled(!videoEnabled);
                        videoOnClick();
                    }}>
                        {videoEnabled ? <HiMiniVideoCamera size={20} /> : <HiMiniVideoCameraSlash size={20} />}
                    </div>

                </div>

            </div>
        </>
    )
}


export default VideoToolbar;
