import './buttonMultipleToggle.scss';
import React, {useEffect, useRef} from 'react';

type Button = {
    label: string
    value: string;
};

type Props = {
    buttons: Array<Button>
    setSelected: (selected: string) => void;
    selected: string;
};

const ButtonMultipleToggle = ({buttons, setSelected, selected}: Props) => {

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (containerRef) {
            const element = containerRef.current?.getElementsByClassName('item')[0] as HTMLDivElement;
            activeState(element);
        }
    }, [containerRef])

    const activeState = (btnItem: HTMLDivElement | null) => {
        const btnActive = containerRef.current?.getElementsByClassName('js-btn-slide-active')[0] as HTMLElement;
        let itemBounding = containerRef.current?.getBoundingClientRect();
        let btnBounding = btnItem?.getBoundingClientRect();

        if (btnActive && btnItem && itemBounding && btnBounding) {
            btnActive.style.opacity = '1';
            btnActive.style.left = Math.round(btnBounding.left) - Math.round(itemBounding.left) + 'px';
            let width = btnItem.offsetWidth;
            btnActive.style.width = width + 'px';
        }
    };

    const buttonClick = (event: React.MouseEvent<HTMLDivElement>, button: Button) => {
        const btnItem = event.target as HTMLDivElement;
        setSelected(button.value);
        activeState(btnItem);
    };

    return (
        <div className="button-multiple-toggle">
            <div ref={containerRef} className="wrapper">
                <span className="slider js-btn-slide-active" role="presentation"></span>

                {
                    buttons.map((button, index) => {
                        return <div key={index}
                                    className={'item' + (button.value === selected ? ' active' : '')}
                                    onClick={(e) => buttonClick(e, button)}>
                            {button.label}
                        </div>
                    })
                }
            </div>
        </div>
    );
};

export default ButtonMultipleToggle;
