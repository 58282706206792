import './profile.scss';
import {useDispatch, useSelector} from "react-redux";
import React, {ChangeEvent, useState} from "react";
import {setProfileData} from "../../reducers/navbarSlice";
import General from "./pages/General/General";
import Account from "./pages/Account/Account";
import Notifications from "./pages/Notifications/Notifications";
import Payments from "./pages/Payments/Payments";
import dbCore from "../../controller/core";
import {hideProgressBar, showProgressBar} from "../../reducers/progressBarSlice";


function Profile() {
    const profile = useSelector((state: any) => state.profile.value)
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);

    const imageChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target && event.target.files) {
            dispatch(showProgressBar())

            const file = event.target.files[0];

            const response = await dbCore.updateProfilePicture(file);
            if (response) {
                dispatch(setProfileData({...profile, profile_picture: response}));
            }

            dispatch(hideProgressBar())
            setShowModal(false);
        }
    }

    const showImageModal = () => {
        setShowModal(true)
    }

    const hideImageModal = (event: React.MouseEvent<HTMLElement>) => {
        let element = event.target as Element;
        if (element.className === 'profile-modal_wrapper fade-element active')
            setShowModal(false)
    }

    const [generalOn, setGeneralOn] = useState(true)
    const [accountOn, setAccountOn] = useState(false)
    const [notificationsOn, setNotificationsOn] = useState(false)
    const [paymentsOn, setPaymentsOn] = useState(false)

    const handleMenuItemClick = (menuItem: string) => {
        if (menuItem === 'General') {
            setGeneralOn(true);
            setAccountOn(false);
            setNotificationsOn(false);
            setPaymentsOn(false);
        } else if (menuItem === 'Account') {
            setGeneralOn(false);
            setAccountOn(true);
            setNotificationsOn(false);
            setPaymentsOn(false);
        } else if (menuItem === 'Notifications') {
            setGeneralOn(false);
            setAccountOn(false);
            setNotificationsOn(true);
            setPaymentsOn(false);
        } else if (menuItem === 'Payments') {
            setGeneralOn(false);
            setAccountOn(false);
            setNotificationsOn(false);
            setPaymentsOn(true);
        }
    };

    return (
        <div className="profile page container-app d-flex justify-content-around">
            <div
                className="profile-left d-flex flex-column justify-content-start align-items-center col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                <div className={"profile-modal_wrapper fade-element " + (showModal ? 'active' : '')}
                     tabIndex={-1} onClick={hideImageModal}>
                    <div className="profile-modal">
                        <h5 className="h5 mb-3">Upload Image:</h5>
                        <input className="form-control" type="file" name="myImage" onChange={imageChange}/>
                    </div>
                </div>
                <div className="profile-image">
                    <img className="profile-image__img"
                         src={profile.profile_picture ? profile.profile_picture : 'resources/student.png'}
                         alt={'profile picture'} onClick={showImageModal}
                    />
                </div>
                <div className="profile-name text-center">
                    <h2>
                        {profile.first_name} {profile.last_name}
                    </h2>
                </div>
                <div>
                    <ul>
                        <li onClick={() => handleMenuItemClick('General')}>General</li>
                        <li onClick={() => handleMenuItemClick('Account')}>Account</li>
                        <li onClick={() => handleMenuItemClick('Notifications')}>Notifications</li>
                        <li onClick={() => handleMenuItemClick('Payments')}>Payments</li>
                    </ul>
                </div>
            </div>
            <div className="profile-right d-flex col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                {generalOn && <General/>}
                {accountOn && <Account/>}
                {notificationsOn && <Notifications/>}
                {paymentsOn && <Payments/>}
            </div>
        </div>
    );
}

export default Profile;
