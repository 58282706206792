import React, { useState, useEffect } from 'react';
import './Reviews.scss';
import { AiFillStar } from "react-icons/ai";
import {useTranslation} from "react-i18next";

function Reviews() {

    const {t} = useTranslation();

    const [votes, setVotes] = useState([0, 0, 0, 0, 0]);

    useEffect(() => {
        setVotes([526, 16, 20, 11, 2]);
    }, []);

    const totalVotes = votes.reduce((total, vote) => total + vote, 0);

    return (
        <>
            <div className="container reviews d-flex flex-column">
                <div className="row header-row d-flex justify-content-center col-12">
                    <h1 className="text-center">{t("reviewsTitle.label")}</h1>
                </div>
                <div className="row main-row d-flex justify-content-between">
                    <div className="main-row-1 d-flex flex-column justify-content-center col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-12">
                        <div className="main-row-1-total-rating d-flex justify-content-center align-items-center">
                            <AiFillStar style={{ filter: "drop-shadow(0px 0px 3px rgba(255, 255, 255, .3))" }}/>
                            <h2>5.00</h2>
                        </div>
                        <div className="row main-row-1-star-children">
                            {votes.map((vote, index) => (
                                <div key={index} className="star-ratings d-flex justify-content-md-evenly justify-content-center  align-items-center">
                                    <AiFillStar style={{ filter: "drop-shadow(0px 0px 3px rgba(255, 255, 255, .3))" }}/>
                                    <p>{index + 1}</p> {/* Display the star rating number */}
                                    <div className="star-bar">
                                        <div className="fill" style={{ width: `${(vote / totalVotes) * 100}%` }}></div>
                                    </div>

                                    <p className="votes-number">{vote}</p> {/* Display the number of votes for this star */}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="main-row-2 d-flex flex-column col-md-8 col-lg-8 col-xl-8 col-xxl-8 col-12 justify-content-center">
                        <div className="heading-elements d-flex align-items-center">
                            <h4>Teresa</h4>
                            <AiFillStar style={{ filter: "drop-shadow(0px 0px 3px rgba(255, 255, 255, .3))" }}/>
                            <p>5</p>
                        </div>
                        <p>Hi dear Theo,it was lovely meeting you today! you are a joy to talk with ,and passionate ,smiling person. you have a such wonderful personality that it can be contagious to other people.you are the second one genius i've met in Engoo. yes, i'm genuinely curious about how you manage your time. hope to see you next time and ask you to share with me that what's the secret to use time well. i hope the time appears as if i have 48 hours too. thanks for your time. see you!
                        </p>
                        <div className="heading-elements d-flex align-items-center">
                            <h4>Teresa</h4>
                            <AiFillStar style={{ filter: "drop-shadow(0px 0px 3px rgba(255, 255, 255, .3))" }}/>
                            <p>5</p>
                        </div>
                        <p>Hi dear Theo,it was lovely meeting you today! you are a joy to talk with ,and passionate ,smiling person. you have a such wonderful personality that it can be contagious to other people.you are the second one genius i've met in Engoo. yes, i'm genuinely curious about how you manage your time. hope to see you next time and ask you to share with me that what's the secret to use time well. i hope the time appears as if i have 48 hours too. thanks for your time. see you!
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Reviews;