import React, {useState} from 'react';

type Props = {
    profile: { user_type?: 'student' | 'professor' };
    studentLinks: React.JSX.Element[];
    professorLinks: React.JSX.Element[];
    unauthorizedLinks: React.JSX.Element[];
};

const NavDesktopLinks: React.FC<Props> = ({profile, studentLinks, professorLinks, unauthorizedLinks}) => {

    const [activeIndex, setActiveIndex] = useState(-1);

    return <>
        {profile.user_type === 'student' &&
            studentLinks.map((element, index) => (
                <li className={'navbar-container__links-item'} key={index}>
                    {element}
                </li>
            ))}
        {profile.user_type === 'professor' &&
            professorLinks.map((element, index) => (
                <li onClick={() => setActiveIndex(index)}
                    className={'navbar-container__links-item' + (activeIndex === index ? ' active' : '')}
                    key={index}>
                    {element}
                </li>
            ))}

        {!profile.user_type &&
            unauthorizedLinks.map((element, index) => (
                <li className={'navbar-container__links-item'} key={index}>
                    {element}
                </li>
            ))}
    </>
}

export default NavDesktopLinks;
