import React, {useRef} from 'react';
import {areDatesEqual, parseDateString} from "../../../utils/dateUtils";
import Booked from "../../../models/booked";
import TableData from "../TableData/TableData";

type Props = {
    isCurrent: boolean;
    formattedTime: string;
    externalTime: string;
    isDragging: boolean;
    setIsDragging: (isDragging: boolean) => void;
    setSelection: (index: Date, finish?: boolean) => void;
    selectionMade: boolean;
    weekdays: Array<Date>;
    slot: Date;
    booked: Array<Booked>;
    availableTimes: Array<Date>;
    selectStudentBookTutor: Function;
    isStudent: boolean;
    clickedSlot: Date | undefined;
    selectBooking: Function;
};

const calculateTempDate = (weekday: Date, slot: Date): Date => {
    const tempDate = new Date(weekday);
    tempDate.setHours(slot.getHours());
    tempDate.setMinutes(slot.getMinutes());
    tempDate.setSeconds(slot.getSeconds());
    return tempDate;
};

const TableRow: React.FC<Props> = ({
                                       isCurrent,
                                       formattedTime,
                                       externalTime,
                                       isDragging,
                                       setIsDragging,
                                       setSelection,
                                       selectionMade,
                                       weekdays,
                                       slot,
                                       booked,
                                       availableTimes,
                                       selectStudentBookTutor,
                                       isStudent,
                                       clickedSlot,
                                       selectBooking
                                   }) => {
    const ref = useRef<HTMLTableRowElement>(null);

    return (
        <tr
            ref={ref}
            className={(isCurrent ? 'current' : '')}
        >
            <td className="local-time">{formattedTime}</td>
            <td className="external-time">{externalTime}</td>

            {weekdays.map((weekday, index) => {
                const tempDate = calculateTempDate(weekday, slot);

                const bookedSlot = booked.find((item) => {
                    const itemDate = parseDateString(item.date.toString());
                    return areDatesEqual(itemDate, tempDate);
                });

                const availableSlot = availableTimes.find((item) => {
                    const itemDate = parseDateString(item.toString());
                    return areDatesEqual(itemDate, tempDate);
                });

                return (
                    <TableData
                        key={index}
                        index={index}
                        availableSlot={availableSlot}
                        bookedSlot={bookedSlot}
                        dateSlot={tempDate}
                        isDragging={isDragging}
                        setIsDragging={setIsDragging}
                        setSelection={setSelection}
                        selectionMade={selectionMade}
                        selectStudentBookTutor={selectStudentBookTutor}
                        isStudent={isStudent}
                        clickedSlot={clickedSlot}
                        selectBooking={selectBooking}
                    />
                );
            })}
        </tr>
    );
};

export default TableRow;