import React from 'react';
import './switcher.scss';
import {isSameDay} from "../../utils/dateUtils";
import {MdArrowBackIos, MdArrowForward, MdArrowForwardIos} from "react-icons/md";

type props = {
    date: Date,
    setDate: Function,
    increment: number
}

const Switcher = ({date, setDate, increment = 1}: props) => {

    const handlePrevious = () => {
        const previousDate = new Date(date);

        if (increment === 30) {
            previousDate.setDate(1);
            previousDate.setMonth(date.getMonth() - 1);
        } else {
            previousDate.setDate(date.getDate() - increment);
        }
        setDate(previousDate);
    };

    const handleNext = () => {
        const nextDate = new Date(date);

        if (increment === 30) {
            nextDate.setDate(1);
            nextDate.setMonth(date.getMonth() + 1);
        } else {
            nextDate.setDate(date.getDate() + increment);
        }

        setDate(nextDate);
    };

    return (
        <div className="switcher">
            <div className="switcher__leftBtn">
                <MdArrowBackIos size={16} className="switcher__leftBtn" onClick={handlePrevious}/>
            </div>
            <p className="switcher__text text-center">
                {increment !== 30 ?
                    <>
                        {isSameDay(date) ? 'TODAY' : date.toDateString()}
                    </> :
                    <>
                        {date.toLocaleDateString('en-US', {month: 'long'})}, {date.getFullYear()}
                    </>}
            </p>
            <div className="switcher__rightBtn">
                <MdArrowForwardIos size={16} className="switcher__leftBtn" onClick={handleNext}/>
            </div>
        </div>
    );
};

export default Switcher;