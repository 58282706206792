import React, {useState, useEffect, useContext} from 'react';
import './CalendarTable.scss';
import '../TableRow/CalendarTableRow.scss';
import '../Confirmation/CalendarTableConfirmation.scss';
import {dateFormatterTime, formatTimeHHmm, roundToHalfHour} from "../../../utils/dateUtils";
import TableRow from "../TableRow/TableRow";
import {GrClose} from "react-icons/gr";
import {ContextManager} from "../../../app/socket";
import Booked from "../../../models/booked";
import {Modal} from "../../Modal/modal";
import moment from "moment-timezone";
import Select from "react-select";

type Props = {
    date: Date;
    booked: Array<Booked>;
    availableTimes: Array<Date>;
    tutorId: string | null;
    daysToAdd: number;
};

const CalendarTable = ({date, booked, availableTimes, tutorId, daysToAdd}: Props) => {
    const socketContext = useContext(ContextManager);

    const [currentTime, setCurrentTime] = useState<Date>(new Date());
    const [isDragging, setIsDragging] = useState(false);
    const [selectionMade, setSelectionMade] = useState(false);
    const [clickedSlot, setClickedSlot] = useState<Date>();
    const [selectedArray, setSelectedArray] = useState<Date[]>([]);

    const [showModal, setShowModal] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState<Booked>();

    const [showCancelPrompt, setShowCancelPrompt] = useState(false);

    const [showChangeCountry, setShowChangeCountry] = useState(false);

    const [selectedCity, setSelectedCity] = useState<string>('Japan');

    const [timeZoneDifference, setTimeZoneDifference] = useState<number>(-6);

    useEffect(() => {
        // Update the current time every minute
        const interval = setInterval(calculateCurrentTimeSlot, 1000 * 60 * 15); // Every 15 minutes
        return () => clearInterval(interval);
    }, []);

    // Function to calculate the nearest half-hour time slot and set it as "current"
    const calculateCurrentTimeSlot = () => {
        const roundedTime = roundToHalfHour(new Date());
        setCurrentTime(roundedTime);
    };

    // Array to represent 48 time slots (24 hours before + 24 hours after) with 30-minute intervals
    const timeSlots: Date[] = [];

    const weekdays: Date[] = [];

    const fillWeekdays = (startDate: Date, numDays: number): void => {
        for (let i = 0; i < numDays; i++) {
            const currentDate: Date = new Date(startDate);
            currentDate.setDate(startDate.getDate() + i);
            weekdays.push(currentDate);
        }
    };

    fillWeekdays(date, daysToAdd);

    // Round current time to the nearest half-hour
    const roundedCurrentTime = roundToHalfHour(currentTime);

    const baseDate = date; // Get the current date and time (will be the base date)
    baseDate.setMinutes(0); // Set the minutes to 0 to start from the beginning of the hour
    baseDate.setSeconds(0);
    baseDate.setMilliseconds(0);

    for (let i = 0; i < 24; i++) {
        const newDate = new Date(baseDate); // Create a new Date object to avoid modifying the baseDate
        newDate.setHours(i); // Set the hours to the current hour
        timeSlots.push(newDate);

        // Add the half-hour interval
        const halfHourLater = new Date(newDate);
        halfHourLater.setMinutes(newDate.getMinutes() + 30);
        timeSlots.push(halfHourLater);
    }

    const setSelection = (selectedDate: Date, finish = false) => {
        let tempArray = selectedArray;

        if (!tempArray.includes(selectedDate)) {
            tempArray.push(selectedDate);
            setSelectedArray([...tempArray]);
        }

        if (finish) {
            setSelectionMade(true);
        }
    };

    const clearSelection = () => {
        setSelectionMade(false);
        setSelectedArray([]);
        setShowModal(false);
        let tempDate = new Date();
        tempDate.setFullYear(1970);
        setClickedSlot(tempDate);
    };

    const setAvailable = () => {
        if (selectedArray.length > 0 && selectionMade) {
            if (socketContext && socketContext.socket) {
                const tempSet = new Set(selectedArray);
                socketContext.socket.emit('setAvailableTimes', date, [...tempSet]);
                clearSelection();
            } else {
                // TODO: show an error
            }
        }
    };

    const setUnavailable = () => {
        if (selectedArray.length > 0 && selectionMade) {
            if (socketContext && socketContext.socket) {
                const tempSet = new Set(selectedArray);
                socketContext.socket.emit('setUnavailableTimes', date, [...tempSet]);
                clearSelection();
            } else {
                // TODO: show an error
            }
        }
    };

    const selectStudentBookTutor = (dateSelected: Date) => {
        setSelectionMade(true);
        setSelectedArray([dateSelected]);
        setClickedSlot(dateSelected);
        setShowModal(false);
    };

    const selectBooking = (dateSelected: Date, booking: Booked) => {
        setSelectionMade(false);
        setClickedSlot(dateSelected);
        setShowModal(true);
        setSelectedBooking(booking);

    };

    const studentBookTutor = () => {
        if (selectedArray.length === 1 && selectionMade) {
            if (socketContext && socketContext.socket) {
                socketContext.socket.emit('requestBookTutor', clickedSlot, tutorId);
                clearSelection();
            } else {
                // TODO: show an error
            }
        }
    };

    type Option = {
        label: string,
        value: string
    }

    let timezones: Array<Option> = [];

    let zones = moment.tz.names();
    zones.forEach((zone) => {
        timezones.push({label: zone, value: zone});
    })

    function getTimeZoneDifference(countryName: string) {
        if (selectedCity) {
            const now = moment();
            const localTimeZone = moment.tz.guess();

            const localOffset = now.tz(localTimeZone).utcOffset();
            const countryOffset = now.tz(selectedCity).utcOffset();

            return (localOffset - countryOffset) / 60; // Convert to hours
        } else {
            return null; // Country not found in mapping
        }
    }

    const changeExternalTime = () => {
        if (!selectedCity)
            return;

        const zoneDifference = getTimeZoneDifference(selectedCity);

        if (zoneDifference) {
            setTimeZoneDifference(zoneDifference);
            setShowChangeCountry(false);
        }
    };

    return (
        <div className={"calendar-table " + ('calendar-table--' + daysToAdd)}>
            <table id="selectableTable">
                <thead>
                <tr>
                    <th>Your Time</th>
                    <th className="calendar-table__external-td" onClick={() => setShowChangeCountry(true)}>
                        {selectedCity} Time ({(timeZoneDifference < 0 ? '+' : '') + (timeZoneDifference * -1)} Hours)
                    </th>

                    {weekdays.map((slot, index) => {
                        return (
                            <th key={index}>
                                {slot.getDate()}/{slot.getMonth() + 1}
                                <br/> {slot.toLocaleDateString('en-US', {weekday: 'short'})}
                            </th>
                        );
                    })}
                </tr>
                </thead>
                <tbody>
                {timeSlots.map((slot, index) => {
                    const formattedTime = formatTimeHHmm(slot);
                    const externalTime = new Date(slot);
                    externalTime.setHours(externalTime.getHours() + timeZoneDifference * -1); // Add 16 hours for Japan time

                    const isCurrent = slot.getTime() === roundedCurrentTime.getTime();

                    return <TableRow
                        key={index}
                        isCurrent={isCurrent}
                        formattedTime={formattedTime}
                        externalTime={formatTimeHHmm(externalTime)}
                        isDragging={isDragging}
                        setIsDragging={setIsDragging}
                        setSelection={setSelection}
                        selectionMade={selectionMade}
                        weekdays={weekdays}
                        slot={slot}
                        booked={booked}
                        availableTimes={availableTimes}
                        selectStudentBookTutor={selectStudentBookTutor}
                        isStudent={!!tutorId}
                        clickedSlot={clickedSlot}
                        selectBooking={selectBooking}
                    />
                })}
                </tbody>
            </table>

            {!tutorId && (
                <div className={'calendar-table__confirmation' + (selectionMade ? ' open' : '')}>
                    <div className={'calendar-table__confirmation__close'} onClick={clearSelection}>
                        <GrClose size={24}/>
                    </div>

                    <button className={'button button-gray'} onClick={setUnavailable}>
                        UNAVAILABLE
                    </button>
                    <button className={'button button-secondary'} onClick={setAvailable}>
                        AVAILABLE
                    </button>
                </div>
            )}

            {tutorId && (
                <div className={'calendar-table__confirmation' + (selectionMade ? ' open' : '')}>
                    <div className={'calendar-table__confirmation__close'} onClick={clearSelection}>
                        <GrClose size={24}/>
                    </div>

                    <button className={'button button-secondary'} onClick={studentBookTutor}>
                        BOOK
                    </button>
                </div>
            )}

            {
                selectedBooking && (
                    <div className={'calendar-table__confirmation' + (showModal ? ' open' : '')}>
                        <div className={'calendar-table__confirmation__close'} onClick={clearSelection}>
                            <GrClose size={24}/>
                        </div>

                        <div className="">
                            <h2>Your booking
                                {!tutorId &&
                                    <span>
                                        : {selectedBooking.first_name + ' ' + selectedBooking.last_name}
                                    </span>
                                }
                            </h2>

                            <h5>
                                Date: {dateFormatterTime(selectedBooking.date)}
                            </h5>
                        </div>

                        <button className={'button button-warning'} onClick={() => setShowCancelPrompt(true)}>
                            CANCEL
                        </button>
                    </div>
                )
            }

            {/* TODO: cancel booking */}
            {
                showCancelPrompt &&
                <Modal onClose={() => setShowCancelPrompt(false)} size={'small'}>
                    <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <h2>Are you sure you want to cancel this booking?</h2>

                        <div className='mb-5'>
                            You are eligible to refund up to 3 cancelled bookings. If you are sure you want to proceed
                            click agree.
                        </div>

                        <div className="button">
                            AGREE
                        </div>
                    </div>
                </Modal>
            }

            {
                showChangeCountry &&
                <Modal onClose={() => setShowChangeCountry(false)} size={'small'}>
                    <div className="d-flex flex-column justify-content-center align-items-center h-100">
                        <h2>Change Timezone</h2>

                        <div className='mb-5'>
                            Change time zone that interests you
                        </div>

                        <div className={'mb-5'}>
                            <div className='country-selector'>
                                <Select
                                    className="basic-single"
                                    classNamePrefix="select"
                                    defaultValue={timezones[0]}
                                    isClearable={false}
                                    isSearchable={true}
                                    name="color"
                                    onChange={(selectedOption) => setSelectedCity(selectedOption?.value ? selectedOption.value : '')}
                                    options={timezones}
                                />
                            </div>
                        </div>

                        <div className="d-flex gap-3">
                            <div className="button button-gray">
                                Cancel
                            </div>

                            <div className="button" onClick={changeExternalTime}>
                                Save
                            </div>
                        </div>
                    </div>
                </Modal>
            }
        </div>
    );
};

export default CalendarTable;
