import './meeting.scss';
import {Link, useNavigate} from "react-router-dom";
import VideoPlayer from "../../components/Video/VideoPlayer";
import React, {useContext, useEffect, useState} from "react";
// import ChatPopup from "../../components/ChatPopup/ChatPopup";
import ChatUI from "../Chat/chat";
import Notes from "../../components/Notes/Notes";
import {getURLParameter} from "../../utils/generalUtils";
import {ContextManager} from "../../app/socket";
import {useDispatch, useSelector} from "react-redux";
import {setMeeting} from "../../reducers/MeetingSlicer";
import {Modal} from "../../components/Modal/modal";

function Meeting() {
    const navigate = useNavigate();

    const [meetingEnded, setMeetingEnded] = useState(false);

    const meeting = useSelector((state: any) => state.meeting.value);
    const dispatch = useDispatch();

    const context = useContext(ContextManager);

    useEffect(() => {
        const meetingId = getURLParameter('meeting_id');

        if (!meetingId) {
            navigate('/');
        } else {
            if (context.socket) {
                context.socket.emit('getMeeting', meetingId);
                context.socket.on('onMeeting', onMeetingListener);
            }
            // setMeetingId(meetingId);
        }

        return () => {
            if (context.socket)
                context.socket.off('onMeeting', onMeetingListener);
        }
    }, [context]);

    const onMeetingListener = (meeting: any) => {
        if (meeting) {
            const currentDate = new Date();
            const minute = 1000 * 60;
            const meetingDate = new Date(meeting.date);
            const extendOfMeeting = new Date(meetingDate.getTime() + meeting.duration * minute);

            if (currentDate >= extendOfMeeting) {
                setMeetingEnded(true);
            } else {
                dispatch(setMeeting(meeting));

                // calculate the end of the meeting and the things that need to be done
                const timeDifference = Math.abs(currentDate.getTime() - extendOfMeeting.getTime());

                setTimeout(() => {
                    setMeetingEnded(true);
                    dispatch(setMeeting(false));
                }, timeDifference);
            }
        }
    }

    return (
        <div className="container-app page meeting">
            {
                meeting &&
                <div className="row mt-15">
                    <div className="col-12 col-xxl-6">
                        <VideoPlayer/>
                    </div>

                    <div className="col-12 col-xxl-6 mt-10 mt-xxl-0">
                        <div className={'mb-15'}>
                            <h3 className={'mb-3'}>Notes</h3>
                            <Notes/>
                        </div>

                        <div>
                            <h3 className={'mb-3'}>Chat</h3>
                            <ChatUI/>
                        </div>

                    </div>
                </div>
            }

            {
                (!meeting && !meetingEnded) &&
                <Modal size={''} onClose={() => {
                }} closable={false}>
                    <div className='d-flex flex-column align-items-center justify-content-center h-100'>
                        <h5 className={'mb-4'}>
                            Wrong meeting url
                        </h5>

                        <Link className="button" to={'/'}>
                            RETURN TO HOMEPAGE
                        </Link>
                    </div>
                </Modal>
            }

            {
                meetingEnded &&
                <Modal size={''} onClose={() => {
                }} closable={false}>
                    <div className='d-flex flex-column align-items-center justify-content-center h-100'>
                        <h5 className={'mb-4'}>
                            This meeting has ended.
                        </h5>

                        <Link className="button" to={'/'}>
                            RETURN TO HOMEPAGE
                        </Link>
                    </div>
                </Modal>
            }

        </div>
    );
}

export default Meeting;
