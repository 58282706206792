import React from 'react';
import './MiniCalendar.scss';

type Props = {
    date: Date
}

const MiniCalendar = ({date}: Props) => {
    // Get the day of the week (e.g., "Sun", "Mon", etc.)
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dayOfWeek = daysOfWeek[date.getDay()];

    // Get the date number (e.g., 23)
    const dateNumber = date.getDate();

    return (
        <div className="mini-calendar d-flex flex-column justify-content-center align-items-center">
            <h1 className="mb-0">{dateNumber}</h1>
            <h4 className="mb-0">{dayOfWeek}</h4>
        </div>
    );
}


export default MiniCalendar;