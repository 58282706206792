import React from "react";
import './General.scss';
import LatestLectures from "../../../../components/LatestLectures/LatestLectures";


function General() {
    return (
        <>
            <div className="general container-fluid d-flex flex-column">
                <h1 className="general-title mb-4">Latest Lectures</h1>
                <LatestLectures />
            </div>
        </>
    )
}

export default General;