import React, {useState} from 'react';
import './BrowseTutors.scss';
import {AiFillStar} from "react-icons/ai";
import {useNavigate} from "react-router-dom";
import User from "../../models/user";
import dbCore from "../../controller/core";

const BrowseTutors = () => {
    const navigate = useNavigate();

    const [users, setUsers] = useState<Array<User>>([]);

    const perLoad = 2;

    const [page, setPage] = useState(1);

    const loadMore = () => {
        if (getTutorsHook) {
            getTutorsHook(page + 1, perLoad, onGetTutorsBatch);
            setPage(page + 1);
        }
    }

    const onGetTutorsBatch = (data: Array<User>) => {
        if (data && data.length) {
            setUsers(prevUsers => [...prevUsers, ...data]);
        }
    }

    const getTutorsHook: Function = dbCore.useGetTutors(page, perLoad, onGetTutorsBatch);

    const visitTutor = (tutorId: string) => {
        navigate("/tutor?tutor_id=" + tutorId)
    }

    return (
        <div className="browse-tutors">
            <div className="browse-tutors__wrapper">
                {users.map((user, index) => {
                    return (
                        <div key={index} className="browse-tutors__item" onClick={() => visitTutor(user._id)}>
                            <img className='browse-tutors__user-pic'
                                 src={user.profile_picture ? user.profile_picture
                                     : '/resources/student.png'}
                                 alt={'profile picture'}/>

                            <div className="browse-tutors__footer">
                                <div className="browse-tutors__name">
                                    {user.first_name + ' ' + user.last_name}
                                </div>

                                <div className="d-flex gap-3">
                                    <div className="browse-tutors__star">
                                        <AiFillStar className={'mr-1'}/>
                                        {user.rating ? user.rating : '-'}
                                    </div>

                                    <div className="browse-tutors__info">
                                        {user.level ? user.level : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className="button" onClick={loadMore}>
                LOAD MORE
            </div>
        </div>
    );
};

export default BrowseTutors;