import {getDatabase, ref, onValue, orderByKey} from "firebase/database";
import {useEffect} from "react";
import Lesson from "../../../models/lesson";
import {DatabaseReference} from "@firebase/database";

export default function useGetLectures(numberToShow: number, page: number, onLessonsListener: (lessonsData: Array<Lesson>, pageData: number) => void) {

    useEffect(() => {
        const db = getDatabase();
        const path = 'bookings/';
        const pathRef = ref(db, path);

        // getRecords(pathRef, page)
        onValue(pathRef, (snapshot) => {
            const data = snapshot.val();
            if (data && data as Array<Lesson>) {
                onLessonsListener(data, 1);
            }
        });

        return () => {

        }
    }, []);

    return (newNumberToShow: number, newPage: number) => {

    }

}

// function getRecords(bookingsRef: DatabaseReference, lastKey = 0) {
//     let query = bookingsRef.orderByKey();
//
//     if (lastKey) {
//         query = query.startAt(lastKey).limitToFirst(11);
//     } else {
//         query = query.limitToFirst(10);
//     }
//
//     query.once('value')
//         .then(function(snapshot) {
//             let records = snapshot.val();
//
//             // Exclude the last record from the previous query if it's included
//             if (lastKey && records) {
//                 delete records[lastKey];
//             }
//
//             // Process the retrieved data
//             for (var key in records) {
//                 var data = records[key];
//                 console.log(key, data);
//                 lastKey = key;
//             }
//         })
//         .catch(function(error) {
//             console.error("Error fetching data:", error);
//         });
// }