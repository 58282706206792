import {getAuth, createUserWithEmailAndPassword, User} from "firebase/auth";
import AuthResponse from "../../../models/authResponse";
import {getDatabase, ref, set} from "firebase/database";

type Props = { password: any; last_name: any; profile_picture: string; first_name: any; email: any; }
const register = async (data: Props) => {

    const {email, password} = data;

    let user: User | null = null;
    let status: string = '';
    let returnData: AuthResponse = {
        status: 0,
        message: ''
    };

    const auth = getAuth();
    await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            user = userCredential.user;
        })
        .catch((error) => {
            status = error.code;
            returnData.message = error.message;
        });

    if (!user) {
        switch (status) {
            case 'auth/claims-too-large':
                returnData.status = 500;
                break;
            case 'auth/email-already-exists':
                returnData.status = 410;
                break;
            default:
                returnData.status = 403
                break;
        }

        return returnData;
    }

    if (user && isUserType(user) && (user as User).uid) {
        let uid = (user as User).uid; // Use type assertion here
        await writeUserData(uid, data.first_name, data.last_name);

        returnData.status = 200;
    }

    return returnData;
}

function isUserType(variable: any): variable is User {
    // Check if the variable has the required properties or conditions to be considered a User
    // return typeof variable === 'object' && 'uid' in variable;
    return variable.uid !== undefined && variable.uid;
}

function writeUserData(userId: string, firstName: string, lastName: string) {
    const db = getDatabase();
    const path = 'users/' + userId;

    return set(ref(db, path), {
        first_name: firstName,
        last_name: lastName,
        activated: true,
        user_type: 'student'
    });
}

export default register;