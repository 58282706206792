const dateFormatter = (date: Date | string): string => {
    return new Date(date).toLocaleDateString().split('T')[0];
};

const dateFormatterTime = (date: Date | string): string => {
    const d = new Date(date);
    return [
            d.getDate(),
            d.getMonth() + 1,
            d.getFullYear()
        ].join('/') + ' ' +
        [
            d.getHours(),
            d.getMinutes(),
            d.getSeconds()
        ].join(':');
};

const isSameDay = (date1: Date, date2: Date = new Date()): boolean => {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
};

const roundToHalfHour = (date: Date): Date => {
    date.setSeconds(0);
    date.setMilliseconds(0);

    const minutes = date.getMinutes();
    const remainder = minutes % 30;
    if (remainder < 15) {
        date.setMinutes(minutes - remainder);
    } else {
        date.setMinutes(minutes + (30 - remainder));
    }
    return date;
};

const formatTimeHHmm = (dateString: string | Date): string => {
    const dateObj = new Date(dateString);
    return `${dateObj.getHours().toString().padStart(2, '0')}:${dateObj.getMinutes().toString().padStart(2, '0')}`;
};

const areDatesEqual = (date1: Date, date2: Date): boolean => {
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate() &&
        date1.getHours() === date2.getHours() &&
        date1.getMinutes() === date2.getMinutes()
    );
};

const parseDateString = (dateString: string): Date => {
    const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

    if (isoDateRegex.test(dateString)) {
        return new Date(dateString);
    } else {
        const dateObj = new Date(dateString);
        const timeZoneOffset = dateObj.getTimezoneOffset();
        return new Date(dateObj.getTime() + timeZoneOffset * 60000);
    }
};

const formatDate = (date: Date|string) => {
    date = new Date(date);
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const dayOfWeek = daysOfWeek[date.getDay()];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${dayOfWeek} ${day.toString().padStart(2, '0')} ${month}, ${year}`;
}

export {
    dateFormatter,
    dateFormatterTime,
    isSameDay,
    roundToHalfHour,
    formatTimeHHmm,
    areDatesEqual,
    parseDateString,
    formatDate
};
