import "./LessonDetails.scss"
import UserLessonStatus from "../../components/UserLessonStatus/UserLessonStatus";
import Waveform from "../../components/Waveform/Waveform";
import Notes from "../../components/Notes/Notes";
import {MdStickyNote2} from "react-icons/md";
import LessonInfoTable from "../../components/LessonInfoTable/LessonInfoTable";
import Switcher from "../../components/Switcher/Switcher";
import React, {useContext, useEffect, useState} from "react";
import {getURLParameter} from "../../utils/generalUtils";
import {useNavigate} from "react-router-dom";
import {ContextManager} from "../../app/socket";
import Lesson from "../../models/lesson";
import {formatDate} from "../../utils/dateUtils";

function LessonDetails() {

    const [date, setDate] = useState(new Date());
    const [lesson, setLesson] = useState<Lesson>();

    const context = useContext(ContextManager);
    const navigate = useNavigate();

    useEffect(() => {
        const lessonId = getURLParameter('lesson_id');

        if (!lessonId) {
            navigate('/');
        } else {
            if (context.socket) {
                context.socket.emit('getLesson', lessonId);
                context.socket.on('onLesson', onLessonListener);
            }
        }

        return () => {
            if (context.socket)
                context.socket.off('onLesson', onLessonListener);
        }
    }, [context]);

    const details = {
        language: 'English',
        content: 'Propositions: There is, There are (Part 4)',
        intro: 'Before the lesson starts I would like to make self introductions',
        corrections: 'I want the teacher to proactively correct my mistakes',
        remarks: '',
    }

    const onLessonListener = (lessonData: Lesson) => {
        if (lessonData) {
            console.log(lessonData)
            setLesson(lessonData);
        } else {
            navigate('/');
        }
    }

    const status = 'completed'

    return (<>
        {lesson &&
            <div className="container-app lesson-details">
                <h1 className="mb-10">
                    Lesson Details - {formatDate(lesson.date)}
                </h1>

                <div className={'mb-8'}>
                    <div className="d-flex justify-content-between align-items-center flex-wrap gap-4">
                        <UserLessonStatus user={lesson.user} status={status}/>
                        {lesson.date && <Switcher date={new Date(lesson.date)} setDate={setDate}/>}
                    </div>
                </div>

                <div className={'mb-12'}>
                    {
                        lesson.recording && <Waveform audio={lesson.recording}/>
                    }
                </div>

                <div className={'mb-12'}>
                    <LessonInfoTable details={details}/>
                </div>

                <div className={'mb-12'}>
                    <h4 className={'mb-3'}>
                        <MdStickyNote2 className={'mr-4'}/>
                        Notes
                    </h4>
                    <Notes/>
                </div>

            </div>}
    </>);
}

export default LessonDetails;
