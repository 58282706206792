import React from 'react';
import './HelpCenter.scss';
import { BsSearch, BsFillPersonLinesFill, BsCreditCard2Back } from "react-icons/bs";
import {Tb24Hours} from "react-icons/tb";
import { MdOutlineAssuredWorkload } from "react-icons/md";


function HelpCenter() {

    return (
        <>
            <div className="help-center container-fluid d-flex flex-column justify-content-center min-vh-100 h-100">
                <div className="row d-flex flex-column align-items-center">
                    <h1 className="help-center-title text-center mb-5">
                        Hi! How can we help you?
                    </h1>
                    <div className="help-center-search-wrapper d-flex justify-content-between col-8 col-md-6 col-lg-5 col-xl-4 col-xxl-4 p-0">
                        <input
                            type="text"
                            placeholder="Search..."
                            className="input help-center-search-input"
                        />
                        <div className="help-center-search-icon mb-0 align-self-center button">
                            <BsSearch/>
                        </div>
                    </div>
                </div>
                <div className="help-center-categories-wrapper row d-flex justify-content-center">
                    <div className="help-center-category d-flex flex-column align-items-center">
                        <div className="help-center-category-icon">
                            <BsFillPersonLinesFill />
                        </div>
                        <p className="text-center mt-3 mb-0">
                            Manage your account
                        </p>
                    </div>
                    <div className="help-center-category d-flex flex-column align-items-center">
                        <div className="help-center-category-icon">
                            <BsCreditCard2Back />
                        </div>
                        <p className="text-center mt-3 mb-0">
                            Payment
                        </p>
                    </div>
                    <div className="help-center-category d-flex flex-column align-items-center">
                        <div className="help-center-category-icon">
                            <Tb24Hours />
                        </div>
                        <p className="text-center mt-3 mb-0">
                            Returns & Refunds
                        </p>
                    </div>
                    <div className="help-center-category d-flex flex-column align-items-center">
                        <div className="help-center-category-icon">
                            <MdOutlineAssuredWorkload />
                        </div>
                        <p className="text-center mt-3 mb-0">
                            Guarantees & assurances
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}


export default HelpCenter;