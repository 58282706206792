import {getAuth, signInWithEmailAndPassword, User} from "firebase/auth";
import LoginResponse from "../../../models/loginResponse";
import firebase from "firebase/compat";
import {getDatabase, ref, child, get} from "firebase/database";
import dbCore from "../../core";

type Props = {
    email: string,
    password: string
}

const login = async (data: Props) => {
    const {email, password} = data;

    let user: null | User = null;
    let response: LoginResponse = {
        status: 0,
        message: '',
        user: null
    };

    let errorCode: number;
    let errorMessage: string | null = null;

    const auth = getAuth();
    await signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in
            user = userCredential.user;
            const token = user.getIdToken();
        })
        .catch((error) => {
            errorCode = error.code;
            errorMessage = error.message;
        });


    if (user) {
        response.status = 200;
        response.user = await dbCore.getUserData();
    } else {
        response.status = 403;
        response.message = errorMessage ? errorMessage : 'Error'
    }

    return response;
}

const read = (userId: string) => {
    return new Promise((resolve, reject) => {
        const dbRef = ref(getDatabase());
        const path = 'users/' + userId;

        get(child(dbRef, path)).then((snapshot) => {
            if (snapshot.exists()) {
                resolve(snapshot.val())
            } else {
                reject('nothing retrieved')
            }
        }).catch((error) => {
            console.error(error);
        });
    });
}


export default login;