import {useNavigate} from "react-router-dom";
import "./TutorPage.scss"
import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useState} from "react";
import {getURLParameter} from "../../utils/generalUtils";
import TutorInfo from "../../components/TutorInfo/TutorInfo";
import Reviews from "../../components/Reviews/Reviews";
import {ContextManager} from "../../app/socket";
import Schedule from "../../components/Schedule/Schedule";
import User from "../../models/user";
import Tutor from "../../models/tutor";

function TutorPage() {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const socketContext = useContext(ContextManager);

    const [tutorId, setTutorId] = useState<string | null>(null);

    const [tutor, setTutor] = useState<Tutor>();

    useEffect(() => {
        const tutor_id = getURLParameter('tutor_id');

        if (tutor_id) {
            setTutorId(tutor_id);

            if (socketContext && socketContext.socket) {
                socketContext.socket.emit('getTutorData', tutor_id);

                socketContext.socket.on('onTutorData', (data: User) => {
                    let tempData = {...data, ...user}
                    setTutor(tempData);
                });
            }
        } else {
            navigate('/');
        }
    }, [socketContext]);

    // @ts-ignore
    const user: Tutor = {
        video: '/resources/intro-video.mp4',
        description: 'Hello there! I am a highly qualified tutor with a Bachelor\'s Degree in Sound Engineering. From 2008 to 2012, I worked as the lead singer and producer of a music band and was a band member of three music groups. My hobbies include playing sports, reading books, composing music, watching interesting movies and series, traveling, listening to podcasts and music, and taking long walks. I have a long experience in academic writing (in English), as I have helped hundreds of university students worldwide. I have written academic essays on a wide range of topics, including English and Literature, Psychology, Philosophy, Healthcare, Law, Humanities, History, Natural Sciences, Religion/Theology, and others. I\'ve become an ESL tutor because I enjoy creating a friendly and interactive learning environment for my students. I\'m very passionate about helping others learn and practice English, and I look forward to meeting and working with you to achieve your language goals.',
        rating_count: 255,
        country: 'greece',
        gender: 'male',
        age: 32,
        school: 'akmi',
        favorite_movies: 'the matrix',
        hobbies: 'Reading books, writing, learning-mastering new skills, composing music, playing the piano, fitness.',
    }

    return (
        <div className="container-app tutor-page">
            <div className="row">
                <div className="col mt-15 mb-15">
                    {
                        tutor && (<TutorInfo tutor={tutor}/>)
                    }
                </div>
            </div>

            <div className="row">
                <div className="col mb-15">
                    {
                        tutorId && (<Schedule tutorId={tutorId} />)
                    }
                </div>
            </div>

            <div className="row">
                <div className="col mb-15">
                    <Reviews />
                </div>
            </div>
        </div>
    );
}

export default TutorPage;
