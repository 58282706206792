import {calls} from "../config/db_config";
import {tokenUtils} from "./token-utils";

export const isValidHttpUrl = (string: string) => {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}

export const iconStyle = (bgColor: string) => {
    return {background: bgColor, padding: '5px', borderRadius: '50%', marginBottom: '8px'}
}

export const getURLParameter = (name: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
}

export const call = (path: string, load: any = null, method: string = 'GET', authorized = true) => {
    let token;
    if (authorized) {
        token = tokenUtils.getBearerToken();
        if (!token) {
            return new Promise((resolve, reject) => {
                resolve({status: 400});
            });
        }
    }

    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/json');

    if (authorized && token) {
        requestHeaders.set('Authorization', token);
    }

    let init: any = {
        method: method,
        headers: requestHeaders,

    };

    if (load && method !== 'GET') {
        init.body = JSON.stringify(load);
    }

    return fetch(path, init).then(response => response.json());
}

export const callForm = (path: string, load: any = null, method: string = 'GET') => {
    const token = tokenUtils.getBearerToken();
    if (!token) {
        return Promise.resolve({ status: 400 });
    }

    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Authorization', token);

    let init: any = {
        method: method,
        headers: requestHeaders,
    };

    if (load && method !== 'GET') {
        if (load instanceof FormData) {
            init.body = load;
        } else {
            const formData = new FormData();
            Object.keys(load).forEach(key => formData.append(key, load[key]));
            init.body = formData;
        }
    }

    return fetch(path, init).then(response => response.json());
};

const colorValues = ['purple', 'green', 'orange'];

export const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * colorValues.length);
    return colorValues[randomIndex];
};