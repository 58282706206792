import React, {FormEvent} from "react";
import {useEffect} from 'react';

type Props = {
    id?: string,
    className?: string,
    callback: Function,
    children: React.ReactNode,
}

const CaptchaForm = ({id, className, callback, children}: Props) => {

    const key: string = '6Lea0oooAAAAALdD-1UxDcaMTsyvDhvLj26GHeWl';

    useEffect(() => {
        // Add reCaptcha
        const script = document.createElement("script")
        script.src = "https://www.google.com/recaptcha/api.js?render=" + key;
        script.addEventListener("load", () => {
            console.log('script loaded')
        })
        document.body.appendChild(script)
    });

    const onSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // @ts-ignore
        if (window.grecaptcha) {
            // window.grecaptcha.ready(_ => {
            //     console.log('grecaptcha ready')
            //     // @ts-ignore
            //
            // })
            // @ts-ignore
            window.grecaptcha
                .execute(key, {action: "submit"})
                .then((token: any) => {
                    console.log('captcha token is here');
                    callback();
                })
        }
    }

    return (
        <form id={id} className={className} onSubmit={onSubmit}>
            {children}

            <div
                className="g-recaptcha"
                data-sitekey={key}
                data-size="invisible"
            ></div>
        </form>
    )
};

export default CaptchaForm;