import {createSlice} from '@reduxjs/toolkit'

export const CurrentBookingSlicer = createSlice({
    name: 'currentBooking',
    initialState: {
        value: false,
    },
    reducers: {
        setCurrentBooking: (state, action: any) => {
            state.value = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const {setCurrentBooking} = CurrentBookingSlicer.actions

export default CurrentBookingSlicer.reducer