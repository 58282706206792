import React, { useState, useEffect } from 'react';
import './Partners.scss';

// @ts-ignore
import { ReactComponent as TerraFormIcon } from "../../resources/Partners/terraform-svgrepo-com.svg";
// @ts-ignore
import { ReactComponent as PayPalIcon } from "../../resources/Partners/paypal-svgrepo-com.svg";
// @ts-ignore
import { ReactComponent as SlackIcon } from "../../resources/Partners/slack-svgrepo-com.svg";
// @ts-ignore
import { ReactComponent as MondayIcon } from "../../resources/Partners/monday-svgrepo-com.svg";
// @ts-ignore
import { ReactComponent as MiroIcon } from "../../resources/Partners/miro-svgrepo-com.svg";

function Partners() {
    const icons = [
        TerraFormIcon,
        PayPalIcon,
        SlackIcon,
        MondayIcon,
        MiroIcon
        // Add other icon components here
    ];

    return (
        <div className="partners">
            <div className="partners__carousel">
                <div className="partners__carousel__wrapper">
                    {icons.map((IconComponent, index) => (
                        <IconComponent key={index} className="partners__icon-element" />
                    ))}
                    {/* Duplicate the icons to ensure seamless looping */}
                    {icons.map((IconComponent, index) => (
                        <IconComponent key={index + icons.length} className="partners__icon-element" />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Partners;