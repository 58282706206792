import React from 'react';
import { dateFormatterTime } from '../../utils/dateUtils';
import { Link } from 'react-router-dom';
import Notification from '../../models/notification';
import {useSelector} from "react-redux";
import "./Notifications.scss";

type Props = {
    navNotifications: boolean;
};

function Notifications({ navNotifications }: Props) {
    const notifications = useSelector((state: any) => state.notifications.value);

    const currentBooking = useSelector((state: any) => state.currentBooking.value);

    return (
        <div className="notifications">
            <div className={'navbar-notifications' + (navNotifications ? ' active' : '')}>
                {
                    (notifications.length === 0 && !currentBooking) &&
                    <div className="text-center d-flex flex-column align-items-center">
                        <img className="navbar-notifications__empty-img" src="/resources/no-notifications.png" alt="no-notifications"/>

                        Nothing new
                    </div>
                }

                {
                    notifications.length > 0 &&
                    <ul className="navbar-notifications__list">
                        {notifications.slice(0).reverse().map((notification: Notification, index: number) => (
                            <li key={index}>
                                <div className={'d-flex'}>
                                    <div className="item-content">
                                        <span className="item-content__title">{notification.title}</span>
                                        <span className="item-content__text">{notification.description}</span>
                                    </div>
                                </div>

                                <div className={'date'}>{dateFormatterTime(notification.created)}</div>
                            </li>
                        ))}
                        <li>
                            <Link className={'button text-decoration-none'} key={notifications.length + 1} to="/notifications">
                                Δείτε όλες
                            </Link>
                        </li>
                    </ul>
                }


                {
                    currentBooking &&
                    <ul className="navbar-notifications__list">
                        <li key={-1}>
                            <div className={'d-flex'}>
                                <div className="item-content">
                                    <span className="item-content__title">{currentBooking.title}</span>
                                    <span className="item-content__text">{currentBooking?.description}</span>
                                </div>
                            </div>

                            <div className={'date'}>{dateFormatterTime(currentBooking.created)}</div>

                            {
                                currentBooking.ready &&
                                <Link className={'button button-secondary mt-3'} to={"/call?meeting_id=" + currentBooking._id}>
                                    Meeting
                                </Link>
                            }

                        </li>
                    </ul>
                }

            </div>
        </div>
    );
}

export default Notifications;
