import "./SchedulePage.scss"
import React, {useContext, useEffect, useReducer, useState} from "react";
import MiniCalendar from "../../components/MiniCalendar/MiniCalendar";
import {ContextManager} from "../../app/socket";
import Schedule from "../../components/Schedule/Schedule";


function SchedulePage() {

    const socketContext = useContext(ContextManager);

    const [date, setDate] = useState(new Date());

    const onDateChange = (date: Date) => {
        setDate(date)
    }

    return (
        <div className="container-app schedule">
            <div className="row">
                <div className="col-12">

                    <div className="d-flex justify-content-start align-items-center mt-14 mb-12">
                        <MiniCalendar date={date} />

                        <div className={'ml-8'}>
                            <h1 className="mb-0 text-start">
                                Daily Schedule
                            </h1>

                            <h4 className="mb-0 text-start">
                                Check and edit you calendar
                            </h4>
                        </div>
                    </div>

                </div>
            </div>

            <Schedule tutorId={null} onDateChange={onDateChange}/>
        </div>
    );
}

export default SchedulePage;
