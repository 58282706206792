import React, {useContext, useEffect, useState} from 'react';
import './notificationPage.scss';
import {dateFormatterTime} from "../../utils/dateUtils";
import {ContextManager} from "../../app/socket";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import notification from "../../models/notification";

const NotificationPage = () => {
    const {t} = useTranslation();
    const [notifications, setNotifications] = useState<Array<notification>>([]);
    const socketContext = useContext(ContextManager);

    const profile = useSelector((state: any) => state.profile.value);

    useEffect(() => {
        getNotifications();
    }, []);

    function getNotifications() {
        const notificationListener = (data: Array<notification>) => {
            setNotifications(data);
        };

        if (socketContext && socketContext.socket) {
            socketContext.socket.on('notification', notificationListener);
            socketContext.socket.emit('getNotifications');
        }
    }

    return (
        <div className={'container-app notification-page'}>

            <h1>{t("notifications.label")}</h1>

            {
                notifications.length > 0 && <ul className={'notification-page__wrapper'}>
                    {notifications.slice(0).reverse().map((notification, id) => (
                        <li className={'notification-page__item'} key={id}>
                            <img className='notification-page__img'
                                 src={profile.profile_picture !== '' ? profile.profile_picture : '/resources/student.png'}
                                 alt={'user icon'}/>

                            <div className="notification-page__content">
                                <div className="notification-page__title">{notification.title}</div>
                                <span className="notification-page__description">{notification.description}</span>
                            </div>

                            <div className={'notification-page__date'}>{dateFormatterTime(notification.created)}</div>
                        </li>
                    ))
                    }
                </ul>
            }

            {
                notifications.length === 0 &&
                <div className="d-flex flex-grow-1 flex-column align-items-center justify-content-center">
                    <img className="notification-page__empty-img" src="/resources/no-notifications.png" alt="no-notifications"/>
                    <h3 className="fw-bold">No Notifications</h3>
                </div>
            }

        </div>
    );
};

export default NotificationPage;
