import React from 'react';
import './Tickets.scss';
import {useSelector} from "react-redux";
import {BsTicketPerforatedFill} from "react-icons/bs";

const Tickets = () => {

    const tickets = useSelector((state: any) => state.tickets.value);

    return (
        <div className='tickets'>
            <BsTicketPerforatedFill size={24} />
            <span>Tickets: </span>{tickets && tickets}
        </div>
    );
};

export default Tickets;