import {getAuth, onAuthStateChanged} from "firebase/auth";
import {getDatabase, ref, child, get} from "firebase/database";
import User from "../../../models/user";

const getUserData = async () => {
    const auth = getAuth();
    let firebaseUser = auth.currentUser;
    let user: User | null = null;

    if (firebaseUser && firebaseUser.uid && firebaseUser.email) {
        let token = await firebaseUser.getIdToken();

        let readData = await read(firebaseUser.uid);
        if (readData && readData as User && token) {
            let userData = readData as User;
            user = {
                ...userData,
                _id: firebaseUser.uid,
                email: firebaseUser.email,
                profile_picture: firebaseUser.photoURL ? firebaseUser.photoURL : '',
                token: token
            }
        }
    }

    return user;
}

const read = (userId: string) => {
    return new Promise((resolve, reject) => {
        const dbRef = ref(getDatabase());
        const path = 'users/' + userId;

        get(child(dbRef, path)).then((snapshot) => {
            if (snapshot.exists()) {
                resolve(snapshot.val())
            } else {
                reject('nothing retrieved')
            }
        }).catch((error) => {
            console.error(error);
        });
    });
}


export default getUserData;