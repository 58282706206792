import './FavoriteTutors.scss';
import {useContext, useEffect, useState} from "react";
import {ContextManager} from "../../app/socket";
import React from 'react';
import User from "../../models/user";
import {useNavigate} from "react-router-dom";

function Users() {
    const context = useContext(ContextManager);
    const navigate = useNavigate();

    const [favoriteTutors, setFavoriteTutors] = useState<Array<User>>([]);

    useEffect(() => {
        const usersListener = (data: Array<User>) => {
            setFavoriteTutors(data);
        };

        if (context.socket) {
            context.socket.on('onFavoriteTutors', usersListener);
            context.socket.emit('getFavoriteTutor');
        }
    }, []);

    return (
        <div className="favorite-tutors">
            {favoriteTutors && favoriteTutors.map((user, id) => (
                <div className={'favorite-tutors__users-wrapper'} key={id} onClick={() => navigate('/tutor?tutor_id=' + user._id)}>
                    <img className={'favorite-tutors__users-picture'} src={user.profile_picture !== '' ?
                        user.profile_picture : '../../../resources/student.png'}
                         alt={'profile picture'}/>
                    <div
                        className={'favorite-tutors__users-name'}>{user.first_name} {user.last_name}</div>
                </div>
            ))
            }
        </div>
    );
}

export default Users;
