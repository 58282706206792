import {cookieUtils} from "./cookie-parser";
import User from "../models/user";

export const tokenToCookie = function (userObject: User) {
    const cookie = JSON.stringify(userObject);

    cookieUtils.setCookie('auth', cookie, 200)
}

export const removeToken = function () {
    cookieUtils.eraseCookie('auth')
}