import "./UserHomepage.scss"
import TopTutors from "../../components/TopTutors/TopTutors";
import {useTranslation} from "react-i18next";
import React from "react";
import BrowseTutors from "../../components/BrowseTutors/BrowseTutors";

function UserHomepage() {
    const {t} = useTranslation();

    return (
        <div className="container-app user-homepage">
            <div className={'mt-15 mb-15'}>
                <h1 className={'mb-10 text-center'}>{t("topTutorsTitle.label")}</h1>
                <TopTutors/>
            </div>

            <div className={'mb-15'}>
                <div className="d-flex justify-content-between align-items-center mb-10">
                    <h1 className={'text-center'}>{t("tutorsTitle.label")}</h1>

                </div>
                <BrowseTutors/>
            </div>
        </div>
    );
}

export default UserHomepage;
