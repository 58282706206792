import React, {useState} from 'react';
import './ReferralSystem.scss';
import { GoCopy, GoCheck } from "react-icons/go";
import { BsFacebook, BsTwitter, BsInstagram } from "react-icons/bs";

function ReferralSystem() {

    const [copyText, setCopyText] = useState("This text representing a link will be copied.");
    const [buttonText, setButtonText] = useState("Copy Text")
    const [copyToggle, setCopyToggle] = useState(true)

    const handleCopyClick = () => {
        navigator.clipboard.writeText(copyText)
            .then(() => {
                setCopyToggle(false);
                setButtonText("Copied!");


                setTimeout(() => {
                    setCopyToggle(true);
                    setButtonText("Copy Text");
                }, 2000);
            })
            .catch(error => {
                console.error('Copy failed: ', error);
            });
        };

    return (
        <>
            <div className="referral container d-flex flex-column col-12 col-lg-10 col-xl-8 col-xxl-7">
                <p className="referral-firstparagraph fw-bold">Send or share your referral link.</p>
                <p className="referral-firstparagraph">Copy the text below for a better engagement</p>
                <div className="referral-first-row d-flex">
                    <div className="referral-copywrapper d-flex justify-content-between col-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                        <p className="referral-copy mb-0 align-self-center">{copyText}</p>
                        <button className="referral-buttn d-flex p-3 align-self-center justify-content-center" onClick={handleCopyClick}>
                            {copyToggle && <GoCopy className="align-self-center"/>}
                            {!copyToggle && <GoCheck className="align-self-center"/>}
                            <p className="mb-0 align-self-center">{buttonText}</p>
                        </button>
                    </div>
                    <div className="d-flex">
                        <a href="https://www.facebook.com/" target="_blank" className="referral-item d-flex justify-content-center align-items-center">
                            <BsFacebook />
                        </a>
                        <a href="https://twitter.com/" target="_blank" className="referral-item d-flex justify-content-center align-items-center">
                            <BsTwitter />
                        </a>
                        <a href="https://www.instagram.com/" target="_blank" className="referral-item d-flex justify-content-center align-items-center">
                            <BsInstagram />
                        </a>
                    </div>
                </div>
                <div className="referral-second-row d-flex mt-4">
                    <div className="d-flex flex-column justify-content-between col-4 ">
                        <div className="referral-title-div d-flex align-items-center">
                            <div className="referral-guide-steps d-flex justify-content-center align-items-center">
                                <p className="mb-0"> 1 </p>
                            </div>
                            <h6 className="mb-0">Copy the Text</h6>
                        </div>
                        <p className="referral-guide-steps-text mt-4 mb-0">Share your link with your friends and help them get onboard EngLink.</p>
                    </div>

                    <div className="d-flex flex-column justify-content-between col-4">
                        <div className="referral-title-div d-flex align-items-center">
                            <div className="referral-guide-steps d-flex justify-content-center align-items-center">
                                <p className="mb-0"> 2 </p>
                            </div>
                            <h6 className="mb-0">Registration</h6>
                        </div>
                        <p className="referral-guide-steps-text mt-4 mb-0">Share your link with your friends and help them get onboard EngLink.</p>
                    </div>

                    <div className="d-flex flex-column justify-content-between col-4">
                        <div className="referral-title-div d-flex align-items-center">
                            <div className="referral-guide-steps d-flex justify-content-center align-items-center">
                                <p className="mb-0"> 3 </p>
                            </div>
                            <h6 className="mb-0">Redeem your Reward</h6>
                        </div>
                        <p className="referral-guide-steps-text mt-4 mb-0">Share your link with your friends and help them get onboard EngLink.</p>
                    </div>

                </div>
            </div>
        </>
    );
}

export default ReferralSystem;