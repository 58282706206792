import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import './CountrySelector.scss';
import countryList from 'react-select-country-list';
import ReactCountryFlag from "react-country-flag";
// import Select, {Option} from "../Select/Select";
import Select from 'react-select';

type Props = {
    setSelectedCountry: Function
}

const CountrySelector = ({setSelectedCountry}: Props) => {

    const countryData = useMemo(() => countryList().getData(), [])

    const [optionsElements, setOptionsElements] = useState<Array<any>>([])

    useEffect(() => {
        let tempArray: Array<any> = [];

        if (countryData && countryData.length) {
            countryData.forEach((option, index) => {
                let tempElement = <div className='d-flex align-items-center gap-3 ps-3' key={index}>
                    <ReactCountryFlag
                        countryCode={option.value}
                        svg
                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                        cdnSuffix="svg"
                        title={option.value}
                    />

                    <div className="country-selector__country">{option.label}</div>
                </div>

                let tempOption = {label: tempElement, value: option.label}

                tempArray.push(tempOption);
            });

            setOptionsElements([...tempArray]);
        }
    }, [countryData])

    return <div className='country-selector'>
        <Select
            className="basic-single"
            classNamePrefix="select"
            defaultValue={optionsElements[0]}
            isClearable={false}
            isSearchable={true}
            name="color"
            onChange={(selectedOption) => setSelectedCountry(selectedOption.value)}
            options={optionsElements}
        />
    </div>
};

export default CountrySelector;