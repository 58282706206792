import React from "react";
import './Payments.scss';


function Payments() {
    return (
        <>
            <div className="payments_info container-fluid d-flex flex-column">
                <h1 className="payments_info-title mt-3 mb-0">Payments</h1>
            </div>
        </>
    )
}

export default Payments;