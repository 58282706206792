import {calls} from "../../../config/db_config";
import axios from "axios";

type InfoData = {}
const registerTutor = (idPhotoBlob: Blob, photoDataBlob: Blob, infoData: InfoData) => {
    const formData = new FormData();

    formData.append('photo_id', idPhotoBlob);
    formData.append('picture', photoDataBlob);
    formData.append('infoData', JSON.stringify(infoData));

    const config = {
        headers: {
            ContentType: 'multipart/form-data'
        }
    };

    return axios.post(calls.signUpTutor, formData, config);
}

export default registerTutor;