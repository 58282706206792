import React, {createRef, useEffect, useState} from "react";
import {Bar} from 'react-chartjs-2'
import "./BarGraph.scss";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement
} from 'chart.js'

function BarGraph() {
    const barReference = createRef<any>();

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        BarElement
    )

    const [barData, setBarData] = useState({
        labels: ['July', 'August', 'September', 'October', 'December', 'January', 'February'],
        datasets: [{
            barPercentage: 0.5,
            label: 'Quiz Stats Bar',
            data: [25, 56, 23, 12, 62, 25, 85],
            backgroundColor: [
                'rgb(153, 102, 255)',
                'rgb(255, 159, 64)',
                'rgb(255, 205, 86)',
                'rgb(255, 99, 132)',
                'rgb(75, 192, 192)',
                'rgb(54, 162, 235)',
                'rgb(201, 203, 207)'
            ],
            borderColor: [
                'rgb(255, 99, 132)',
                'rgb(255, 159, 64)',
                'rgb(255, 205, 86)',
                'rgb(75, 192, 192)',
                'rgb(54, 162, 235)',
                'rgb(153, 102, 255)',
                'rgb(201, 203, 207)'
            ],
            borderRadius: 2, // Border radius for the bars
            borderWidth: 0,
        }]
    });

    useEffect(() => {
        // fetch(calls.getQuizAverages, {
        //     method: 'get',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': tokenUtils.getBearerToken()
        //     }
        // }).then(response => response.json()).then(data => {
        //     if (data.status === 200) {
        //         const bar = barReference.current;
        //
        //         let tempObj = barData;
        //         let tempLabels = [];
        //         let tempData = [];
        //
        //         data.data.forEach(function (row, index) {
        //             tempLabels.push(row.month);
        //             tempData.push(row.average);
        //         })
        //         tempObj.labels = tempLabels;
        //         tempObj.datasets[0].data = tempData;
        //
        //         bar.data = tempObj;
        //         setBarData(tempObj);
        //     } else {
        //         console.log(data);
        //     }
        // })
    }, []);

    return (
        <div className="bar-graph">
            {
                <Bar ref={barReference} data={barData} width={100} height={15}
                     options={{
                         responsive: true,
                         maintainAspectRatio: false,
                         plugins: {
                             legend: {
                                 display: false
                             }
                         },
                         scales: {
                             x: {
                                 grid: {
                                     display: false,
                                 }
                             },
                             y: {
                                 display: false,
                                 max: 100, // Set the maximum value for the y-axis
                             }
                         },
                         indexAxis: 'x',
                         // @ts-ignore
                         barThickness: 10
                     }}/>
            }
        </div>
    );
}

export default BarGraph;
