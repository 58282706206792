// In your tsx file (e.g., tokenUtils.tsx)
import { cookieUtils } from "./cookie-parser";

function getToken(): string | null {
    return cookieUtils.getCookie('auth');
}

function getBearerToken(): string | null {
    const token = getToken();
    return token ? 'Bearer ' + JSON.parse(token).token : null;
}

export const tokenUtils = { getToken, getBearerToken };
