import { createSlice } from '@reduxjs/toolkit'

export const navbarSlice = createSlice({
    name: 'profile',
    initialState: {
        value: {},
    },
    reducers: {
        setProfileData: (state, action) => {
            let data = action.payload;
            if (data.birthday)
                data.birthday = new Date(data.birthday).toDateInputValue();
            state.value = data;
        },
    },
})

declare global {
    interface Date {
        toDateInputValue(): string;
    }
}

Date.prototype.toDateInputValue = (function() {
    let local = new Date(this);
    local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
    return local.toJSON().slice(0,10);
});

// Action creators are generated for each case reducer function
export const { setProfileData } = navbarSlice.actions

export default navbarSlice.reducer