import {createSlice} from '@reduxjs/toolkit';

export const progressBarSlice = createSlice({
    name: 'progressBar',
    initialState: {
        value: false,
    },
    reducers: {
        toggleProgressBar: (state) => {
            state.value = !state.value;
        },
        showProgressBar: (state) => {
            state.value = true;
        },
        hideProgressBar: (state) => {
            state.value = false;
        },
    },
});

export const {toggleProgressBar, showProgressBar, hideProgressBar} = progressBarSlice.actions

export default progressBarSlice.reducer