import React, {useEffect, useRef, useState} from 'react';
import WaveSurfer from 'wavesurfer.js';
import './Waveform.scss';

type Props = {
    audio: string;
};

function Waveform({audio}: Props) {
    const containerRef = useRef<HTMLTableRowElement>(null);

    useEffect(() => {
        if (containerRef.current) {
            const element = containerRef.current as Element;

            const waveSurfer = WaveSurfer.create({
                container: containerRef.current,
                height: 88
            })
            waveSurfer.load(audio);

            waveSurfer.on('interaction', () => {
                waveSurfer.playPause()
            })

            return () => {
                waveSurfer.destroy()
            }
        }

    }, [audio])

    return (
        <div className={'waveform'}>
            <div ref={containerRef}/>
        </div>)
}

export default Waveform;