import React from 'react';
import './UserLessonStatus.scss';
import User from "../../models/user";

type Props = {
    user: User,
    status: string
}

const UserLessonStatus = ({user, status}: Props) => {

    return (
        <div className="lesson-status">
            <div className="lesson-status__wrapper">
                <img className='lesson-status__user-pic'
                     src={user.profile_picture ? user.profile_picture
                         : '/resources/student.png'}
                     alt={'profile picture'}/>

                <div className={'d-flex flex-column align-items-start'}>
                    <h2>Lesson <strong className='text-primary'>{user.first_name + ' ' + user.last_name}</strong></h2>
                    <div className="lesson-status__status">
                        {status}
                    </div>
                </div>
            </div>

        </div>
    );
}


export default UserLessonStatus;