import React, {createRef, useState, RefObject, useContext, useEffect} from 'react';
import './TutorInfo.scss';
import { AiFillStar } from "react-icons/ai";
import { MdFavorite } from "react-icons/md";
import { BsFillPauseFill, BsFillPlayFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import capitalize from "../../utils/textUtils";
import {ContextManager} from "../../app/socket";
import Tutor from "../../models/tutor";

const TutorInfo: React.FC<{ tutor: Tutor }> = ({ tutor }) => {

    const context = useContext(ContextManager);

    const [isVideoShowing, setIsVideoShowing] = useState(false);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);

    const videoReference: RefObject<HTMLVideoElement> = createRef();

    const { t } = useTranslation();

    useEffect(() => {
        if (tutor.is_favorite != null)
            setIsFavorite(tutor.is_favorite);
    }, [])

    const toggleVideoPlay = () => {
        if (videoReference.current) {
            if (!isVideoShowing) {
                videoReference.current.play();
                videoReference.current.addEventListener('ended', myHandler, false);

                function myHandler(e: Event) {
                    setIsVideoShowing(false);
                    setIsVideoPlaying(false);
                }

                setIsVideoShowing(true);
                setIsVideoPlaying(true);
            }

            if (isVideoPlaying) {
                videoReference.current.pause();
                setIsVideoPlaying(false);
            } else {
                videoReference.current.play();
                setIsVideoPlaying(true);
            }
        }
    };

    const pauseButtonClick = () => {
        if (videoReference.current) {
            videoReference.current.play();
            setIsVideoShowing(true);
        }
    };

    const favoriteTutor = () => {
        if (context.socket) {
            if (isFavorite) {
                context.socket.emit('removeFavoriteTutor', tutor._id);
                setIsFavorite(false);
            } else {
                context.socket.emit('setFavoriteTutor', tutor._id);
                setIsFavorite(true);
            }
        }
    };

    return (
        <div className="tutor-info">
            <div className="tutor-info__top">
                <div className="tutor-info__pic-wrapper">
                    {!isVideoShowing && (
                        <img
                            className='tutor-info__user-pic'
                            src={tutor.profile_picture ? tutor.profile_picture : '/resources/student.png'}
                            alt={'profile picture'}
                        />
                    )}

                    {tutor.video && (
                        <>
                            <video
                                className={'tutor-info__video ' + (isVideoShowing ? '' : 'd-none')}
                                ref={videoReference}
                                src={tutor.video}
                                onClick={toggleVideoPlay}
                            ></video>

                            <div className="tutor-info__play-button" onClick={toggleVideoPlay}>
                                {!isVideoPlaying && <BsFillPlayFill size={30} />}
                                {isVideoPlaying && <BsFillPauseFill size={30} />}
                            </div>
                        </>
                    )}
                </div>

                <div className={'tutor-info__right'}>
                    <div className="tutor-info__right-wrapper">
                        <div className={'d-flex align-items-center'}>
                            <h1 className="tutor-info__name mb-0 mr-6">
                                {tutor.first_name + ' ' + tutor.last_name}
                            </h1>

                            <div className="tutor-info__star">
                                <AiFillStar size={26} className={'mr-1'} />
                                {tutor.rating ? tutor.rating : 'No ratings'}
                                {tutor.rating_count ? ' (' + tutor.rating_count : ''})
                            </div>
                        </div>

                        <div className={"button " + (isFavorite ? '' : 'button-gray')} onClick={favoriteTutor}>
                            {
                                isFavorite && <span className={'mr-2'}>{t("tutorInfoRemoveToFavorites.label")}</span>
                            }
                            {
                                !isFavorite && <span className={'mr-2'}>{t("tutorInfoAddToFavorites.label")}</span>
                            }


                            <MdFavorite />
                        </div>
                    </div>

                    <h4 className="tutor-info__description">{tutor.description}</h4>
                </div>
            </div>

            <div className="tutor-info__table">
                <table>
                    <tbody>
                    <tr>
                        <td>
                            {capitalize(t("country.label"))}: {' ' + capitalize(tutor.country)}
                        </td>

                        <td>
                            {capitalize(t("gender.label"))}: {capitalize(tutor.gender)}
                        </td>

                        <td>
                            {capitalize(t("hobby.label"))}: {' ' + capitalize(tutor.hobbies)}
                        </td>
                    </tr>

                    <tr>
                        <td>
                            {capitalize(t("age.label"))}: {' ' + capitalize(tutor.age.toString())}
                        </td>

                        <td>
                            {tutor.school && (
                                <>
                                    {capitalize(t("school.label"))}: {' ' + capitalize(tutor.school)}
                                </>
                            )}
                        </td>

                        <td>
                            {tutor.favorite_movies && (
                                <>
                                    {capitalize(t("favoriteMovies.label"))}: {capitalize(tutor.favorite_movies)}
                                </>
                            )}
                        </td>

                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TutorInfo;
