import React, {useEffect} from "react";
import './App.css';
import {BrowserRouter as Router, Routes, Route, useLocation} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './pages/Login/login';
import Homepage from './pages/Homepage/homepage';
import './scss/main.scss';
import Profile from "./pages/Profile/profile";
import NotFound from "./pages/NotFound/not-found";
import HelpCenter from './pages/HelpCenter/HelpCenter';
// @ts-ignore
import Dashboard from "./pages/Dashboard/Dashboard.tsx";
import ProgressBar from "./components/ProgressBar/progressBar";
import ChatUI from "./pages/Chat/chat";
import LatestLecturesPage from "./pages/LatestLecturesPage/LatestLecturesPage";
import Meeting from "./pages/CallPage/meeting";
import {useDispatch, useSelector} from "react-redux";
import {setThemeMode} from "./reducers/ThemeModeSlicer";
import Cookies from "universal-cookie";
import SchedulePage from "./pages/SchedulePage/SchedulePage";
import LessonDetails from "./pages/LessonDetails/LessonDetails";
import "./i18n";
import UserHomepage from "./pages/UserHomepage/UserHomepage";
import TutorPage from "./pages/TutorPage/TutorPage";
import Register from "./pages/Register/Register";
import Authorized from "./layout/Authorized/Authorized";
import Unauthorized from "./layout/Unauthorized/Unauthorized";
import FreePass from "./layout/FreePass/FreePass";
import TutorRegister from "./pages/TutorRegister/TutorRegister";
import NotificationPage from "./pages/NotificationPage/notificationPage";
import AboutUs from "./pages/AboutUs/AboutUs";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import ContactUs from "./pages/ContactUs/ContactUs";

function App() {

    const themeMode = useSelector((state: any) => state.themeMode.value);
    const dispatch = useDispatch();

    // const [dark, setDark] = useState(true);

    const initTheme = () => {
        const cookies = new Cookies();
        let theme = cookies.get('theme');

        if (theme) {
            if (theme === 'dark') {
                dispatch(setThemeMode(true));
            }

            return;
        }

        const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
        if (darkThemeMq.matches) {
            dispatch(setThemeMode(true));
        }
    }

    useEffect(() => {
        initTheme();
    }, [dispatch]);

    const ScrollToTop = () => {
        const { pathname } = useLocation();

        useEffect(() => {
            window.scrollTo(0, 0);
        }, [pathname]);

        return null;
    };

    return (
        <div className={"app " + (themeMode ? 'dark' : 'light')}>
            <ProgressBar/>

            <Router>
                <ScrollToTop />

                <Routes>
                    <Route element={<FreePass/>}>
                        <Route path='/' element={<Homepage/>}/>
                        <Route path='/about' element={<AboutUs/>}/>
                        <Route path='/contact-us' element={<ContactUs/>}/>
                        <Route path='/help-center' element={<HelpCenter/>}/>
                        <Route path='/terms-and-conditions' element={<TermsAndConditions/>}/>
                    </Route>

                    <Route element={<Unauthorized/>}>
                        <Route path='/login' element={<Login/>}/>
                        <Route path='/register' element={<Register/>}/>
                        <Route path='/tutor-register' element={<TutorRegister/>}/>
                    </Route>

                    <Route element={<Authorized/>}>
                        <Route path='/app' element={<Dashboard/>}/>
                        <Route path='/schedule' element={<SchedulePage/>}/>
                        <Route path='/chat' element={<ChatUI/>}/>
                        <Route path='/notifications' element={<NotificationPage/>}/>
                        <Route path='/lesson-details' element={<LessonDetails/>}/>
                        <Route path='/call' element={<Meeting/>}/>
                        <Route path='/book' element={<UserHomepage/>}/>
                        <Route path='/tutor' element={<TutorPage/>}/>

                        <Route path='/profile' element={<Profile/>}/>
                        <Route path='/latest-lectures' element={<LatestLecturesPage/>}/>
                    </Route>

                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </Router>
        </div>);
}

export default App;
